import React from "react"
import PropTypes from "prop-types"

import * as formatUtils from '../../util/formatting'
import * as purchaseKind from '../../util/builder/purchase_kind'
import * as purchaseOrderHelpers from '../../util/builder/purchase_order'

class TopUpTracker extends React.Component {
  constructor(props) {
    super(props)

    this.MIN_TOPUP_AMOUNT = 1000

    this.canBuy = this.canBuy.bind(this)
    this.onClickBuy = this.onClickBuy.bind(this)
  }

  canBuy() {
    return Number(this.props.purchaseOrder.cad_total) >= this.MIN_TOPUP_AMOUNT &&
           purchaseOrderHelpers.getTotalProportion(this.props.purchaseOrder) === 100
  }

  onClickBuy() {
    if (this.canBuy()) {
      this.props.onClickBuy()
    }
  }

  render () {
    const isTopUpInProgress = this.props.purchaseOrder && this.props.purchaseOrder.kind === purchaseKind.TOP_UP

    return (
      <div className="top-up-tracker">
        {!this.props.isTopUpMode || !isTopUpInProgress ? (
          <React.Fragment>
            <div>
              <div className="header">Your DAC is live!</div>
              <div className="description">
                <span>Congratulations, your Digital Asset Cache™ has been deposited in our offline vault on {formatUtils.shortDate(new Date(Date.parse(this.props.dac.start_date)))}.</span>
              </div>
              <div className="description">
                <span>Track your DAC on the go with </span>
                <a className="pv-link-medium mobile-apps-link" href="https://itunes.apple.com/us/app/balance-now/id1300713117" target="_blank">our iOS app.</a>
              </div>
            </div>

            <div className="link-container">
              {isTopUpInProgress ? (
                <button className="pv-btn disabled">Top-Up</button>
              ) : (
                <button className="pv-btn" onClick={this.props.onClickTopUp}>Top-Up</button>
              )}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div>
              <div className="header-container">
                <div className="header">Top-Up your DAC</div>
              </div>

              <div className="description">
                <span>Play with your proportions on the left, and see how well it does on the right. You can lock proportions or explore presets.</span>
              </div>
            </div>

            <div className="link-container">
              <button className={"pv-btn buy-btn " + (this.canBuy() ? '' : 'disabled')} onClick={this.onClickBuy}>Checkout</button>
            </div>
          </React.Fragment>
        )}

      </div>
    );
  }
}

TopUpTracker.propTypes = {
  dac: PropTypes.object,
  purchaseOrder: PropTypes.object,
  user: PropTypes.object,
  isTopUpMode: PropTypes.bool,
  onClickTopUp: PropTypes.func,
  onClickBuy: PropTypes.func,
}

export default TopUpTracker
