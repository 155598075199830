import * as currencyHelpers from "../currency"

const allStringFieldsPresent = (dict, fields) => {
  if (!dict) { return false }

  var allFieldsPresent = true
  fields.forEach((fieldName, i) => {
    if (!dict[fieldName] || dict[fieldName].length == 0) {
      allFieldsPresent = false
    }
  })

  return allFieldsPresent
}

export const fiatDetailsComplete = (redeemRequest) => {
  const commonRequiredFields = ['country', 'beneficiary_name', 'bank_address', 'currency']
  const canadaRequiredFields = ['institution_number', 'transit_number', 'account_number']
  const usRequiredFields = ['account_number', 'aba_routing_number']
  const intlRequiredFields = ['iban', 'swift']

  var hasCommonFieldsPresent = allStringFieldsPresent(redeemRequest.fiatDetails, commonRequiredFields)
  if (!hasCommonFieldsPresent) { return false }

  if (redeemRequest.fiatDetails.country === 'Canada') {
    return allStringFieldsPresent(redeemRequest.fiatDetails, canadaRequiredFields)
  } else if (redeemRequest.fiatDetails.country === 'United States') {
    return allStringFieldsPresent(redeemRequest.fiatDetails, usRequiredFields)
  }

  return allStringFieldsPresent(redeemRequest.fiatDetails, intlRequiredFields)
}

export const cryptoDetailsComplete = (redeemRequest, dac) => {
  if (!redeemRequest.cryptoDetails) { return false }

  const requiredCurrencies = Object.keys(redeemRequest.amounts)

  // check that all public addresses are present and valid
  var allAddrsValid = true
  requiredCurrencies.forEach((currency, i) => {
    var addr = redeemRequest.cryptoDetails[`${currency}_public_address`]
    if (!addr || addr.length == 0 || !currencyHelpers.isValidPublicAddress(currency, addr)) {
      allAddrsValid = false
    }
  })

  // check that xlm memo is valid if present
  if (requiredCurrencies.includes("xlm")) {
    var memo = redeemRequest.cryptoDetails['xlm_memo']
    if (memo && memo.length > 0 && !currencyHelpers.isValidXlmMemo(memo)) {
      return false
    }
  }

  // check that xrp tag is valid if present
  if (requiredCurrencies.includes("xrp")) {
    var tag = redeemRequest.cryptoDetails['xrp_tag']
    if (tag && tag.length > 0 && !currencyHelpers.isValidXrpTag(tag)) {
      return false
    }
  }

  return allAddrsValid
}

export const mailDetailsComplete = (redeemRequest) => {
  const requiredAddressFields = ['country', 'province', 'city', 'street', 'postal_code']
  return allStringFieldsPresent(redeemRequest.mailDetails, requiredAddressFields)
}
