import React from "react"
import PropTypes from "prop-types"

import redeemKind from "../../util/redeem/redeem_kind"
import * as addressHelpers from "../../util/address"
import Switch from "react-switch"

class RedeemConfirmationTab extends React.Component {
  constructor(props) {
    super(props)

    this.onAcknowledgeChanged = this.onAcknowledgeChanged.bind(this)
    this.onSubmit = this.onSubmit.bind(this)

    this.state = {
      acknowledged: false,
    }
  }

  onAcknowledgeChanged(value) {
    this.setState({
      ...this.state,
      acknowledged: value,
    })
  }

  onSubmit() {
    if (this.state.acknowledged) {
      this.props.onSubmit()
    }
  }

  render () {
    return (
      <div className="redeem-confirmation-tab">
        <div className="title">Are you sure you want to redeem your Digital Asset Cache?</div>

        <div className="description">
          {this.props.redeemRequest.kind === redeemKind.FIAT && (
            <React.Fragment>
              <span>
                The component digital assets will be liquidated at fair market value within 1-2 business days
                and the proceeds will be wired to your bank account.
              </span>
            </React.Fragment>
          )}

          {this.props.redeemRequest.kind === redeemKind.CRYPTO && (
            <React.Fragment>
              <span>
                Each of the component digital assets will be transferred to the corresponding public addresses your provided,
                after as we confirm their ownership.
              </span>
            </React.Fragment>
          )}

          {this.props.redeemRequest.kind === redeemKind.MAIL && (
            <React.Fragment>
              <span>Each of the component digital assets will be printed on an encrypted paper wallet and shipped to your physical address. We will guide you through the decryption process upon confirmation of receipt.</span>
              <div className="address-text">{addressHelpers.addressAsString(this.props.redeemRequest.mailDetails)}</div>
            </React.Fragment>
          )}
        </div>

        <div className="description">
          We will contact you via email or phone to verify the details of your request and instruct you on the next steps.
        </div>

        <div className="acknowledgement-container">
          <Switch
            checked={this.state.acknowledged}
            onChange={this.onAcknowledgeChanged}
            checkedIcon={<div className="toggle-label yes">Yes</div>}
            uncheckedIcon={<div className="toggle-label no">No</div>}
            className="toggle-btn"
            height={20}
            onColor="#2369AD"
            offColor="#778F9B" />

          <div className="ackowledgement-text">
            I acknowledge that by pressing "Redeem" I hereby initiate the exercise of the Redeeming Service
            as per section 1(b) of my contract for the sale of managed services.
          </div>
        </div>

        <button className={"submit-btn pv-btn " + (this.state.acknowledged ? '' : 'disabled')} onClick={this.onSubmit}>Redeem</button>
      </div>
    )
  }
}

RedeemConfirmationTab.propTypes = {
  user: PropTypes.object,
  redeemRequest: PropTypes.object,
  dac: PropTypes.object,
  onSubmit: PropTypes.func,
}

export default RedeemConfirmationTab
