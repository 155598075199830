import React from "react"
import PropTypes from "prop-types"

import ReactHintFactory from 'react-hint'
const ReactHint = ReactHintFactory(React)

import NumberFormat from 'react-number-format'

class LockableNumberInput extends React.Component {
  constructor(props) {
    super(props)

    this.valueOrEmptyString = this.valueOrEmptyString.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onChange = this.onChange.bind(this)

    this.state = {
      allowValidation: false,
    }
  }

  valueOrEmptyString(value) {
    return !value ? '' : value
  }

  onChange(e) {
    this.setState({...this.state, allowValidation: true})
    if (this.props.onChange) {
      this.props.onChange(e)
    }
  }

  onBlur(e) {
    this.setState({...this.state, allowValidation: true})
    if (this.props.onBlur) {
      this.props.onBlur(e)
    }
  }

  render () {
    var classNames = [this.props.className, "input", "text-input"]
    if (this.props.isLocked) {
      classNames.push('disabled')
    }

    if (!this.props.validator()) {
      if (this.state.allowValidation || !this.props.validateOnBlurOnly) {
        classNames.push('invalid')
      }
    }

    return (
      <NumberFormat
        className={classNames.join(' ')}
        value={this.valueOrEmptyString(this.props.value)}
        displayType={this.props.isLocked ? 'text' : 'input'}
        onValueChange={this.onChange}
        onBlur={this.onBlur}
        decimalScale={this.props.decimalScale}
        allowNegative={this.props.allowNegative}
        placeholder={this.props.placeholder}
        format={this.props.format}
        isAllowed={this.props.isAllowed}
        prefix={this.props.prefix}
        isNumericString={this.props.isNumericString} />
    )
  }
}

LockableNumberInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  format: PropTypes.string,
  allowNegative: PropTypes.bool,
  decimalScale: PropTypes.number,
  prefix: PropTypes.string,
  isNumericString: PropTypes.bool,

  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  isAllowed: PropTypes.func,

  isLocked: PropTypes.bool,
  validator: PropTypes.func,

  validateOnBlurOnly: PropTypes.bool,
}

const defaultTrueFunc = () => { return true }

LockableNumberInput.defaultProps = {
  className: "",
  placeholder: "",
  format: undefined,
  allowNegative: true,
  decimalScale: undefined,
  prefix: undefined,
  isNumericString: false,

  isLocked: false,
  isAllowed: defaultTrueFunc,
  validator: defaultTrueFunc,
  validateOnBlurOnly: true,
}

export default LockableNumberInput
