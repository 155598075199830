import React from "react"
import PropTypes from "prop-types"

import ReactHintFactory from 'react-hint'
const ReactHint = ReactHintFactory(React)

import * as presets from '../../util/builder/presets'

class WelcomeView extends React.Component {
  constructor(props) {
    super(props)

    this.onClickEqualAllocation = this.onClickEqualAllocation.bind(this)
    this.onClickMarketCapAllocation = this.onClickMarketCapAllocation.bind(this)
    this.onClickMarkowitzAllocation = this.onClickMarkowitzAllocation.bind(this)
    this.onClickBloombergGalaxyAllocation = this.onClickBloombergGalaxyAllocation.bind(this)
    this.renderTooltip = this.renderTooltip.bind(this)
  }

  renderTooltip

  onClickEqualAllocation() {
    this.props.onPresetSelected(presets.equal(), 'equal')
  }

  onClickMarketCapAllocation() {
    this.props.onPresetSelected(presets.marketCap(this.props.assetSummary), 'mcap')
  }

  onClickMarkowitzAllocation() {
    this.props.onPresetSelected(presets.markowitz(), 'markowitz')
  }

  onClickBloombergGalaxyAllocation() {
    this.props.onPresetSelected(presets.bloombergGalaxyIndex(), 'bloomberg')
  }

  renderTooltip(target, content) {
    const preset = target.dataset.preset

    if (preset === 'equal') {
      return (
        <div className="tooltip-content preset-tooltip-content">
          Each of the assets receives an equal weight.
        </div>
      )
    }

    if (preset === 'mcap') {
      return (
        <div className="tooltip-content preset-tooltip-content">
          Assets are weighted by their respective market capitalization as reported by CoinMarketCap.
        </div>
      )
    }

    if (preset === 'bloomberg') {
      return (
        <div className="tooltip-content preset-tooltip-content">
          Weights are distributed relatively to the proportions reported by Bloomberg Galaxy. Unsupported assets are excluded. <a href='https://www.bloomberg.com/professional/product/indices/bloomberg-galaxy-crypto-index/' target='_blank'>Read more</a>.
        </div>
      )
    }

    if (preset === 'markowitz') {
      return (
        <div className="tooltip-content preset-tooltip-content">
          Weights are distributed based on a mathematical framework developed by Harry Markowitz such that the \"expected return\" is maximized for 1 standard deviation of risk based on price changes over the last 365 days. Read more at <a href='https://en.wikipedia.org/wiki/Modern_portfolio_theory'>Modern Portfolio Theory</a>
        </div>
      )
    }
  }

  render () {
    return (
      <div className="presets-container">
        <div className={"preset " + (this.props.purchaseOrder.preset === 'equal' ? 'selected' : '')} onClick={this.onClickEqualAllocation}>
          Equal Weights
          <div className="question-icon-blue" data-preset-tooltip data-preset="equal" />
        </div>
        <div className={"preset " + (this.props.purchaseOrder.preset === 'mcap' ? 'selected' : '')} onClick={this.onClickMarketCapAllocation}>
          Market Cap Weights
          <div className="question-icon-blue" data-preset-tooltip data-preset="mcap" />
        </div>
        <div className={"preset " + (this.props.purchaseOrder.preset === 'bloomberg' ? 'selected' : '')} onClick={this.onClickBloombergGalaxyAllocation}>
          Bloomberg Galaxy Weights
          <div className="question-icon-blue" data-preset-tooltip data-preset="bloomberg" />
        </div>

        <ReactHint position="right" events onRenderContent={this.renderTooltip} className="tooltip" attribute="data-preset-tooltip" delay={100} persist={true} />
      </div>
    )
  }
}

WelcomeView.propTypes = {
  purchaseOrder: PropTypes.object,
  assetSummary: PropTypes.object,
  onPresetSelected: PropTypes.func,
}

export default WelcomeView
