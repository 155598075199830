import React from "react"
import PropTypes from "prop-types"

import * as purchaseStatus from '../../util/builder/purchase_status'
import * as purchaseOrderHelpers from '../../util/builder/purchase_order'

class CtaView extends React.Component {
  constructor(props) {
    super(props)
    this.MIN_DAC_AMOUNT = 5000
    this.canBuy = this.canBuy.bind(this)
    this.onClickBuy = this.onClickBuy.bind(this)
  }

  canBuy() {
    return Number(this.props.purchaseOrder.cad_total) >= this.MIN_DAC_AMOUNT &&
           purchaseOrderHelpers.getTotalProportion(this.props.purchaseOrder) === 100
  }

  onClickBuy() {
    if (this.canBuy()) {
      this.props.onBuy()
    }
  }

  render () {
    return (
      <React.Fragment>
        <div>
          <div className="title">
            {this.props.purchaseOrder.status === purchaseStatus.SAVED ?
              'Your DAC is not live yet.' :
              'Create your DAC'
            }
          </div>
          <div className="description">
            {this.props.purchaseOrder.status === purchaseStatus.SAVED ? (
              <React.Fragment>
                Would you like some human assistance?
                <br />
                <a href="mailto:support@balancenow.ca">Talk to one of our representatives.</a>
              </React.Fragment>
            ) : (
              <span>Play with your proportions on the left, and see how well it does on the right. You can lock proportions or explore presets.</span>
            )}
          </div>
        </div>
        <div>
          <button className={"pv-btn buy-btn " + (this.canBuy() ? '' : 'disabled')} onClick={this.onClickBuy}>Checkout</button>
        </div>
      </React.Fragment>
    )
  }
}

CtaView.propTypes = {
  purchaseOrder: PropTypes.object,
  onBuy: PropTypes.func,
}

export default CtaView
