import React from "react"
import PropTypes from "prop-types"

import axios from '../../util/network'
import * as geography from '../../util/geography'

import ReactHintFactory from 'react-hint'
const ReactHint = ReactHintFactory(React)

import AddressEditorForm from '../profile/AddressEditorForm'

class AddressTab extends React.Component {
  constructor(props) {
    super(props)

    this.valueOrEmptyString = this.valueOrEmptyString.bind(this)
    this.canSubmit = this.canSubmit.bind(this)
    this.onClickSubmit = this.onClickSubmit.bind(this)
    this.onAddressEditorFormUpdate = this.onAddressEditorFormUpdate.bind(this)

    this.addressTooltipText = "In order to comply with Anti-Money Laundering regulations, we verify your identity using your name, date of birth, address and relevant documentation corroborating this information."

    this.state = {
      address: this.props.address,
    }
  }

  valueOrEmptyString(value) {
    return !value ? '' : value
  }

  canSubmit() {
    return this.valueOrEmptyString(this.state.address.country).length > 0 &&
           this.valueOrEmptyString(this.state.address.province).length > 0 &&
           this.valueOrEmptyString(this.state.address.city).length > 0 &&
           this.valueOrEmptyString(this.state.address.street).length > 0 &&
           this.valueOrEmptyString(this.state.address.postal_code).length > 0
  }

  saveAddressAsync(address) {
    axios.put('/addresses/'+address.id, address)
      .catch((err) => {
        console.log(err)
      })
  }

  onClickSubmit() {
    if (!this.canSubmit()) {
      return
    }

    this.saveAddressAsync(this.state.address)
    this.props.afterSubmitted(this.state.address)
  }

  onAddressEditorFormUpdate(newAddress) {
    this.setState({
      ...this.state,
      address: newAddress,
    })
  }

  render () {
    return (
      <div className="address-editor">
        <div className="title-container">
          <div className="title">Address</div>
          <div className="question-icon-blue" data-address-editor-tooltip={this.addressTooltipText} />
        </div>

        <AddressEditorForm
          address={this.state.address}
          onUpdate={this.onAddressEditorFormUpdate}
          mandatoryFields={['country', 'province', 'city', 'street', 'postal_code']} />

        <button className={"pv-btn submit-btn " + (this.canSubmit() ? '' : 'disabled')} onClick={this.onClickSubmit} tabIndex="0">Continue</button>

        <ReactHint autoPosition events className="tooltip" attribute="data-address-editor-tooltip" delay={100} persist={true} />
      </div>
    )
  }
}

AddressTab.propTypes = {
  address: PropTypes.object,
  afterSubmitted: PropTypes.func,
}

export default AddressTab
