import React from "react"
import PropTypes from "prop-types"

import Switch from "react-switch"
import axios from '../../util/network'

import ReactHintFactory from 'react-hint'
const ReactHint = ReactHintFactory(React)

class PurchaseOrderQuestionnaire extends React.Component {
  constructor(props) {
    super(props)

    this.canSubmit = this.canSubmit.bind(this)
    this.saveQuestionnaireAsync = this.saveQuestionnaireAsync.bind(this)
    this.onChangeRepresentation = this.onChangeRepresentation.bind(this)
    this.onChangeBorrowedFunds = this.onChangeBorrowedFunds.bind(this)
    this.onChangeSourceOfFunds = this.onChangeSourceOfFunds.bind(this)
    this.onChangeDeclaration = this.onChangeDeclaration.bind(this)
    this.onSourceOfFundsBlur = this.onSourceOfFundsBlur.bind(this)
    this.onClickSubmit = this.onClickSubmit.bind(this)

    this.representingOthersTooltipText = "A third party is a person or entity who instructs you to conduct an activity or financial transaction on their behalf. To determine whether a third party is involved, it is not about who owns or benefits from the money, or who is carrying out the transaction or activity, but rather about who gives the instructions to handle the money or conduct a transaction or particular activity."

    // set default purchase order fields
    const purchaseOrder = this.props.purchaseOrder
    if (!purchaseOrder.questionnaire.representing_others) {
      purchaseOrder.questionnaire.representing_others = false
    }
    if (!purchaseOrder.questionnaire.using_borrowed_funds) {
      purchaseOrder.questionnaire.using_borrowed_funds = false
    }
    if (!purchaseOrder.questionnaire.source_of_funds) {
      purchaseOrder.questionnaire.source_of_funds = ''
    }

    this.state = {
      purchaseOrder: purchaseOrder,
      declarationAccepted: false,
      sourceOfFundsBlurred: false,
    }
  }

  canSubmit() {
    return this.state.declarationAccepted &&
           this.state.purchaseOrder.questionnaire.source_of_funds &&
           this.state.purchaseOrder.questionnaire.source_of_funds.length > 0
  }

  saveQuestionnaireAsync(questionnaire) {
    axios.put('/purchase_order_questionnaires/'+questionnaire.id, questionnaire)
      .catch((err) => {
        console.log(err)
      })
  }

  onChangeRepresentation(value) {
    const newPurchaseOrder = {
      ...this.state.purchaseOrder,
      questionnaire: {
        ...this.state.purchaseOrder.questionnaire,
        representing_others: value,
      }
    }

    this.setState({
      ...this.state,
      purchaseOrder: newPurchaseOrder,
    })
  }

  onChangeBorrowedFunds(value) {
    const newPurchaseOrder = {
      ...this.state.purchaseOrder,
      questionnaire: {
        ...this.state.purchaseOrder.questionnaire,
        using_borrowed_funds: value,
      }
    }

    this.setState({
      ...this.state,
      purchaseOrder: newPurchaseOrder,
    })
  }

  onChangeSourceOfFunds(e) {
    const newPurchaseOrder = {
      ...this.state.purchaseOrder,
      questionnaire: {
        ...this.state.purchaseOrder.questionnaire,
        source_of_funds: e.target.value,
      }
    }

    this.setState({
      ...this.state,
      purchaseOrder: newPurchaseOrder,
    })
  }

  onChangeDeclaration(e) {
    this.setState({
      ...this.state,
      declarationAccepted: e.target.checked,
    })
  }

  onSourceOfFundsBlur() {
    this.setState({...this.state, sourceOfFundsBlurred: true})
  }

  onClickSubmit() {
    if (this.canSubmit()) {
      const newQuestionnaire = {
        ...this.state.purchaseOrder.questionnaire,
        is_completed: true,
      }

      const newPurchaseOrder = {
        ...this.state.purchaseOrder,
        questionnaire: newQuestionnaire,
      }

      this.saveQuestionnaireAsync(newQuestionnaire)
      this.props.afterSubmitted(newPurchaseOrder)

      this.setState({
        ...this.state,
        purchaseOrder: newPurchaseOrder,
      })
    }
  }

  render () {
    return (
      <div className="purchase-order-questionnaire">
        <div className="questionnaire-row">
          <div className="left-col">1.</div>
          <div className="middle-col">
            <div className="text">Are you acting under the instructions of any third-party person or entity (e.g. your employer)?</div>
            <div className="question-icon-blue" data-questionnaire-tooltip={this.representingOthersTooltipText} />
          </div>
          <div className="right-col">
            <Switch
              checked={this.state.purchaseOrder.questionnaire.representing_others}
              onChange={this.onChangeRepresentation}
              checkedIcon={<div className="toggle-label yes">Yes</div>}
              uncheckedIcon={<div className="toggle-label no">No</div>}
              className="toggle-btn"
              height={20}
              onColor="#2369AD"
              offColor="#778F9B" />
          </div>
        </div>

        <div className="questionnaire-row">
          <div className="left-col">2.</div>
          <div className="middle-col">
            <div className="text">Are you using borrowed funds for the contemplated transaction?</div>
          </div>
          <div className="right-col">
            <Switch
              checked={this.state.purchaseOrder.questionnaire.using_borrowed_funds}
              onChange={this.onChangeBorrowedFunds}
              checkedIcon={<div className="toggle-label yes">Yes</div>}
              uncheckedIcon={<div className="toggle-label no">No</div>}
              className="toggle-btn"
              height={20}
              onColor="#2369AD"
              offColor="#778F9B" />
          </div>
        </div>

        <div className="questionnaire-row">
          <div className="left-col">3.</div>
          <div className="middle-col">
            <div>
              <div className="text">
                Please specify the source of funds (e.g. employment income, savings, investment income, sale of property).
                <span className="mandatory-asterisk">*</span>
              </div>
            </div>
            <input
              type="text"
              name="source-of-funds"
              className={"input text-input " + (this.state.sourceOfFundsBlurred && this.state.purchaseOrder.questionnaire.source_of_funds.length === 0 ? 'invalid' : '')}
              value={this.state.purchaseOrder.questionnaire.source_of_funds}
              placeholder="Source of funds"
              onChange={this.onChangeSourceOfFunds}
              onBlur={this.onSourceOfFundsBlur} />
          </div>
        </div>

        <div className="questionnaire-row">
          <div className="left-col">
          </div>
          <div className="middle-col">
            <input type="checkbox" name="declaration" className="checkbox" checked={this.state.declarationAccepted} onChange={this.onChangeDeclaration} />
            <div className="text small">I hereby declare that the information provided is true, complete and accurate. I also understand that any willful dishonesty may render for refusal of this application and provision of any other future services.</div>
          </div>
        </div>

        <button className={"pv-btn submit-btn " + (this.canSubmit() ? '' : 'disabled')} onClick={this.onClickSubmit}>Continue</button>

        <ReactHint autoPosition events className="tooltip" attribute="data-questionnaire-tooltip" delay={100} persist={true} />
      </div>
    )
  }
}

PurchaseOrderQuestionnaire.propTypes = {
  purchaseOrder: PropTypes.object,
  afterSubmitted: PropTypes.func,
}

export default PurchaseOrderQuestionnaire
