import React from "react"
import PropTypes from "prop-types"

import * as purchaseKind from '../../util/builder/purchase_kind'

class WelcomeView extends React.Component {
  render () {
    return (
      <div className="not-started-container">
        {this.props.purchaseOrder.kind === purchaseKind.TOP_UP ? (
          <div className="heading">Your Top-Up is empty.</div>
        ) : (
          <div className="heading">Your Digital Asset Cache™ is empty.</div>
        )}
        <div className="wallet-icon" />
        <div className="option top">Build your own</div>
        <div className="option middle">or</div>
        <div className="option bottom">Start with a preset</div>
        <div className="arrow" />
      </div>
    )
  }
}

WelcomeView.propTypes = {
  purchaseOrder: PropTypes.object,
}

export default WelcomeView
