import * as purchaseStatus from './purchase_status'
import * as purchaseKind from './purchase_kind'

export const getTotalProportion = (purchaseOrder) => {
  const total = purchaseOrder.items.reduce((sum, item) => {
    return sum + Number(item.proportion)
  }, 0)

  return Number(total.toFixed(2))
}

export const getItemForCurrency = (purchaseOrder, currency) => {
  return purchaseOrder.items.find((item) => {
    return item.currency === currency
  })
}

export const getProportionForCurrency = (purchaseOrder, currency) => {
  const foundItem = getItemForCurrency(purchaseOrder, currency)
  return foundItem ? foundItem.proportion : 0
}

// helper method to present a purchase order in the same format as a DAC,
// which will then help us derive graph data from a common format
export const purchaseOrderAsDac = (purchaseOrder, assetSummary) => {
  const currencies = {}
  var originalCost = 0

  purchaseOrder.items.forEach((item, i) => {
    const cadAmount = purchaseOrder.cad_total * item.proportion / 100
    const currentPriceCAD = assetSummary[item.currency].cad_price
    const originalPriceCAD = item.cad_price || currentPriceCAD
    const cryptoAmount = cadAmount / currentPriceCAD

    currencies[item.currency] = {
      balance: cryptoAmount,
      cad_value: cadAmount,
    }

    originalCost += cryptoAmount * originalPriceCAD
  })

  const dac = {
    start_date: purchaseOrder.last_saved_at,
    cost: originalCost,
    cad_value: purchaseOrder.cad_total,
    currencies: currencies,
    status: purchaseOrder.status === purchaseStatus.NOT_STARTED ? 'empty' : 'in_progress',
  }

  return dac
}

export const getMinPurchaseAmount = (purchaseOrder) => {
  return purchaseOrder.kind === purchaseKind.TOP_UP ? 1000 : 5000
}

export const haveProportionsChanged = (originalPurchaseOrder, newPurchaseOrder) => {
  if (!originalPurchaseOrder || !newPurchaseOrder) {
    return true
  }

  var isChanged = false
  originalPurchaseOrder.items.forEach((item, i) => {
    if (item.proportion !== getProportionForCurrency(newPurchaseOrder, item.currency)) {
      isChanged = true
    }
  })

  return isChanged
}
