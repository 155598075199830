const isProfileCompleted = (user) => {
  return user.firstname && user.firstname.length > 0 &&
         user.lastname && user.lastname.length > 0 &&
         user.occupation && user.occupation.length > 0 &&
         user.phone_number && user.phone_number.length > 0 &&
         user.date_of_birth && user.date_of_birth.length > 0
}

const isAddressCompleted = (user) => {
  return user.address &&
         user.address.country && user.address.country.length > 0 &&
         user.address.province && user.address.province.length > 0 &&
         user.address.city && user.address.city.length > 0 &&
         user.address.street && user.address.street.length > 0 &&
         user.address.postal_code && user.address.postal_code.length > 0
}

const isDocumentsCompleted = (user) => {
  return user.kyc_documents.photo_id && user.kyc_documents.photo_id.status === 'active' &&
         user.kyc_documents.proof_of_address && user.kyc_documents.proof_of_address.status === 'active'
}

module.exports = {
  PROFILE: 0,
  ADDRESS: 1,
  DOCUMENTS: 2,

  isProfileCompleted,
  isAddressCompleted,
  isDocumentsCompleted,
}
