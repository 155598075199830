import React from "react"
import PropTypes from "prop-types"
import * as purchaseStatus from '../../util/builder/purchase_status'
import CtaView from './CtaView'
import PurchaseProgressView from './PurchaseProgressView'

class PurchaseOrderTracker extends React.Component {
  constructor(props) {
    super(props)

    this.onCancel = this.onCancel.bind(this)

    this.CTA_STATES = [purchaseStatus.NOT_STARTED, purchaseStatus.EDITING, purchaseStatus.SAVED]
    this.KYC_STATES = [
      purchaseStatus.ORDER_PLACED,
      purchaseStatus.PAPERWORK_SIGNED,
      purchaseStatus.INVOICE_PAID]
  }

  onCancel() {
    if (confirm("Are you sure you want to cancel your order?")) {
      this.props.onCancel()
    }
  }

  render () {
    return (
      <div className="purchase-order-tracker">
        {this.CTA_STATES.includes(this.props.purchaseOrder.status) && (
          <CtaView purchaseOrder={this.props.purchaseOrder} onBuy={this.props.onBuy} />
        )}

        {this.KYC_STATES.includes(this.props.purchaseOrder.status) && (
          <PurchaseProgressView
            user={this.props.user}
            purchaseOrder={this.props.purchaseOrder}
            onCancel={this.onCancel}
            onClickVerifyId={this.props.onClickVerifyId}
            onClickPayInvoice={this.props.onClickPayInvoice} />
        )}
      </div>
    )
  }
}

PurchaseOrderTracker.propTypes = {
  user: PropTypes.object,
  purchaseOrder: PropTypes.object,
  onBuy: PropTypes.func,
  onCancel: PropTypes.func,
  onClickVerifyId: PropTypes.func,
  onClickPayInvoice: PropTypes.func,
}

export default PurchaseOrderTracker
