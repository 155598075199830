import React from "react"
import PropTypes from "prop-types"

import RedeemFiatForm from "./RedeemFiatForm"
import RedeemCryptoForm from "./RedeemCryptoForm"
import RedeemMailForm from "./RedeemMailForm"

import redeemKind from "../../util/redeem/redeem_kind"
import * as redeemValidations from "../../util/redeem/redeem_validations"

class RedeemDetailsTab extends React.Component {
  constructor(props) {
    super(props)

    this.canSubmit = this.canSubmit.bind(this)
    this.onUpdate = this.onUpdate.bind(this)
    this.onSubmit = this.onSubmit.bind(this)

    this.state = {
      redeemRequest: this.props.redeemRequest,
    }
  }

  canSubmit() {
    if (!this.state.redeemRequest) {
      return false
    }

    if (this.state.redeemRequest.kind === redeemKind.FIAT) {
      return redeemValidations.fiatDetailsComplete(this.state.redeemRequest)
    } else if (this.state.redeemRequest.kind === redeemKind.CRYPTO) {
      return redeemValidations.cryptoDetailsComplete(this.state.redeemRequest, this.props.dac)
    } else if (this.state.redeemRequest.kind === redeemKind.MAIL) {
      return redeemValidations.mailDetailsComplete(this.state.redeemRequest)
    }

    return false
  }

  onUpdate(newRedeemRequest) {
    this.setState({
      ...this.state,
      redeemRequest: newRedeemRequest,
    })
    this.props.onUpdate(newRedeemRequest)
  }

  onSubmit() {
    if (this.canSubmit()) {
      this.props.onSubmit(this.state.redeemRequest)
    }
  }

  render () {
    return (
      <div className="redeem-details-tab">
        {this.state.redeemRequest.kind === redeemKind.FIAT && (
          <React.Fragment>
            <RedeemFiatForm
              user={this.props.user}
              redeemRequest={this.state.redeemRequest}
              onUpdate={this.onUpdate}
            />

            <div className="description">
              Please keep a record of the banking information you provided and have it available for verification.
              <br />
              An account representative from Balance will contact you to confirm this withdrawal request before proceeding.
            </div>
          </React.Fragment>
        )}

        {this.state.redeemRequest.kind === redeemKind.CRYPTO && (
          <React.Fragment>
            <RedeemCryptoForm
              user={this.props.user}
              redeemRequest={this.state.redeemRequest}
              dac={this.props.dac}
              onUpdate={this.onUpdate}
            />

            <div className="description">
              Please keep a record of the public addresses you provided and have them available for verification.
              <br />
              An account representative from Balance will contact you to confirm this withdrawal request before proceeding.
            </div>
          </React.Fragment>
        )}

        {this.state.redeemRequest.kind === redeemKind.MAIL && (
          <React.Fragment>
            <RedeemMailForm
              user={this.props.user}
              redeemRequest={this.state.redeemRequest}
              onUpdate={this.onUpdate}
            />

            <div className="description">An account representative from Balance will contact you to confirm this withdrawal request before proceeding.</div>
          </React.Fragment>
        )}

        <button className={"submit-btn pv-btn " + (this.canSubmit() ? '' : 'disabled')} onClick={this.onSubmit}>Confirm</button>
      </div>
    )
  }
}

RedeemDetailsTab.propTypes = {
  user: PropTypes.object,
  redeemRequest: PropTypes.object,
  dac: PropTypes.object,
  onUpdate: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default RedeemDetailsTab
