import React from "react"
import PropTypes from "prop-types"

import {hierarchy, partition, treemapSquarify, treemap} from "d3-hierarchy"

import * as formatUtils from '../../util/formatting'
import * as renderingUtils from '../../util/rendering'
import * as privacyUtils from "../../util/privacy"

class Tilemap extends React.Component {
  constructor(props) {
    super(props)

    this.getLeafStyle = this.getLeafStyle.bind(this)
    this.minSize = this.minSize.bind(this)
    this.normalizeData = this.normalizeData.bind(this)
    this.getNodes = this.getNodes.bind(this)
    this.getHoverContent = this.getHoverContent.bind(this)
  }

  getLeafStyle(node) {
    return {
      top: node.y0,
      left: node.x0,
      width: node.x1 - node.x0,
      height: node.y1 - node.y0,
      background: node.data.bgcolor,
    }
  }

  minSize(size) {
    return Math.max(3, size)
  }

  normalizeData(data) {
    // clone array
    let normalizedData = JSON.parse(JSON.stringify(data))

    let newSum = 0
    normalizedData.forEach((d) => {
      d.size = this.minSize(d.size)
      newSum += d.size
    })

    normalizedData.forEach((d) => {
      d.size = d.size / newSum
    })

    return normalizedData
  }

  getNodes() {
    let normalizedData = this.normalizeData(this.props.data)
    let treeData = {"children": normalizedData}

    let input = hierarchy(treeData)
      .sum(function(d) { return d.size })

    let mapper = treemap()
      .tile(treemapSquarify.ratio(1))
      .size([this.props.width, this.props.height])
      .padding(6)

    let allNodes = mapper(input).descendants()
    allNodes.shift() // we don't need the root node in a TileMap
    return allNodes
  }

  // we need to decide if the hover content will fit into the tile
  // by estimating the required height and width required to render the content
  getHoverContent(node) {
    const nodeStyle = this.getLeafStyle(node)

    const iconHeight = 11;
    const iconWidth = 19;
    const textHeight = 16;

    const text = formatUtils.money(node.data.cad_value, 0)
    const font = "bold " + textHeight + "px Avenir Next, Lato, Calibri, Arial, sans-serif"
    const textWidth = renderingUtils.getTextWidth(text, font)

    // approx height of the tile UI when hovering, before showing the hover content
    // header font + footer font + top/bottom tile content padding + top/bottom hover content margins
    const minTileHeight = 16 + 18 + 13*2 + 8*2

    const textElement = privacyUtils.formatMoney(node.data.cad_value, 0)
    const iconElement = (<div className="eye-icon-white" />)

    if ((nodeStyle.height > minTileHeight + iconHeight + textHeight) && (nodeStyle.width > textWidth + 20)) {
      return (
        <React.Fragment>
          {textElement}
          {iconElement}
        </React.Fragment>
      )
    }

    if (nodeStyle.height > minTileHeight + iconHeight || nodeStyle.width > 115) {
      return (
        <React.Fragment>
          {iconElement}
        </React.Fragment>
      )
    }

    return ""
  }

  render () {
    let width = this.props.width
    let height = this.props.height

    return (
      <div className="tile-map" style={{width, height}}>
        {this.getNodes().map((node, index) => {
          return (
            <div key={index} className="tile-map-leaf" style={this.getLeafStyle(node)} onClick={() => {this.props.onClickTile(node.data.currency)}}>
              <div className="leaf-content">
                <div className="leaf-header">{node.data.header}</div>
                <div className="leaf-hover-content">{this.getHoverContent(node)}</div>
                <div className="leaf-footer">{node.data.footer}</div>
              </div>
            </div>
          )
        })}
      </div>
    );
  }
}

Tilemap.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  data: PropTypes.array,
  onClickTile: PropTypes.func,
}

export default Tilemap
