import React from "react"
import PropTypes from "prop-types"

import Dropzone from 'react-dropzone'
import CircularProgressbar from 'react-circular-progressbar'

import * as uploadStatus from "../../util/kyc/upload_status"

class FileUploadTracker extends React.Component {
  render () {
    return (
      <div className="file-upload-tracker">
        {this.props.uploadStatus === uploadStatus.not_started ? (
          <Dropzone className="filepicker" onDrop={this.props.onFilePicked} multiple={false} accept={this.props.acceptedExtensions}>
            <div className="text-container">
              <div className="upload-icon" />
              <div className="text">
                <span>Drag and drop to </span>
                <span className="underlined">upload file</span>
              </div>
            </div>
            <div className={this.props.graphicClass} />
          </Dropzone>
        ) : (
          <div className="upload-progress-container">
            {this.props.uploadStatus === uploadStatus.in_progress && (
              <div className="progress-indicator-container">
                <CircularProgressbar percentage={this.props.uploadProgress} />
              </div>
            )}
            {this.props.uploadStatus === uploadStatus.uploaded_successfully && (
              <div className="success-icon-green" />
            )}

            <div className="status-text">
              {this.props.uploadStatus === uploadStatus.in_progress && 'Uploading'}
              {this.props.uploadStatus === uploadStatus.uploaded_successfully && 'Successfully uploaded'}
            </div>

            <div className="filename-text">{this.props.filename}</div>
          </div>
        )}
      </div>
    )
  }
}

FileUploadTracker.propTypes = {
  uploadStatus: PropTypes.string,
  graphicClass: PropTypes.string,
  uploadProgress: PropTypes.number,
  filename: PropTypes.string,
  acceptedExtensions: PropTypes.string,
  onFilePicked: PropTypes.func,
}

export default FileUploadTracker
