import React from "react"
import PropTypes from "prop-types"

import NumberFormat from 'react-number-format'
import moment from 'moment'

import LockableLabel from '../forms/LockableLabel'
import LockableTextInput from '../forms/LockableTextInput'
import LockableNumberInput from '../forms/LockableNumberInput'
import LockableDropdown from '../forms/LockableDropdown'
import * as validations from '../../util/validations'

class UserEditorForm extends React.Component {
  constructor(props) {
    super(props)

    this.valueOrEmptyString = this.valueOrEmptyString.bind(this)
    this.userFromState = this.userFromState.bind(this)
    this.onChangeFirstname = this.onChangeFirstname.bind(this)
    this.onChangeLastname = this.onChangeLastname.bind(this)
    this.onChangeDobDay = this.onChangeDobDay.bind(this)
    this.onChangeDobMonth = this.onChangeDobMonth.bind(this)
    this.onChangeDobYear = this.onChangeDobYear.bind(this)
    this.onChangeCountryCode = this.onChangeCountryCode.bind(this)
    this.onChangePhoneNumber = this.onChangePhoneNumber.bind(this)
    this.onChangeOccupation = this.onChangeOccupation.bind(this)
    this.emptyStringValidator = this.emptyStringValidator.bind(this)
    this.yearValidator = this.yearValidator.bind(this)
    this.monthValidator = this.monthValidator.bind(this)
    this.dayValidator = this.dayValidator.bind(this)
    this.countryCodeValidator = this.countryCodeValidator.bind(this)
    this.phoneNumberValidator = this.phoneNumberValidator.bind(this)

    this.DOB_FORMAT = "DD/MM/YYYY"

    // parse birthday
    const dob = !this.props.user.date_of_birth ? undefined : moment(this.props.user.date_of_birth, this.DOB_FORMAT)
    const day = !dob ? undefined : dob.date()
    const month = !dob ? 0 : dob.month()+1 // 0-based to 1-based
    const year = !dob ? undefined : dob.year()

    // parse phone
    var countryCode = ''
    var phoneNumber = ''
    if (this.props.user.phone_number && this.props.user.phone_number.length > 0) {
      var chunks = this.props.user.phone_number.split('-')
      countryCode = chunks.shift()
      phoneNumber = chunks.join('')
    }

    this.state = {
      firstname: this.props.user.firstname,
      lastname: this.props.user.lastname,
      dobDay: day,
      dobMonth: month,
      dobYear: year,
      countryCode: countryCode,
      phoneNumber: phoneNumber,
      occupation: this.props.user.occupation,
    }
  }

  valueOrEmptyString(value) {
    return !value ? '' : value
  }

  userFromState(currentState) {
    var user = {
      ...this.props.user,
      firstname: currentState.firstname,
      lastname: currentState.lastname,
      occupation: currentState.occupation,
      date_of_birth: '',
      phone_number: '',
    }

    if (currentState.dobDay && currentState.dobDay > 0 &&
        currentState.dobMonth && currentState.dobMonth > 0 &&
        currentState.dobYear && currentState.dobYear > 0) {

      const dobString = currentState.dobDay+'/'+currentState.dobMonth+'/'+currentState.dobYear
      if (moment(dobString, this.DOB_FORMAT).isValid()) {
        user.date_of_birth = dobString
      }
    }

    if (currentState.countryCode && currentState.countryCode.length > 0 &&
        currentState.phoneNumber && currentState.phoneNumber.length > 0) {
      user.phone_number = currentState.countryCode+'-'+currentState.phoneNumber
    }

    return user
  }

  onChangeFirstname(e) {
    const newState = {
      ...this.state,
      firstname: e.target.value,
    }

    this.setState(newState)
    this.props.onUpdate(this.userFromState(newState))
  }

  onChangeLastname(e) {
    const newState = {
      ...this.state,
      lastname: e.target.value,
    }

    this.setState(newState)
    this.props.onUpdate(this.userFromState(newState))
  }

  onChangeDobDay(values, e) {
    const newState = {
      ...this.state,
      dobDay: values.floatValue,
    }

    this.setState(newState)
    this.props.onUpdate(this.userFromState(newState))
  }

  onChangeDobMonth(e) {
    const newState = {
      ...this.state,
      dobMonth: Number(e.target.value),
    }

    this.setState(newState)
    this.props.onUpdate(this.userFromState(newState))
  }

  onChangeDobYear(values, e) {
    const newState = {
      ...this.state,
      dobYear: values.floatValue,
    }

    this.setState(newState)
    this.props.onUpdate(this.userFromState(newState))
  }

  onChangeCountryCode(values, e) {
    const newState = {
      ...this.state,
      countryCode: values.value,
    }

    this.setState(newState)
    this.props.onUpdate(this.userFromState(newState))

    if (values.value == "1") {
      // has to be on a 0ms timeout because of react-number-input
      // see https://github.com/s-yadav/react-number-format/issues/213
      setTimeout(function(){ $('.phone-number-input').focus() }, 0)
    }
  }

  onChangePhoneNumber(values, e) {
    const newState = {
      ...this.state,
      phoneNumber: values.value,
    }

    this.setState(newState)
    this.props.onUpdate(this.userFromState(newState))
  }

  onChangeOccupation(e) {
    const newState = {
      ...this.state,
      occupation: e.target.value,
    }

    this.setState(newState)
    this.props.onUpdate(this.userFromState(newState))
  }

  emptyStringValidator(fieldName) {
    return () => {
      return !this.props.mandatoryFields.includes(fieldName) || !!this.state[fieldName] && this.state[fieldName].length > 0
    }
  }

  yearValidator() {
    return !this.props.mandatoryFields.includes('dob') || !!this.state.dobYear && this.state.dobYear > 0 && this.state.dobYear <= (new Date()).getFullYear()
  }

  monthValidator() {
    return !this.props.mandatoryFields.includes('dob') || !!this.state.dobMonth && this.state.dobMonth > 0 && this.state.dobMonth <= 12
  }

  dayValidator() {
    return !this.props.mandatoryFields.includes('dob') || !!this.state.dobDay && this.state.dobDay > 0 && this.state.dobDay <= 31
  }

  countryCodeValidator() {
    return !this.props.mandatoryFields.includes('phoneNumber') || !!this.state.countryCode && this.state.countryCode > 0 && this.state.countryCode <= 9999
  }

  phoneNumberValidator() {
    return !this.props.mandatoryFields.includes('phoneNumber') || !!this.state.phoneNumber && this.state.phoneNumber.length > 0 && this.state.phoneNumber.length <= 16
  }

  render () {
    const monthDropdownItems = {
      0: 'Month',
      1: 'January',
      2: 'February',
      3: 'March',
      4: 'April',
      5: 'May',
      6: 'June',
      7: 'July',
      8: 'August',
      9: 'September',
      10: 'October',
      11: 'November',
      12: 'December',
    }

    return (
      <div className="user-editor-form">
        <div className="profile-row">
          <div className="profile-col left">
            <LockableLabel className="label" text="First name" isLocked={this.props.lockedFields.includes('firstname')} tooltipText="You account is verified. Contact us if you'd like to change this information." />
            {(this.props.mandatoryFields.includes('firstname') && !this.props.lockedFields.includes('firstname')) && (
              <span className="mandatory-asterisk">*</span>
            )}
            <LockableTextInput
              value={this.state.firstname}
              placeholder="First name"
              onChange={this.onChangeFirstname}
              isLocked={this.props.lockedFields.includes('firstname')}
              validator={this.emptyStringValidator('firstname')} />
          </div>

          <div className="profile-col right">
            <LockableLabel className="label" text="Last name" isLocked={this.props.lockedFields.includes('lastname')} tooltipText="You account is verified. Contact us if you'd like to change this information." />
            {(this.props.mandatoryFields.includes('lastname') && !this.props.lockedFields.includes('lastname')) && (
              <span className="mandatory-asterisk">*</span>
            )}
            <LockableTextInput
              value={this.state.lastname}
              placeholder="Last name"
              onChange={this.onChangeLastname}
              isLocked={this.props.lockedFields.includes('lastname')}
              validator={this.emptyStringValidator('lastname')} />
          </div>
        </div>

        <div className="profile-row">
          <div className="profile-col left">
            <LockableLabel className="label" text="Date of birth" isLocked={this.props.lockedFields.includes('dob')} tooltipText="You account is verified. Contact us if you'd like to change this information." />
            {(this.props.mandatoryFields.includes('dob') && !this.props.lockedFields.includes('dob')) && (
              <span className="mandatory-asterisk">*</span>
            )}
            <div className="dob-container">
              <LockableNumberInput
                className="year-input"
                value={this.state.dobYear}
                onChange={this.onChangeDobYear}
                decimalScale={0}
                allowNegative={false}
                placeholder="YYYY"
                format="####"
                validator={this.yearValidator}
                isAllowed={(values) => { return values.value.length == 0 || (values.floatValue > 0 && values.floatValue <= (new Date()).getFullYear()) }}
                isLocked={this.props.lockedFields.includes('dob')} />

              <LockableDropdown
                className="month-input"
                value={this.state.dobMonth}
                items={monthDropdownItems}
                onChange={this.onChangeDobMonth}
                isLocked={this.props.lockedFields.includes('dob')}
                validator={this.monthValidator} />

              <LockableNumberInput
                className="day-input"
                value={this.state.dobDay}
                onChange={this.onChangeDobDay}
                decimalScale={0}
                allowNegative={false}
                placeholder="DD"
                format="##"
                validator={this.dayValidator}
                isAllowed={(values) => { return values.value.length == 0 || (values.floatValue > 0 && values.floatValue <= 31) }}
                isLocked={this.props.lockedFields.includes('dob')} />
            </div>
          </div>

          <div className="profile-col right">
            <LockableLabel className="label" text="Phone number" isLocked={this.props.lockedFields.includes('phoneNumber')} tooltipText="You account is verified. Contact us if you'd like to change this information." />
            {(this.props.mandatoryFields.includes('phoneNumber') && !this.props.lockedFields.includes('phoneNumber')) && (
              <span className="mandatory-asterisk">*</span>
            )}
            <div className="phone-input-container">
              <LockableNumberInput
                className="country-code-input"
                value={this.state.countryCode}
                onChange={this.onChangeCountryCode}
                decimalScale={0}
                allowNegative={false}
                placeholder="+1"
                prefix="+"
                isNumericString={true}
                validator={this.countryCodeValidator}
                isAllowed={(values) => {return values.value === '' || values.floatValue <= 9999}}
                isLocked={this.props.lockedFields.includes('phone_number')} />

              <LockableNumberInput
                className="phone-number-input"
                value={this.state.phoneNumber}
                onChange={this.onChangePhoneNumber}
                decimalScale={0}
                allowNegative={false}
                placeholder="E.g. (647)-123-0000"
                isNumericString={true}
                format="(###) ###-##########"
                validator={this.countryCodeValidator}
                isLocked={this.props.lockedFields.includes('phone_number')} />
            </div>
          </div>
        </div>

        <div className="profile-row">
          <div className="profile-col left">
            <LockableLabel className="label" text="Occupation" isLocked={this.props.lockedFields.includes('occupation')} tooltipText="You account is verified. Contact us if you'd like to change this information." />
            {(this.props.mandatoryFields.includes('occupation') && !this.props.lockedFields.includes('occupation')) && (
              <span className="mandatory-asterisk">*</span>
            )}

            <LockableTextInput
              value={this.state.occupation}
              placeholder="E.g. Project manager"
              onChange={this.onChangeOccupation}
              isLocked={this.props.lockedFields.includes('occupation')}
              validator={this.emptyStringValidator('occupation')} />
          </div>

          {this.props.showEmail && (
            <div className="profile-col right">
              <LockableLabel className="label" text="Email" tooltipText="Email cannot be changed" isLocked={true} tooltipText="Email cannot be changed." />
              <LockableTextInput
                value={this.props.user.email}
                isLocked={true} />
            </div>
          )}
        </div>
      </div>
    )
  }
}

UserEditorForm.propTypes = {
  user: PropTypes.object,
  onUpdate: PropTypes.func,
  lockedFields: PropTypes.array,
  mandatoryFields: PropTypes.array,
  showEmail: PropTypes.bool,
}

UserEditorForm.defaultProps = {
  showEmail: false,
  lockedFields: [],
  mandatoryFields: [],
}

export default UserEditorForm
