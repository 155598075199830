import React from "react"
import PropTypes from "prop-types"

import ReactHintFactory from 'react-hint'
const ReactHint = ReactHintFactory(React)

import AddressEditorForm from '../profile/AddressEditorForm'

class RedeemMailForm extends React.Component {
  constructor(props) {
    super(props)

    this.onAddressEditorFormUpdate = this.onAddressEditorFormUpdate.bind(this)

    this.state = {
      redeemRequest: this.props.redeemRequest,
    }

    if (!this.state.redeemRequest.mailDetails) {
      // deep copy
      this.state.redeemRequest.mailDetails = JSON.parse(JSON.stringify(this.props.user.address))
    }

    // force a parent update to enable the submit button if everything is filled out correctly
    this.props.onUpdate(this.state.redeemRequest)
  }

  onAddressEditorFormUpdate(newAddress) {
    const newRedeemRequest = {
      ...this.state.redeemRequest,
      mailDetails: newAddress,
    }

    this.setState({
      ...this.state,
      redeemRequest: newRedeemRequest,
    })

    this.props.onUpdate(newRedeemRequest)
  }

  renderTooltip(target, content) {
    return (
      <div className="tooltip-content">
        <div className="tooltip-title">Disclaimer</div>
        <div>
          <p>We can only ship to the physical address you submitted during identity verification procedures.</p>
          <p><a href="mailto:support@balancenow.ca">Contact our support</a> if your address has changed.</p>
        </div>
      </div>
    )
  }

  render () {
    return (
      <div className="redeem-mail-form redeem-form">
        <div className="title-container">
          <div className="title">Mailing address</div>
          <div className="question-icon-blue" data-redeem-mail-tooltip />
        </div>
        <AddressEditorForm
          address={this.state.redeemRequest.mailDetails}
          onUpdate={this.onAddressEditorFormUpdate}
          lockedFields={['country', 'province', 'city', 'street', 'postal_code']} />

        <ReactHint
          position="top"
          events
          className="tooltip"
          attribute="data-redeem-mail-tooltip"
          onRenderContent={this.renderTooltip}
          delay={100}
          persist={true} />
      </div>
    )
  }
}

RedeemMailForm.propTypes = {
  user: PropTypes.object,
  redeemRequest: PropTypes.object,
  onUpdate: PropTypes.func,
}

export default RedeemMailForm
