import React from "react"
import PropTypes from "prop-types"

import * as currencyHelpers from '../../util/currency'

// This component will display a piece of text and show an edit button on hover.
// After clicking the edit button, the text gets replaced by an input field and a save/discard button.
class CurrencyLabel extends React.Component {
  getLabelText = () => {
    if (this.props.showFullName) {
      var labelText = currencyHelpers.getFullNameForCurrency(this.props.currency)

      if(this.props.showCurrencyTag) {
        labelText += ' (' + this.props.currency.toUpperCase() + ')'
      }

      return labelText
    }

    return this.props.currency.toUpperCase()
  }

  render () {
    return (
      <div className={"currency-label-container " + this.props.className}>
        {this.props.showIcon && (
          <div
            className={"currency-icon " + currencyHelpers.getIconClassForCurrency(this.props.currency)}
          />
        )}

        {this.props.showText && (
          <div className="currency-label">
            {this.getLabelText()}
          </div>
        )}
      </div>
    )
  }
}

CurrencyLabel.propTypes = {
  currency: PropTypes.string,
  className: PropTypes.string,
  showIcon: PropTypes.bool,
  showText: PropTypes.bool,
  showFullName: PropTypes.bool,
  showCurrencyTag: PropTypes.bool,
}

CurrencyLabel.defaultProps = {
  className: "",
  showIcon: true,
  showText: true,
  showFullName: false,
}

export default CurrencyLabel

