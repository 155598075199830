import React from "react"
import PropTypes from "prop-types"

import PurchaseOrderQuestionnaire from "./PurchaseOrderQuestionnaire"
import KycProgressBar from "./KycProgressBar"
import ProfileTab from "./ProfileTab"
import AddressTab from "./AddressTab"
import DocumentsTab from "./DocumentsTab"
import * as kycStage from "../../util/kyc/kyc_stage"
import * as kycStatus from "../../util/kyc/kyc_status"

class KycWorkflow extends React.Component {
  constructor(props) {
    super(props)

    this.shouldShowQuestionnaire = this.shouldShowQuestionnaire.bind(this)
    this.afterQuestionnaireSubmitted = this.afterQuestionnaireSubmitted.bind(this)
    this.afterProfileSubmitted = this.afterProfileSubmitted.bind(this)
    this.afterAddressSubmitted = this.afterAddressSubmitted.bind(this)
    this.afterDocumentsSubmitted = this.afterDocumentsSubmitted.bind(this)
    this.onDocumentsUpdate = this.onDocumentsUpdate.bind(this)
    this.inferCurrentStage = this.inferCurrentStage.bind(this)
    this.onClickProfile = this.onClickProfile.bind(this)
    this.onClickAddress = this.onClickAddress.bind(this)
    this.onClickDocuments = this.onClickDocuments.bind(this)

    this.state = {
      purchaseOrder: this.props.purchaseOrder,
      user: this.props.user,
    }

    this.state.currentStage = this.inferCurrentStage()
  }

  shouldShowQuestionnaire() {
    return !this.props.avoidQuestionnaire && !this.state.purchaseOrder.questionnaire.is_completed
  }

  afterQuestionnaireSubmitted(purchaseOrder) {
    this.setState({
      ...this.state,
      purchaseOrder: purchaseOrder,
    })

    if (this.state.user.kyc_verification.status === kycStatus.VERIFIED) {
      this.props.onComplete(this.state.user, purchaseOrder)
    } else {
      this.props.onUpdate(this.state.user, purchaseOrder)
    }
  }

  afterProfileSubmitted(newUser) {
    this.setState({
      ...this.state,
      user: newUser,
      currentStage: kycStage.ADDRESS,
    })

    this.props.onUpdate(newUser, this.state.purchaseOrder)
  }

  afterAddressSubmitted(newAddress) {
    const newUser = {
      ...this.state.user,
      address: newAddress,
    }

    this.setState({
      ...this.state,
      user: newUser,
      currentStage: kycStage.DOCUMENTS,
    })

    this.props.onUpdate(newUser, this.state.purchaseOrder)
  }

  afterDocumentsSubmitted(user) {
    this.setState({
      ...this.state,
      user: user,
    })

    this.props.onComplete(user, this.state.purchaseOrder)
  }

  onDocumentsUpdate(user) {
    this.setState({
      ...this.state,
      user: user,
    })
  }

  inferCurrentStage() {
    if (kycStage.isProfileCompleted(this.state.user)) {
      if (kycStage.isAddressCompleted(this.state.user)) {
        return kycStage.DOCUMENTS
      }
      return kycStage.ADDRESS
    }
    return kycStage.PROFILE
  }

  onClickProfile() {
    this.setState({
      ...this.state,
      currentStage: kycStage.PROFILE,
    })
  }

  onClickAddress() {
    if (kycStage.isProfileCompleted(this.state.user)) {
      this.setState({
        ...this.state,
        currentStage: kycStage.ADDRESS,
      })
    }
  }

  onClickDocuments() {
    if (kycStage.isAddressCompleted(this.state.user)) {
      this.setState({
        ...this.state,
        currentStage: kycStage.DOCUMENTS,
      })
    }
  }

  render () {
    return (
      <div className="kyc-workflow-container">
        <div className="title-bar">
          {this.shouldShowQuestionnaire() ? (
            <div className="modal-title">Questionnaire</div>
          ) : (
            <KycProgressBar
              purchaseOrder={this.state.purchaseOrder}
              currentStage={this.state.currentStage}
              user={this.state.user}
              onClickProfile={this.onClickProfile}
              onClickAddress={this.onClickAddress}
              onClickDocuments={this.onClickDocuments} />
          )}
        </div>
        <div className="divider" />

        {this.shouldShowQuestionnaire() ? (
          <PurchaseOrderQuestionnaire purchaseOrder={this.state.purchaseOrder} afterSubmitted={this.afterQuestionnaireSubmitted} />
        ) : (
          <React.Fragment>
            {this.state.currentStage === kycStage.PROFILE && (
              <ProfileTab user={this.state.user} afterSubmitted={this.afterProfileSubmitted} />
            )}

            {this.state.currentStage === kycStage.ADDRESS && (
              <AddressTab address={this.state.user.address} afterSubmitted={this.afterAddressSubmitted} />
            )}

            {this.state.currentStage === kycStage.DOCUMENTS && (
              <DocumentsTab user={this.state.user} afterSubmitted={this.afterDocumentsSubmitted} onUpdate={this.onDocumentsUpdate} />
            )}
          </React.Fragment>
        )}
      </div>
    )
  }
}

KycWorkflow.propTypes = {
  purchaseOrder: PropTypes.object,
  user: PropTypes.object,
  avoidQuestionnaire: PropTypes.bool,
  onUpdate: PropTypes.func,
  onComplete: PropTypes.func,
}

export default KycWorkflow
