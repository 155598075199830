import WAValidator from 'multicoin-address-validator'
import bech32 from 'bech32'

const XRP_BASE_RESERVE = 20
const XLM_BASE_RESERVE = 1

export const getIconClassForCurrency = (currency) => {
  return {
    "btc": "icon-btc",
    "bch": "icon-bch",
    "eth": "icon-eth",
    "etc": "icon-etc",
    "ltc": "icon-ltc",
    "dash": "icon-dash",
    "xlm": "icon-xlm",
    "xrp": "icon-xrp",
    "pax": "icon-pax",
    "usdc": "icon-usdc",
    "usdt": "icon-usdt",
    "qcad": "icon-qcad",
    "eurs": "icon-eurs",
    "brlm": "icon-brlm",
    "paxg": "icon-paxg",
    "cdag": "icon-cdag",
    "aion": "icon-aion",
    "axia": "icon-axia",
    "xmr": "icon-xmr",
  }[currency.toLowerCase()]
}

export const getFullNameForCurrency = (currency) => {
  return {
    "btc": "Bitcoin",
    "bch": "Bitcoin Cash",
    "eth": "Ethereum",
    "etc": "Ethereum Classic",
    "ltc": "Litecoin",
    "dash": "Dash",
    "xlm": "Stellar",
    "xrp": "Ripple",
    "pax": "Paxos",
    "usdc": "USD Coin",
    "usdt": "Tether",
    "qcad": "Stablecorp QCAD",
    "eurs": "Stasis EURS",
    "brlm": "BR.Mint",
    "paxg": "PAX Gold",
    "cdag": "Canadian Natural Dollar",
    "aion": "Aion",
    "axia": "AXIA Coin",
    "xmr": "Monero",
  }[currency.toLowerCase()]
}

export const getPrimaryColorForCurrency = (currency) => {
  return {
    "btc":  "#f69900",
    "eth":  "#4a4ba7",
    "xrp":  "#c80785",
    "xlm":  "#051292",
    "ltc":  "#bcbcbc",
    "dash": "#017785",
    "etc":  "#e94646",
    "bch":  "#4cd137",
    "pax":  "#33d9b2",
    "busd": "#f1c40f",
    "fil":  "#0075d2",
    "usdc": "#114ae0",
    "usdt": "#00ae71",
    "qcad": "#181818",
    "eurs": "#57c8ed",
    "brlm": "#6e0fb6",
    "paxg": "#cca727",
    "cdag": "#666666",
    "aion": "#0075d2",
    "axia": "#C7C7C7",
    "xmr":  "#ff5f00",
  }[currency.toLowerCase()]
}

export const getSupportedCurrencies = () => {
  return [
    'btc',
    'bch',
    'dash',
    'eth',
    'etc',
    'ltc',
    'xrp',
    'xlm',
    'pax',
    'usdc',
    'usdt',
    'qcad',
    'eurs',
    'brlm',
    'paxg',
    'cdag',
    'aion',
    'axia',
    'xmr',
  ]
}

// signify currencies that are supported by default on the platform and allow
// operations like dac purchases, top-ups, and fiat redemption
export const getPrimaryCurrencies = () => {
  return [
    'btc',
    'bch',
    'dash',
    'eth',
    'etc',
    'ltc',
    'xrp',
    'xlm',
  ]
}

export const getSamplePublicAddress = (currency) => {
  return {
    btc: '1CKGsWbeqmmZq2HaFE45ue8sjwuSdbTgiW',
    eth: '0x8113ca0fdee842087a44054f99ec54012ef55a44',
    xrp: 'rudnN9JQwY3uVxcZ33xRkzzuLHFW9ojPo',
    xlm: 'GAW43YZGJQQNFRDATNBX6CSS3EMYUDPPJPI3VGQPNBEYDGQIX4VRCQ7J',
    etc: '0x9B87B878AE30dB27cb9FA6b1743CC41E2ef97058',
    ltc: 'LXEGJPGmt3cpREM4u7fJfMS2B6ARPq5bZH',
    bch: '1EtKgytxQfX2Jtsd6tcApgAbJjr2vXZRLz',
    dash: 'XeAsfyGVvugkTCJeJHj1g9B6aLUqrwMxpw',
    pax: '0x8113ca0fdee842087a44054f99ec54012ef55a44',
    usdc: '0x8113ca0fdee842087a44054f99ec54012ef55a44',
    usdt: '0x8113ca0fdee842087a44054f99ec54012ef55a44',
    qcad: '0x8113ca0fdee842087a44054f99ec54012ef55a44',
    eurs: '0x8113ca0fdee842087a44054f99ec54012ef55a44',
    brlm: '0x8113ca0fdee842087a44054f99ec54012ef55a44',
    paxg: '0x8113ca0fdee842087a44054f99ec54012ef55a44',
    cdag: '0x8113ca0fdee842087a44054f99ec54012ef55a44',
    aion: '0xa051d1b643325d00c52e8f1b6adaf27823d03d37675bcd92749327672f3b10e9',
    axia: '0xa051d1b643325d00c52e8f1b6adaf27823d03d37675bcd92749327672f3b10e9',
    xmr: '49B1eJURzeCLzWV5K4TzArEfY7UTAWeKTLe3QmVZ2uUV7mEhnt55husH31omCEphJjdHWZFB5MdMXYnf7Gab1DVJFenmnq3',
  }[currency]
}

export const getNextCurrency = (cur) => {
  const currencies = getSupportedCurrencies()
  const curIndex = currencies.indexOf(cur)
  if (curIndex < 0) { return 0 }
  return currencies[(curIndex+1) % currencies.length]
}

export const getPreviousCurrency = (cur) => {
  const currencies = getSupportedCurrencies()
  const curIndex = currencies.indexOf(cur)
  if (curIndex < 0) { return 0 }
  var prevIndex = curIndex - 1
  if (prevIndex < 0) { prevIndex = currencies.length - 1 }
  return currencies[prevIndex]
}

export const getLockedAmount = (currency) => {
  switch(currency) {
    case 'xlm':
      return 1
    case 'xrp':
      return 20
    default:
      return 0
  }
}

export const getCurrenciesForTransfer = (amounts) => {
  return Object.keys(amounts).filter((cur, i) => {
    return amounts[cur] - getLockedAmount(cur) > 0
  })
}

export const isERC20 = (currency) => {
  return ['pax', 'usdc', 'usdt', 'qcad', 'eurs', 'brlm', 'paxg', 'cdag'].includes(currency)
}

export const isTransferPossible = (amounts, currency) => {
  if (isERC20(currency)) {
    // ERC20 is a special case. We need to make sure the wallet has ETH balance in order to pay
    // for gas required to send ERC20.
    return amounts['eth'] && amounts['eth'] >= 0.0005
  }

  return true
}

export const maxTransferableAmount = (currency, amount) => {
  if(currency == 'xlm') {
    return Math.max(amount - XLM_BASE_RESERVE, 0)
  }

  if(currency == 'xrp') {
    return Math.max(amount - XRP_BASE_RESERVE, 0)
  }

  return amount
}

export const isValidPublicAddress = (currency, address) => {
  // use wallet-address-validator package to validate most addresses:
  // https://www.npmjs.com/package/multicoin-address-validator

  // disallow 3-style addresses
  if (currency === 'ltc' && address && address[0] === '3') {
    return false
  }

  if (currency === 'ltc') {
    return isLTCAddressValid(address)
  }

  if (currency === 'bch' && !isBCHAddressValid(address)) {
    return false
  }

  if (currency === "xmr") {
    return isXMRAddressValid(address)
  }

  if (currency === "aion" || currency === "axia") {
    return isAionAddressValid(address)
  }

  var validatorCurrency = currency
  if (isERC20(currency)) {
    validatorCurrency = 'eth'
  }

  return address && address.length > 0 && WAValidator.validate(address, validatorCurrency)
}

export const isBCHAddressValid = (address) => {
  if (address) {
    if(address.startsWith("bitcoincash:")){
      return WAValidator.validate(address, 'bch')
    } else {
      return WAValidator.validate(address, 'bch') || WAValidator.validate("bitcoincash:" + address, 'bch')
    }
  }

  return false
}

export const isLTCAddressValid = (address) => {
  if (address.substr(0, 4) === 'ltc1') {
    try {
      var decoded = bech32.decode(address)
      return decoded && decoded.prefix === 'ltc'
    } catch(ex) {
      return false
    }
  }

  return WAValidator.validate(address, 'ltc')
}

export const isXMRAddressValid = (address) => {
  return address && address.length > 0 && (
    WAValidator.validate(address, 'xmr') // validate address
    || address.match(/8[0-9a-zA-Z]{94}/) // validate subaddress
  )
}

export const isAionAddressValid = (address) => {
  return address && address.length > 0 &&
    address.match(/0xa0[0-9a-fA-F]{62}/)
}

export const isValidXlmMemo = (memo) => {
  return memo.length < 28
}

export const isValidXrpTag = (tag) => {
  return Number.isInteger(Number(tag))
}
