import React from "react"
import PropTypes from "prop-types"

import redeemKind from "../../util/redeem/redeem_kind"
import * as currencyHelpers from "../../util/currency"

class RedeemMethodPickerTab extends React.Component {
  constructor(props) {
    super(props)

    var selectedMethod = undefined
    if (this.props.redeemRequest) {
      selectedMethod = this.props.redeemRequest.kind
    }

    this.state = {
      selectedMethod: selectedMethod,
      disableFiatOption: this.isFiatOptionDisabled(),
    }
  }

  isFiatOptionDisabled = () => {
    var permittedCurrencies = currencyHelpers.getPrimaryCurrencies()
    var selectedCurrencies = Object.keys(this.props.redeemRequest.amounts)

    var shouldDisable = false
    selectedCurrencies.forEach((cur, i) => {
      if (!permittedCurrencies.includes(cur)) {
        shouldDisable = true
      }
    })

    return shouldDisable
  }

  onPickMethod = (newSelectedMethod) => {
    return () => {
      if (this.state.disableFiatOption && newSelectedMethod == redeemKind.FIAT) {
        return false
      }
      this.setState({
        ...this.state,
        selectedMethod: newSelectedMethod,
      })
    }
  }

  onSubmit = () => {
    if (this.state.selectedMethod) {
      this.props.onSubmit(this.state.selectedMethod)
    }
  }

  render () {
    return (
      <div className="redeem-method-picker-tab">
        <div className="title">
          Choose your preferred withdrawal method:
        </div>

        <div
          className={"method-container " + (this.state.selectedMethod === redeemKind.FIAT ? 'active' : '') + (this.state.disableFiatOption ? 'disabled' : '')}
          onClick={this.onPickMethod(redeemKind.FIAT)}
          disabled={this.state.disableFiatOption}
        >
          <div className={"radio-icon " + (this.state.selectedMethod === redeemKind.FIAT ? 'active' : '')}>
            <div className="radio-icon-inner" />
          </div>
          <div className="method-content">
            <div className="method-title">Sell for CAD or USD</div>
            <div className="method-description">
              <span className="price-text">FREE</span>
              <span className="processing-time-text">2-5 business days</span>
            </div>
            <div className="method-description">
              {this.state.disableFiatOption && (
                <span className="description-text">This option is only available for Bitcoin, Bitcoin Cash, Dash, Ethereum, Ethereum Classic, Litecoin, Ripple, and Stellar.</span>
              )}
              {!this.state.disableFiatOption && (
                <span className="description-text">Proceeds will be wired to your bank account.</span>
              )}
            </div>
          </div>
        </div>

        <div className={"method-container " + (this.state.selectedMethod === redeemKind.CRYPTO ? 'active' : '')} onClick={this.onPickMethod(redeemKind.CRYPTO)}>
          <div className={"radio-icon " + (this.state.selectedMethod === redeemKind.CRYPTO ? 'active' : '')}>
            <div className="radio-icon-inner" />
          </div>

          <div className="method-content">
            <div className="method-title">Transfer to external digital wallets</div>
            <div className="method-description">
              <span className="price-text">FREE</span>
              <span className="processing-time-text">2-5 business days</span>
            </div>
            <div className="method-description">
              <span className="description-text">Blockchain fees will be deducted and your assets transferred to your external wallet addresses.</span>
            </div>
          </div>
        </div>

        <div className={"method-container " + (this.state.selectedMethod === redeemKind.MAIL ? 'active' : '')} onClick={this.onPickMethod(redeemKind.MAIL)}>
          <div className={"radio-icon " + (this.state.selectedMethod === redeemKind.MAIL ? 'active' : '')}>
            <div className="radio-icon-inner" />
          </div>
          <div className="method-content">
            <div className="method-title">Ship a paper wallet to my physical address</div>
            <div className="method-description">
              <span className="price-text">CAD $20 + Tax</span>
              <span className="processing-time-text">Delivery times vary</span>
            </div>
            <div className="method-description">
              <span className="description-text">A paper wallet encrypted with a secret known only by you will be delivered to your mailing address within a few business days.</span>
            </div>
          </div>
        </div>

        <button className={"submit-btn pv-btn " + (this.state.selectedMethod ? '' : 'disabled')} onClick={this.onSubmit}>Continue</button>
      </div>
    )
  }
}

RedeemMethodPickerTab.propTypes = {
  user: PropTypes.object,
  redeemRequest: PropTypes.object,
  isSettlementClient: PropTypes.bool,
  onSubmit: PropTypes.func,
}

export default RedeemMethodPickerTab
