import React from "react"
import PropTypes from "prop-types"

import { timeFormat } from "d3-time-format"
import { CSSTransitionGroup } from 'react-transition-group'
import ReactHintFactory from 'react-hint'
const ReactHint = ReactHintFactory(React)

import AreaChart from '../graph/AreaChart'

import * as format from '../../util/formatting'
import * as stats from '../../util/stats'
import * as privacyUtils from "../../util/privacy"
import * as purchaseStatus from '../../util/builder/purchase_status'
import * as purchaseKind from '../../util/builder/purchase_kind'
import * as purchaseOrderHelpers from '../../util/builder/purchase_order'

class DacSummary extends React.Component {
  constructor(props) {
    super(props)

    const DEFAULT_PERIOD = 'week'

    this.graphContainer = React.createRef()
    this.state = {
      graphReadyForDisplay: false,
      graphWidth: 0,
      graphHeight: 0,
      period: DEFAULT_PERIOD,
    }
  }

  getGraphData = (period) => {
    // Data for the weekly chart has a 2-hour resolution.
    // Data for the monthly chart has a 1-day resolution.
    // Data for the yearly chart has a 1-day resolution.
    // Data comes in the following format: {currency: [cad_price(1 week ago), cad_price(...+2 hours), cad_price(...+2 hours)...]}
    // Assume that the number of data points for each currency will be equal

    if (!period) {
      period = this.state.period
    }
    let pricesAccessor = (currency) => {
      return this.props.assetSummary[currency]['prices_'+period]
    }

    let resolution = {
      week: 1000 * 60 * 60 * 2, // 2 hours
      month: 1000 * 60 * 60 * 24, // 1 day
      year: 1000 * 60 * 60 * 24, // 1 day
    }[period]

    const currencies = this.props.dac.currencies

    let num_data_points = pricesAccessor(Object.keys(currencies)[0]).length
    let range_end_date = new Date() // now

    let data_period = []
    for (let i = 0; i < num_data_points; ++i) {
      let cad_value = 0

      Object.keys(currencies).forEach((ticker, j) => {
        let currency = currencies[ticker]
        let price = pricesAccessor(ticker)[i]
        if (!price) {
          price = pricesAccessor(ticker)[i-1]
        }
        if (price) {
          cad_value += price * currency.balance
        }
      })

      data_period.push({
        date: new Date(range_end_date.getTime() - resolution * (num_data_points - i - 1)),
        cad_value: cad_value,
      })
    }

    return data_period
  }

  getPctChange = () => {
    if (this.props.purchaseOrder && purchaseStatus.isOrderSubmitted(this.props.purchaseOrder)) {
      if (this.props.dac.status !== 'active' || this.props.isTopUpMode) {
        return 0
      }
    }
    return ((this.props.dac.cad_value / this.props.dac.cost - 1) * 100)
  }

  shouldShowPctChange = () => {
    return this.props.dac.status === 'active' ||
           (this.props.dac.status === 'in_progress' &&
            this.props.purchaseOrder.cad_total > 0 &&
            purchaseOrderHelpers.getTotalProportion(this.props.purchaseOrder) === 100)
  }

  shouldShowOrderSubmittedState = () => {
    if (this.props.isTopUpMode || this.props.dac.status !== 'active') {
      return purchaseStatus.isOrderSubmitted(this.props.purchaseOrder)
    }
    return false
  }

  areProportionsZero = () => {
    const total = Object.keys(this.props.dac.currencies).reduce((acc, x) => {
      return acc + this.props.dac.currencies[x].cad_value
    })

    return total == 0
  }

  getXAxisFormatter = (period) => {
    if (period === 'week') {
      return timeFormat("%a")
    }
    if (period === 'month') {
      return timeFormat("%b %e")
    }
    if (period === 'year') {
      return timeFormat("%b")
    }
  }

  getPeriodDiff = (period) => {
    const graphData = this.getGraphData(period)
    if (!graphData || !graphData.length) {
      return 0
    }

    const firstVal = graphData[0].cad_value
    const lastVal = graphData[graphData.length-1].cad_value

    return ((lastVal / firstVal) - 1) * 100
  }

  updateDimensions = () => {
    if (this.graphContainer.current) {
      this.setState({
        ...this.state,
        graphReadyForDisplay: true,
        graphWidth: this.graphContainer.current.offsetWidth,
        graphHeight: 170,
      })
    }
  }

  setPeriod = (period) => {
    this.setState({
      ...this.state,
      period: period,
    })
  }

  getMarketCoverage = () => {
    const dacRepresentation = this.props.dac
    return Object.keys(dacRepresentation.currencies).reduce((sum, currency) => {
      const proportion = purchaseOrderHelpers.getProportionForCurrency(this.props.purchaseOrder, currency)
      const balance = dacRepresentation.currencies[currency].balance

      // NOTE: don't include currencies if their proportion looks like '0.00' in the UI
      var shouldIncludeAsset = balance > 0 && proportion >= 0.005

      return sum + (shouldIncludeAsset ? Number(this.props.assetSummary[currency].market_coverage_pct) : 0)
    }, 0)
  }

  getSharpeRatio = () => {
    // we get yearly data because its granulated by day
    const graphData = this.getGraphData('year')

    let prices = graphData.map((data, i) => {
      return data.cad_value
    })

    return stats.sharpeRatio(prices)
  }

  componentDidMount = () => {
    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions)
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateDimensions)
  }

  componentDidUpdate = () => {
    if (!this.state.graphReadyForDisplay) {
      this.updateDimensions()
    }
  }

  render = () => {
    var pctChangeTooltipText = "You can track the changes of your digital assets until they are purchased."
    if (this.props.dac.status === 'active') {
      if (!this.props.purchaseOrder || !this.props.isTopUpMode) {
        pctChangeTooltipText = "Shows the change in value of your DAC, since the day of purchase."
      }
    }

    return (
      <div className="dac-summary">
        <div className="header">
          <div className="left">
            <privacyUtils.PrivacyContext.Consumer>
              {privacyEnabled => (
                <React.Fragment>
                  <div className="amount" onClick={this.props.onTogglePrivacy}>
                    {privacyUtils.formatMoney(this.props.dac.cad_value, 2)}
                  </div>
                  <div className="label privacy-container">
                    {this.props.isTopUpMode ? (
                      <span>Top-Up</span>
                    ) : (
                      <React.Fragment>
                        <span>Balance</span>
                      </React.Fragment>
                    )}
                    <div className={"privacy-button " + (privacyEnabled ? 'open' : 'closed')} onClick={this.props.onTogglePrivacy} title={privacyEnabled ? 'Show balance' : 'Hide balance'} />
                  </div>
                </React.Fragment>
              )}
            </privacyUtils.PrivacyContext.Consumer>
          </div>

          {this.shouldShowPctChange() && (
            <div className="right">
              <div className="pct-container">
                {!this.shouldShowOrderSubmittedState() && (
                  <React.Fragment>
                    <div className={"pct-change " + (Number(this.getPctChange().toFixed(2)) >= 0 ? 'positive' : 'negative')}>{format.signedPercentage(this.getPctChange(), 2)}</div>
                    <div className="question-icon-blue" data-summary-date-tooltip={pctChangeTooltipText} />
                  </React.Fragment>
                )}
              </div>

              {this.shouldShowOrderSubmittedState() ? (
                <div className="label">Purchase in progress</div>
              ) : (
                <div className="label">Since {format.shortDate(new Date(Date.parse(this.props.dac.start_date)))}</div>
              )}

              <ReactHint position="top" events className="tooltip" attribute="data-summary-date-tooltip" delay={100} persist={true} />
            </div>
          )}
        </div>
        <div className="divider"></div>

        {(this.props.dac.status !== 'empty' || this.props.purchaseOrder.status !== purchaseStatus.NOT_STARTED) && this.props.dac.cost > 0 ? (
          <div className="asset-price-chart-container">
            <div className="graph-container" ref={this.graphContainer}>
              {this.state.graphReadyForDisplay && (
                <CSSTransitionGroup
                    transitionName="animated-pane-fade"
                    transitionAppear={true}
                    transitionAppearTimeout={400}
                    transitionEnter={false}
                    transitionLeave={false}
                    component="div">

                  <AreaChart
                    width={this.state.graphWidth}
                    height={this.state.graphHeight}
                    data={this.getGraphData()}
                    xAxisFormatter={this.getXAxisFormatter(this.state.period)} />
                </CSSTransitionGroup>
              )}
            </div>

            <CSSTransitionGroup
                transitionName="animated-pane-fade"
                transitionAppear={true}
                transitionAppearTimeout={400}
                transitionEnter={false}
                transitionLeave={false}
                component="div" className="period-selector">

              <div className={"period " + (this.state.period === 'week' ? 'active' : '')} onClick={e => this.setPeriod('week')}>
                <span className="period-label">1W</span>
                {(this.state.period === 'week' || (this.props.purchaseOrder && this.props.purchaseOrder.status === purchaseStatus.EDITING)) && (
                  <span className={"period-diff " + (this.getPeriodDiff('week') > 0 ? 'positive' : 'negative')}>{format.signedPercentage(this.getPeriodDiff('week'), 2)}</span>
                )}
              </div>
              <div className={"period " + (this.state.period === 'month' ? 'active' : '')} onClick={e => this.setPeriod('month')}>
                <span className="period-label">1M</span>
                {(this.state.period === 'month' || (this.props.purchaseOrder && this.props.purchaseOrder.status === purchaseStatus.EDITING)) && (
                  <span className={"period-diff " + (this.getPeriodDiff('month') > 0 ? 'positive' : 'negative')}>{format.signedPercentage(this.getPeriodDiff('month'), 2)}</span>
                )}
              </div>
              <div className={"period " + (this.state.period === 'year' ? 'active' : '')} onClick={e => this.setPeriod('year')}>
                <span className="period-label">1Y</span>
                {(this.state.period === 'year' || (this.props.purchaseOrder && this.props.purchaseOrder.status === purchaseStatus.EDITING)) && (
                  <span className={"period-diff " + (this.getPeriodDiff('year') >= 0 ? 'positive' : 'negative')}>{format.signedPercentage(this.getPeriodDiff('year'), 2)}</span>
                )}
              </div>
            </CSSTransitionGroup>

            {(this.props.purchaseOrder && this.props.purchaseOrder.status === purchaseStatus.EDITING) && (
              <CSSTransitionGroup
                  transitionName="animated-pane-fade"
                  transitionAppear={true}
                  transitionAppearTimeout={400}
                  transitionEnter={false}
                  transitionLeave={false}
                  component="div" className="market-info">

                <div className="info-col">
                  <div className="light">Market Coverage</div>
                  <div className="bold">{format.percentage(this.getMarketCoverage(), 1)}</div>
                  <div className="question-icon" data-dac-summary-tooltip={stats.marketCoverageDescription} />
                </div>
                <div className="info-col">
                  <div className="light">Risk Adjusted Return</div>
                  <div className="bold">{format.variableDecimalDigits(this.getSharpeRatio(), 2, 2)}</div>
                  <div className="question-icon" data-dac-summary-tooltip={stats.riskAdjustedReturnDescription} />
                </div>
              </CSSTransitionGroup>
            )}
            <ReactHint autoPosition events className="tooltip" attribute="data-dac-summary-tooltip" delay={100} persist={true} />
          </div>
        ) : (
          <CSSTransitionGroup
              transitionName="animated-pane-fade"
              transitionAppear={true}
              transitionAppearTimeout={400}
              transitionEnter={false}
              transitionLeave={false}
              component="div" className="empty-dac-container">

            <div className="arrow-icon" />
            {this.props.purchaseOrder.status === purchaseStatus.EDITING ? (
              <div className="text">Drag sliders to pick your proportions</div>
            ) : (
              <div className="text">Select your asset mix</div>
            )}
          </CSSTransitionGroup>
        )}
      </div>
    )
  }
}

DacSummary.propTypes = {
  dac: PropTypes.object,
  purchaseOrder: PropTypes.object,
  assetSummary: PropTypes.object,
  user: PropTypes.object,
  onTogglePrivacy: PropTypes.func,
  isTopUpMode: PropTypes.bool,
}

export default DacSummary
