import React from "react"
import PropTypes from "prop-types"

import axios from '../../util/network'

class PasswordResetTrigger extends React.Component {
  constructor(props) {
    super(props)

    this.onClickResetPassword = this.onClickResetPassword.bind(this)

    this.state = {
      isPasswordResetRequested: false, // shows an informational message after password reset has been clicked
    }
  }

  requestPasswordResetAsync() {
    return axios.post('/reset_password_auth')
      .catch((err) => {
        console.log(err)
      })
  }


  onClickResetPassword() {
    if (this.state.isPasswordResetRequested) { // already requested
      return
    }

    this.requestPasswordResetAsync()
    .then(() => {
      this.setState({
        ...this.state,
        isPasswordResetRequested: true,
      })
    })
  }

  render () {
    return (
      <div className="password-reset-trigger">
        <div>
          <button
            className={"pv-link " + (this.state.isPasswordResetRequested ? 'disabled' : '')}
            onClick={this.onClickResetPassword}>
            Reset password
          </button>
        </div>
        {this.state.isPasswordResetRequested && (
          <div className="reset-confirmation-message">
            We've emailed you a password reset link.
          </div>
        )}
      </div>
    )
  }
}

PasswordResetTrigger.propTypes = {
}

export default PasswordResetTrigger
