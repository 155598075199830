import React from "react"
import PropTypes from "prop-types"
import * as purchaseStatus from '../../util/builder/purchase_status'

class PaymentInstructions extends React.Component {
  constructor(props) {
    super(props)
  }

  render () {
    return (
      <div className="payment-instructions">
        <div className="title-bar">
          <div className="modal-title">Payment Options</div>
        </div>
        <div className="divider" />

        <div className="instructions-content">
          <p>We accept e-Transfers, Direct Deposits, Wire Transfers*, or Bank Drafts in our company name.</p>
          <p>
            <span className="text-bold">Interac e-Transfer:</span> payments@balancenow.ca
            <br />
            Please include the invoice number in the message field and use "balance" (without quotes) as the password.
          </p>

          <br />
          <p className="text-small">
            <span className="text-bold">Beneficiary Name:</span> PARADISO VENTURES INC.
            <br />
            <span className="text-bold">Beneficiary Address:</span> 888 3rd St SW, Bankers Hall West Tower, 10th floor (Attn: Balance), Calgary, Alberta, T2P 5C5, Canada
          </p>

          <br />
          <div className="bank-info-table text-small">
            <div className="bank-info-row text-bold header-row">
              <div className="currency-col">Currency</div>
              <div className="bank-name-col">Bank</div>
              <div className="institution-number-col">Institution</div>
              <div className="transit-number-col">Transit</div>
              <div className="account-number-col">Account</div>
              <div className="swift-col">SWIFT</div>
              <div className="address-col">Branch Address</div>
            </div>

            <div className="bank-info-row">
              <div className="currency-col">CAD</div>
              <div className="bank-name-col">RBC Royal Bank</div>
              <div className="institution-number-col">003</div>
              <div className="transit-number-col">00616</div>
              <div className="account-number-col">1013226</div>
              <div className="swift-col">ROYCCAT2</div>
              <div className="address-col">6 Fort York Blvd, Toronto, ON, M5V 3Z2</div>
            </div>

            <div className="bank-info-row ">
              <div className="currency-col">CAD</div>
              <div className="bank-name-col">ScotiaBank</div>
              <div className="institution-number-col">002</div>
              <div className="transit-number-col">45872</div>
              <div className="account-number-col">0061018</div>
              <div className="swift-col">NOSCCATT</div>
              <div className="address-col">41 Harbour Sq, Toronto, ON, M5J 2G4</div>
            </div>

            <div className="bank-info-row ">
              <div className="currency-col">CAD</div>
              <div className="bank-name-col">CIBC</div>
              <div className="institution-number-col">010</div>
              <div className="transit-number-col">01002</div>
              <div className="account-number-col">1062816</div>
              <div className="swift-col">CIBCCATT</div>
              <div className="address-col">378 Queen St. W, Toronto, ON M5V2A2</div>
            </div>

            <div className="bank-info-row ">
              <div className="currency-col">USD</div>
              <div className="bank-name-col">RBC Royal Bank</div>
              <div className="institution-number-col">003</div>
              <div className="transit-number-col">00616</div>
              <div className="account-number-col">4002390</div>
              <div className="swift-col">ROYCCAT2</div>
              <div className="address-col">6 Fort York Blvd, Toronto, ON, M5V3Z2</div>
            </div>
          </div>

          <br />
          <p className="text-small">
            * Please ask the bank teller to fill in the SWIFT MT103 Originator field, and include the invoice number in the transfer memo.
          </p>
        </div>
      </div>
    )
  }
}

PaymentInstructions.propTypes = {
}

export default PaymentInstructions
