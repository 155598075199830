import '!style-loader!css-loader!react-circular-progressbar/dist/styles.css'

import React from "react"
import PropTypes from "prop-types"

import axios from '../../util/network'

import ReactHintFactory from 'react-hint'
const ReactHint = ReactHintFactory(React)

import DocumentEditorForm from '../profile/DocumentEditorForm'

class DocumentsTab extends React.Component {
  constructor(props) {
    super(props)

    this.onDocumentEditorFormUpdate = this.onDocumentEditorFormUpdate.bind(this)
    this.canSubmit = this.canSubmit.bind(this)
    this.onClickSubmit = this.onClickSubmit.bind(this)
    this.renderTooltip = this.renderTooltip.bind(this)

    this.state = {
      user: this.props.user,
    }
  }

  onDocumentEditorFormUpdate(newUser) {
    this.setState({...this.state, user: newUser})
    this.props.onUpdate(newUser)
  }

  canSubmit() {
    return this.state.user.kyc_documents.photo_id && this.state.user.kyc_documents.photo_id.status === 'active' &&
           this.state.user.kyc_documents.proof_of_address && this.state.user.kyc_documents.proof_of_address.status === 'active'
  }

  onClickSubmit() {
    if (!this.canSubmit()) {
      return
    }

    this.props.afterSubmitted(this.state.user)
  }

  renderTooltip(target, content) {
    if (target.dataset.doctype === 'photo_id') {
      return (
        <div className="tooltip-content">
          <div className="tooltip-title">We accept any of the documents listed below as long as they are authentic, valid, and current:</div>
          <ul>
            <li>Photo ID card;</li>
            <li>Passport;</li>
            <li>Driver's license;</li>
            <li>Citizenship certificate;</li>
            <li>Permanent Resident or equivalent card.</li>
          </ul>
          <div>Please contact <a href="mailto:documents@balancenow.ca">documents@balancenow.ca</a> if you are unable to provide any of the above documents.</div>
        </div>
      )
    }

    if (target.dataset.doctype === 'proof_of_address') {
      return (
        <div className="tooltip-content">
          <div className="tooltip-title">We can accept the following documents issued within the last 3 months:</div>
          <ul>
             <li>Utility bill;</li>
             <li>Cell phone bill;</li>
             <li>Bank statement;</li>
             <li>Credit card statement;</li>
             <li>Vehicle registration;</li>
             <li>Insurance document;</li>
             <li>Record of employment;</li>
             <li>Letter from the tax authorities (e.g. CRA).</li>
           </ul>
           <div>Please contact <a href="mailto:documents@balancenow.ca">documents@balancenow.ca</a> if you are unable to provide any of the above documents.</div>
        </div>
      )
    }
  }

  render () {
    return (
      <div className="documents-editor">
        <div className="documents-row title-container">
          <div className="documents-col left">
            <div className="title">Government issued photo ID</div>
            <div className="question-icon-blue" data-documents-editor-tooltip data-doctype="photo_id" />

            <DocumentEditorForm
              user={this.state.user}
              doctype='photo_id'
              backgroundGraphicClass='drivers-license-graphic'
              onUpdate={this.onDocumentEditorFormUpdate} />
          </div>

          <div className="documents-col right">
            <div className="title">Proof of address document</div>
            <div className="question-icon-blue" data-documents-editor-tooltip data-doctype="proof_of_address" />

            <DocumentEditorForm
              user={this.state.user}
              doctype='proof_of_address'
              backgroundGraphicClass='hydro-bill-graphic'
              onUpdate={this.onDocumentEditorFormUpdate} />
          </div>
        </div>

        <div className="requirements-text">Please upload a PDF, JPG, or PNG file less than 10MB in size.</div>

        <button className={"pv-btn submit-btn " + (this.canSubmit() ? '' : 'disabled')} onClick={this.onClickSubmit} tabIndex="0">Complete Verification</button>

        <ReactHint position="bottom" events className="tooltip" attribute="data-documents-editor-tooltip" onRenderContent={this.renderTooltip} delay={100} persist={true} />
      </div>
    )
  }
}

DocumentsTab.propTypes = {
  user: PropTypes.object,
  onUpdate: PropTypes.func,
  afterSubmitted: PropTypes.func,
}

export default DocumentsTab
