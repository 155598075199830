// e.g. getTextDimensions("hello there!", "bold 12pt arial"))
const getTextWidth = (text, font) => {
    // re-use canvas object for better performance
    var canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"))
    var context = canvas.getContext("2d")
    context.font = font
    var metrics = context.measureText(text)
    return metrics.width
}

module.exports = {
  getTextWidth,
}
