import React from "react"
import PropTypes from "prop-types"

import axios from '../../util/network'
import Modal from 'react-modal'

import DacDetails from "./DacDetails"
import DacSummary from "./DacSummary"
import TopUpTracker from "./TopUpTracker"
import DacBuilder from "../builder/DacBuilder"
import PurchaseOrderTracker from "../purchase_order/PurchaseOrderTracker"
import PaymentInstructions from "../purchase_order/PaymentInstructions"
import KycWorkflow from "../kyc/KycWorkflow"

import * as privacyUtils from "../../util/privacy"
import * as purchaseStatus from '../../util/builder/purchase_status'
import * as purchaseKind from '../../util/builder/purchase_kind'
import * as purchaseOrderHelpers from '../../util/builder/purchase_order'
import * as kycStage from '../../util/kyc/kyc_stage'
import * as kycStatus from '../../util/kyc/kyc_status'

Modal.setAppElement('.body-container')

class DashboardPage extends React.Component {
  constructor(props) {
    super(props)

    this.lastSavedPurchaseOrder = this.props.purchaseOrder

    const isTopUpViewSelected = this.props.purchaseOrder && this.props.purchaseOrder.kind === purchaseKind.TOP_UP &&
        [purchaseStatus.NOT_STARTED, purchaseStatus.EDITING].includes(this.props.purchaseOrder.status)

    this.state = {
      dac: this.props.dac,
      purchaseOrder: this.props.purchaseOrder,
      user: this.props.user,
      isKycModalOpen: this.props.user.force_kyc_workflow,
      isPaymentInstructionsModalOpen: false,
      privacyEnabled: privacyUtils.getSessionValue(),
      isTopUpViewSelected: isTopUpViewSelected,
      avoidQuestionnaire: true,
    }
  }

  getDacRepresentationForSummaryView = () => {
    if (this.state.isTopUpViewSelected || this.state.dac.status === 'empty') {
      return purchaseOrderHelpers.purchaseOrderAsDac(this.state.purchaseOrder, this.props.assetSummary)
    }

    return this.state.dac
  }

  hasTopUpOrder = () => {
    return this.state.purchaseOrder && this.state.purchaseOrder.kind === purchaseKind.TOP_UP
  }

  savePurchaseOrderAsync = (purchaseOrder) => {
    axios.put('/purchase_orders/'+purchaseOrder.id, purchaseOrder)
      .catch((err) => {
        console.log(err)
      })
  }

  saveKycVerificationAsync = (kyc_verification) => {
    axios.put('/kyc_verifications/'+kyc_verification.id, kyc_verification)
      .catch((err) => {
        console.log(err)
      })
  }

  requestTopUp = () => {
    return axios.post('/purchase_orders')
      .catch((err) => {
        console.log(err)
      })
  }

  cancelTopUp = (purchaseOrder) => {
    return axios.delete("/purchase_orders/"+purchaseOrder.id)
      .catch((err) => {
        console.log(err)
      })
  }

  updatePurchaseOrderPrices = (purchaseOrder) => {
    purchaseOrder.items.forEach((item, i) => {
      item.cad_price = this.props.assetSummary[item.currency].cad_price
    })
  }

  isKycWorkflowCompleted = (user) => {
    return kycStage.isProfileCompleted(user) && kycStage.isAddressCompleted(user) && kycStage.isDocumentsCompleted(user)
  }

  onDacBuilderUpdate = (newPurchaseOrder) => {
    this.setState({
      ...this.state,
      purchaseOrder: newPurchaseOrder,
    })
  }

  onDacBuilderSave = (newPurchaseOrder) => {
    if (this.hasTopUpOrder()) {
      return this.onPurchaseOrderBuy()
    }

    newPurchaseOrder.status = purchaseStatus.SAVED
    if (purchaseOrderHelpers.haveProportionsChanged(this.lastSavedPurchaseOrder, newPurchaseOrder)) {
      newPurchaseOrder.last_saved_at = new Date().toString()
      this.updatePurchaseOrderPrices(newPurchaseOrder)
    }

    this.setState({
      ...this.state,
      purchaseOrder: newPurchaseOrder,
      isTopUpViewSelected: this.hasTopUpOrder(),
    })

    this.savePurchaseOrderAsync(newPurchaseOrder)
    this.lastSavedPurchaseOrder = newPurchaseOrder
  }

  onDacDetailsEdit = () => {
    const newPurchaseOrder = {
      ...this.state.purchaseOrder,
      status: purchaseStatus.EDITING,
    }
    this.setState({
      ...this.state,
      purchaseOrder: newPurchaseOrder,
      isTopUpViewSelected: this.hasTopUpOrder(),
    })

    this.savePurchaseOrderAsync(newPurchaseOrder)
  }

  onPurchaseOrderBuy = () => {
    const newPurchaseOrder = this.state.purchaseOrder

    if (purchaseOrderHelpers.haveProportionsChanged(this.lastSavedPurchaseOrder, newPurchaseOrder)) {
      newPurchaseOrder.last_saved_at = new Date().toString()
      this.updatePurchaseOrderPrices(newPurchaseOrder)
    }

    newPurchaseOrder.status = purchaseStatus.ORDER_PLACED

    this.setState({
      ...this.state,
      purchaseOrder: newPurchaseOrder,
      isKycModalOpen: !(newPurchaseOrder.questionnaire.is_completed && this.isKycWorkflowCompleted(this.state.user)),
      isTopUpViewSelected: this.hasTopUpOrder(),
      avoidQuestionnaire: false,
    })

    this.savePurchaseOrderAsync(newPurchaseOrder)
    this.lastSavedPurchaseOrder = newPurchaseOrder
  }

  onPurchaseOrderTrackerCancel = () => {
    if (this.hasTopUpOrder()) {
      return this.onDeleteTopUp()
    }

    const newPurchaseOrder = this.state.purchaseOrder
    newPurchaseOrder.status = purchaseStatus.SAVED
    this.savePurchaseOrderAsync(newPurchaseOrder)

    const newState = {
      ...this.state,
      purchaseOrder: newPurchaseOrder,
      isTopUpViewSelected: this.hasTopUpOrder(),
    }

    if (this.state.user.kyc_verification.status !== kycStatus.VERIFIED) {
      const newUser = this.state.user
      newUser.kyc_verification.status = kycStatus.IN_PROGRESS
      this.saveKycVerificationAsync(newUser.kyc_verification)
      newState.user = newUser
    }

    this.setState(newState)
  }

  onPurchaseOrderTrackerClickVerifyId = () => {
    this.setState({
      ...this.state,
      isKycModalOpen: true,
      avoidQuestionnaire: false,
    })
  }

  onPurchaseOrderTrackerClickPayInvoice = () => {
    this.setState({
      ...this.state,
      isPaymentInstructionsModalOpen: true,
    })
  }

  onClickKycModalClose = () => {
    this.setState({
      ...this.state,
      isKycModalOpen: false,
    })
  }

  onClickPaymentInstructionsModalClose = () => {
    this.setState({
      ...this.state,
      isPaymentInstructionsModalOpen: false,
    })
  }

  onClickTopUpTrackerCreateTopUp = () => {
    this.requestTopUp()
    .then((response) => {
      const newPurchaseOrder = response.data
      this.setState({
        ...this.state,
        purchaseOrder: newPurchaseOrder,
        isTopUpViewSelected: true,
      })
    })
  }

  onDeleteTopUp = () => {
    this.cancelTopUp(this.state.purchaseOrder)

    this.setState({
      ...this.state,
      purchaseOrder: undefined,
      isTopUpViewSelected: false,
    })
  }

  onKycWorkflowUpdate = (user, purchaseOrder) => {
    this.setState({
      ...this.state,
      user: user,
      purchaseOrder: purchaseOrder,
    })
  }

  onKycWorkflowComplete = (user, purchaseOrder) => {
    if (user.kyc_verification.status !== kycStatus.VERIFIED) {
      user.kyc_verification.status = kycStatus.SUBMITTED
      this.saveKycVerificationAsync(user.kyc_verification)
    }

    this.setState({
      ...this.state,
      user: user,
      purchaseOrder: purchaseOrder,
      isKycModalOpen: false,
    })
  }

  onSwitchToDacView = () => {
    this.setState({
      ...this.state,
      isTopUpViewSelected: false,
    })
  }

  onSwitchToTopUpView = () => {
    this.setState({
      ...this.state,
      isTopUpViewSelected: true,
    })
  }

  togglePrivacy = () => {
    const isPrivacyEnabled = !this.state.privacyEnabled
    this.setState({
      ...this.state,
      privacyEnabled: isPrivacyEnabled,
    })

    privacyUtils.setSessionValue(isPrivacyEnabled)
  }

  render () {
    return (
      <div className="dashboard-page">
        <privacyUtils.PrivacyContext.Provider value={this.state.privacyEnabled}>
          <div className="left-col">
            <div className="dac-details-component-container dac-component-container">
              {purchaseStatus.isOrderBeingEdited(this.state.purchaseOrder) ? (
                <DacBuilder
                  purchaseOrder={this.state.purchaseOrder}
                  assetSummary={this.props.assetSummary}
                  user={this.state.user}
                  onUpdate={this.onDacBuilderUpdate}
                  onSave={this.onDacBuilderSave}
                  onDelete={this.onDeleteTopUp} />
              ) : (
                <DacDetails
                  dac={this.state.dac}
                  purchaseOrder={this.state.purchaseOrder}
                  assetSummary={this.props.assetSummary}
                  user={this.state.user}
                  onEdit={this.onDacDetailsEdit}
                  isTopUpViewSelected={this.state.isTopUpViewSelected}
                  onSwitchToDacView={this.onSwitchToDacView}
                  onSwitchToTopUpView={this.onSwitchToTopUpView} />
              )}
            </div>
          </div>
          <div className="right-col">
            <div className="dac-summary-component-container dac-component-container top">
              <DacSummary
                dac={this.getDacRepresentationForSummaryView()}
                purchaseOrder={this.state.purchaseOrder}
                assetSummary={this.props.assetSummary}
                user={this.state.user}
                onTogglePrivacy={this.togglePrivacy}
                isTopUpMode={this.state.isTopUpViewSelected} />
            </div>

            {this.props.user.type != "settlement_client" && (
              <div className="dac-info-compomnent-container dac-component-container bottom">
                {((this.state.dac.status === 'active' && !purchaseStatus.isOrderSubmitted(this.state.purchaseOrder)) ||
                  (this.hasTopUpOrder() && !this.state.isTopUpViewSelected)) ? (
                  <TopUpTracker
                    dac={this.state.dac}
                    purchaseOrder={this.state.purchaseOrder}
                    user={this.state.user}
                    onClickTopUp={this.onClickTopUpTrackerCreateTopUp}
                    onClickBuy={this.onPurchaseOrderBuy}
                    isTopUpMode={this.state.isTopUpViewSelected} />
                ) : (
                  <PurchaseOrderTracker
                    user={this.state.user}
                    purchaseOrder={this.state.purchaseOrder}
                    onBuy={this.onPurchaseOrderBuy}
                    onCancel={this.onPurchaseOrderTrackerCancel}
                    onClickVerifyId={this.onPurchaseOrderTrackerClickVerifyId}
                    onClickPayInvoice={this.onPurchaseOrderTrackerClickPayInvoice} />
                )}
              </div>
            )}
          </div>

          <Modal
              className="pv-modal"
              overlayClassName="pv-modal-overlay"
              isOpen={this.state.isKycModalOpen}
              onRequestClose={()=>{this.setState({...this.state, isKycModalOpen: false})}}
              closeTimeoutMS={300}
              shouldCloseOnOverlayClick={true}>

            <KycWorkflow
              purchaseOrder={this.state.purchaseOrder}
              user={this.state.user}
              avoidQuestionnaire={this.state.avoidQuestionnaire}
              onUpdate={this.onKycWorkflowUpdate}
              onComplete={this.onKycWorkflowComplete} />

            <div className="close-btn" onClick={this.onClickKycModalClose}/>
          </Modal>

          <Modal
              className="pv-modal"
              overlayClassName="pv-modal-overlay"
              isOpen={this.state.isPaymentInstructionsModalOpen}
              onRequestClose={()=>{this.setState({...this.state, isPaymentInstructionsModalOpen: false})}}
              closeTimeoutMS={300}
              shouldCloseOnOverlayClick={true}>

            <PaymentInstructions />
            <div className="close-btn" onClick={this.onClickPaymentInstructionsModalClose}/>
          </Modal>
        </privacyUtils.PrivacyContext.Provider>
      </div>
    )
  }
}

DashboardPage.propTypes = {
  dac: PropTypes.object,
  purchaseOrder: PropTypes.object,
  assetSummary: PropTypes.object,
  user: PropTypes.object,
}

export default DashboardPage
