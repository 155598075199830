import React from "react"
import PropTypes from "prop-types"

import redeemStage from "../../util/redeem/redeem_stage"

class RedeemProgressBar extends React.Component {
  constructor(props) {
    super(props)
  }

  onClickPickAmounts = () => {
    if (this.props.currentStage > redeemStage.PICK_AMOUNTS) {
      this.props.onUpdateCurrentStage(redeemStage.PICK_AMOUNTS)
    }
  }

  onClickPickMethod = () => {
    if (this.props.currentStage > redeemStage.PICK_METHOD) {
      this.props.onUpdateCurrentStage(redeemStage.PICK_METHOD)
    }
  }

  onClickEnterDetails = () => {
    if (this.props.currentStage > redeemStage.ENTER_DETAILS) {
      this.props.onUpdateCurrentStage(redeemStage.ENTER_DETAILS)
    }
  }

  render = () => {
    var pickAmountsClass = "progress-col"
    var pickMethodClass = "progress-col"
    var enterDetailsClass = "progress-col"
    var confirmClass = "progress-col"
    var firstSeparatorClass = "separator first"
    var secondSeparatorClass = "separator second"

    if (this.props.currentStage === redeemStage.PICK_AMOUNTS) {
      pickAmountsClass += " active"
    } else if (this.props.currentStage === redeemStage.PICK_METHOD) {
      pickAmountsClass += " clickable completed"
      pickMethodClass += " active"
      firstSeparatorClass += " in-progress"
    } else if (this.props.currentStage === redeemStage.ENTER_DETAILS) {
      pickAmountsClass += " clickable completed"
      pickMethodClass += " clickable completed"
      enterDetailsClass += " active"
      firstSeparatorClass += " completed"
      secondSeparatorClass += " in-progress"
    } else if (this.props.currentStage === redeemStage.CONFIRM) {
      pickAmountsClass += " clickable completed"
      pickMethodClass += " clickable completed"
      enterDetailsClass += " clickable completed"
      confirmClass += " active"
      firstSeparatorClass += " completed"
      secondSeparatorClass += " completed"
    }

    return (
      <div className="redeem-progress-bar-container">
        <div className="title">
          Withdraw
        </div>
        <div className="redeem-progress-bar">
          <div className={pickAmountsClass} onClick={this.onClickPickAmounts}>
            <div className="circle" />
            <div className="label">Select</div>
          </div>

          <div className={firstSeparatorClass} />

          <div className={pickMethodClass} onClick={this.onClickPickMethod}>
            <div className="circle" />
            <div className="label">Direct</div>
          </div>

          <div className={secondSeparatorClass} />

          <div className={enterDetailsClass} onClick={this.onClickEnterDetails}>
            <div className="circle" />
            <div className="label">Confirm</div>
          </div>
        </div>
      </div>
    )
  }
}

RedeemProgressBar.propTypes = {
  user: PropTypes.object,
  redeemRequest: PropTypes.object,
  currentStage: PropTypes.number,
  onUpdateCurrentStage: PropTypes.func,
  isSettlementClient: PropTypes.bool,
}

RedeemProgressBar.defaultProps = {
  isSettlementClient: false,
}

export default RedeemProgressBar
