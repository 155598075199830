import React from "react"
import PropTypes from "prop-types"

import Modal from 'react-modal'

import RedeemWorkflow from '../redeem/RedeemWorkflow'
import * as userHelpers from "../../util/users"

Modal.setAppElement('.body-container')

class RedeemTrigger extends React.Component {
  constructor(props) {
    super(props)

    this.onClickRedeem = this.onClickRedeem.bind(this)
    this.afterRedeemWorkflowSubmitted = this.afterRedeemWorkflowSubmitted.bind(this)

    this.state = {
      isRedeemModalOpen: false,
      isRedeemRequestSubmitted: false,
      redeemRequest: this.blankRedeemRequest(),
      isSettlementClient: this.props.user.type === userHelpers.USER_TYPE_SETTLEMENT_CLIENT,
    }
  }

  blankRedeemRequest = () => {
    return {
      amounts: {},
    }
  }

  onClickRedeem() {
    if (this.props.redeemRequest || this.state.isRedeemRequestSubmitted) {
      return
    }

    this.setState({
      ...this.state,
      isRedeemModalOpen: true,
    })
  }

  afterRedeemWorkflowSubmitted() {
    this.setState({
      ...this.state,
      isRedeemRequestSubmitted: true,
      isRedeemModalOpen: false,
    })
  }

  render () {
    return (
      <React.Fragment>
        <div className="redeem-trigger-container">
          <button className={"pv-link redeem-btn " + (this.props.redeemRequest || this.state.isRedeemRequestSubmitted ? 'disabled' : '')} onClick={this.onClickRedeem}>
            Withdraw
          </button>
          {(this.props.redeemRequest || this.state.isRedeemRequestSubmitted) && (
            <div className="redeem-confirmation-text">
              Your withdrawal request has been submitted. Your account manager will be in touch within 24h.
            </div>
          )}
        </div>

        <Modal
          className="pv-modal"
          overlayClassName="pv-modal-overlay"
          isOpen={this.state.isRedeemModalOpen}
          onRequestClose={()=>{this.setState({...this.state, isRedeemModalOpen: false})}}
          closeTimeoutMS={300}
          shouldCloseOnOverlayClick={true}>

          <RedeemWorkflow
            user={this.props.user}
            redeemRequest={this.state.redeemRequest}
            dac={this.props.dac}
            afterSubmitted={this.afterRedeemWorkflowSubmitted}
            fiatRates={this.props.fiatRates}
          />

          <div className="close-btn" onClick={()=>{this.setState({...this.state, isRedeemModalOpen: false})}} />
        </Modal>
      </React.Fragment>
    )
  }
}

RedeemTrigger.propTypes = {
  user: PropTypes.object,
  redeemRequest: PropTypes.object,
  dac: PropTypes.object,
  fiatRates: PropTypes.object,
}

export default RedeemTrigger
