import React from "react"
import PropTypes from "prop-types"

import Switch from "react-switch"
import axios from '../../util/network'

class EmailOptinForm extends React.Component {
  constructor(props) {
    super(props)

    this.updateEmailOptin = this.updateEmailOptin.bind(this)
    this.onChangeEmailOptin = this.onChangeEmailOptin.bind(this)

    this.state = {
      email_optin: this.props.user.email_optin,
    }
  }

  updateEmailOptin(value) {
    return axios.put('/update_profile', {email_optin: value})
      .catch((err) => {
        console.log(err)
      })
  }

  onChangeEmailOptin(value) {
    this.updateEmailOptin(value)
    .then(() => {
      this.setState({
        ...this.state,
        email_optin: value,
      })
      this.props.afterSubmitted(value)
    })
  }

  render () {
    return (
      <div className="email-optin-form">
        <Switch
          checked={this.state.email_optin}
          onChange={this.onChangeEmailOptin}
          checkedIcon={<div className="toggle-label yes">Yes</div>}
          uncheckedIcon={<div className="toggle-label no">No</div>}
          className="toggle-btn"
          height={20}
          onColor="#2369AD"
          offColor="#778F9B" />

        <div className="description">I want to receive the monthly newsletter.</div>
      </div>
    )
  }
}

EmailOptinForm.propTypes = {
  user: PropTypes.object,
  afterSubmitted: PropTypes.func,
}

export default EmailOptinForm
