const standardDeviation = (values) => {
  var avg = average(values);

  var squareDiffs = values.map((value) => {
    var diff = value - avg;
    var sqrDiff = diff * diff;
    return sqrDiff;
  })

  var avgSquareDiff = average(squareDiffs);

  var stdDev = Math.sqrt(avgSquareDiff);
  return stdDev;
}

const average = (data) => {
  var sum = data.reduce((sum, value) => {
    return sum + value;
  }, 0);

  var avg = sum / data.length;
  return avg;
}

const sharpeRatio = (prices) => {
  if (!prices) { return 0 }

  // get periodic % change
  const pctChanges = []

  for (i = 1; i < prices.length; i++) {
    let prevPrice = prices[i-1]
    let currentPrice = prices[i]
    pctChanges.push(100 * (currentPrice - prevPrice) / prevPrice)
  }

  let avg = average(pctChanges)
  let stdDev = standardDeviation(pctChanges)
  if (!stdDev) {
    return 0
  }

  return Math.sqrt(pctChanges.length) * avg / stdDev
}

const marketCoverageDescription = "Percentage of the total digital currency market covered by your portfolio. A higher market coverage implies a broader exposure to the entirety of the digital currency market."
const riskAdjustedReturnDescription = "Quantifies the return per each unit of volatility. Higher is better. Computed by dividing the return by the standard deviation for the past 365 days."

module.exports = {
  standardDeviation,
  average,
  sharpeRatio,
  marketCoverageDescription,
  riskAdjustedReturnDescription,
}
