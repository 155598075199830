import React from "react"
import PropTypes from "prop-types"

import { timeFormat } from "d3-time-format"
import { CSSTransitionGroup } from 'react-transition-group'
import ReactHintFactory from 'react-hint'
const ReactHint = ReactHintFactory(React)

import AreaChart from '../graph/AreaChart'

import * as format from '../../util/formatting'
import * as stats from '../../util/stats'
import * as color from '../../util/color'
import * as privacyUtils from "../../util/privacy"
import * as userHelpers from "../../util/users"

class AssetDetails extends React.Component {
  constructor(props) {
    super(props)

    this.graphContainer = React.createRef()

    this.state = {
      graphReadyForDisplay: false,
      graphWidth: 0,
      graphHeight: 0,
      graphData: [],
      period: 'week',
      isSettlementClient: this.props.user.type === userHelpers.USER_TYPE_SETTLEMENT_CLIENT,
    }
  }

  getGraphData = (period) => {
    // Data for the weekly chart has a 2-hour resolution.
    // Data for the monthly chart has a 1-day resolution.
    // Data for the yearly chart has a 1-day resolution.
    // Data comes in the following format: {currency: [cad_price(1 week ago), cad_price(...+2 hours), cad_price(...+2 hours)...]}

    let pricesAccessor = () => {
      return this.props.assetSummary['prices_'+period]
    }

    let resolution = {
      week: 1000 * 60 * 60 * 2, // 2 hours
      month: 1000 * 60 * 60 * 24, // 1 day
      year: 1000 * 60 * 60 * 24, // 1 day
    }[period]

    let num_data_points = pricesAccessor().length
    let range_end_date = new Date() // now

    let data_period = []
    for (let i = 0; i < num_data_points; ++i) {
      let price = pricesAccessor()[i]
      if (!price) {
        price = pricesAccessor()[i-1]
        if (!price) {
          next
        }
      }
      let cad_value = price // we always show just the price in this graph

      data_period.push({
        date: new Date(range_end_date.getTime() - resolution * (num_data_points - i - 1)),
        cad_value: cad_value,
      })
    }

    return data_period
  }

  getXAxisFormatter = (period) => {
    if (period === 'week') {
      return timeFormat("%a")
    }
    if (period === 'month') {
      return timeFormat("%b %e")
    }
    if (period === 'year') {
      return timeFormat("%b")
    }
  }

  getSharpeRatio = () => {
    return stats.sharpeRatio(this.props.assetSummary.prices_year)
  }

  getPeriodDiff = () => {
    const graphData = this.getGraphData(this.state.period)
    if (!graphData || !graphData.length) {
      return 0
    }

    const firstVal = graphData[0].cad_value
    const lastVal = graphData[graphData.length-1].cad_value

    return ((lastVal / firstVal) - 1) * 100
  }

  updateDimensions = () => {
    this.setState({
      ...this.state,
      graphReadyForDisplay: true,
      graphWidth: this.graphContainer.current.offsetWidth,
      graphHeight: 170,
    })
  }

  setPeriod = (period) => {
    this.setState({
      ...this.state,
      period: period,
    })
  }

  handleKeypress = (event) => {
    switch(event.keyCode) {
      case 27: // escape
        event.preventDefault()
        this.props.onClose()
        break;
      case 37: // left arrow
        event.preventDefault()
        this.props.onPrevious()
        break;
      case 39: // right arrow
        event.preventDefault()
        this.props.onNext()
        break;
      default: return;
    }
  }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions)
    window.addEventListener("keydown", this.handleKeypress)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions)
    window.removeEventListener("keydown", this.handleKeypress)
  }

  render () {
    const mainContent = (
      <React.Fragment>
        <div className="section-title-container" style={{backgroundColor: this.props.primaryColor}}>
          <div className="currency">{this.props.currency}</div>
          <div className="proportion">
            <span className="percentage">{format.percentage(this.props.proportion, 2)}</span>
            <span className="description">
              {this.state.isSettlementClient ? "of your total assets" : "of DAC"}
            </span>
          </div>
          <div className="close-btn" onClick={() => {this.props.onClose()}}></div>
        </div>

        <div className="details-container">
          <div className="header">{this.props.assetSummary.full_name}</div>
          <div className="portfolio-summary">
            <div className="summary-col left">
              <span className="bold">{privacyUtils.formatText(format.variableDecimalDigits(this.props.data.balance, 0, 6))}</span>
              &nbsp;
              <span className="light uppercase">{privacyUtils.formatText(this.props.currency, "")}</span>
            </div>
            <div className="summary-col">
              <span className="bold">{privacyUtils.formatMoney(this.props.data.cad_value, 2)}</span>
            </div>
          </div>

          <div className="asset-price-chart-container">
            <div className="graph-container" ref={this.graphContainer}>
              {this.state.graphReadyForDisplay && (
                <AreaChart
                  width={this.state.graphWidth}
                  height={this.state.graphHeight}
                  data={this.getGraphData(this.state.period)}
                  fillColor={color.hexToRGBA(this.props.primaryColor, 0.3)}
                  lineColor={this.props.primaryColor}
                  cursorColor={this.props.primaryColor}
                  xAxisFormatter={this.getXAxisFormatter(this.state.period)} />)}
            </div>
            <div className="period-selector">
              <div className={"period " + (this.state.period === 'week' ? 'active' : '')} onClick={e => this.setPeriod('week')}>
                <span className="period-label">1W</span>
                {this.state.period === 'week' && (
                  <span className={"period-diff " + (this.getPeriodDiff() > 0 ? 'positive' : 'negative')}>{format.signedPercentage(this.getPeriodDiff(), 2)}</span>
                )}
              </div>
              <div className={"period " + (this.state.period === 'month' ? 'active' : '')} onClick={e => this.setPeriod('month')}>
                <span className="period-label">1M</span>
                {this.state.period === 'month' && (
                  <span className={"period-diff " + (this.getPeriodDiff() > 0 ? 'positive' : 'negative')}>{format.signedPercentage(this.getPeriodDiff(), 2)}</span>
                )}
              </div>
              <div className={"period " + (this.state.period === 'year' ? 'active' : '')} onClick={e => this.setPeriod('year')}>
                <span className="period-label">1Y</span>
                {this.state.period === 'year' && (
                  <span className={"period-diff " + (this.getPeriodDiff() >= 0 ? 'positive' : 'negative')}>{format.signedPercentage(this.getPeriodDiff(), 2)}</span>
                )}
              </div>
            </div>
          </div>

          <div className="market-info">
            <div className="info-col">
              <div className="light">Market Coverage</div>
              <div className="bold">{format.percentage(this.props.assetSummary.market_coverage_pct, 1)}</div>
              <div className="question-icon" data-asset-details-tooltip={stats.marketCoverageDescription} />
            </div>
            <div className="info-col">
              <div className="light">Risk Adjusted Return</div>
              <div className="bold">{format.variableDecimalDigits(this.getSharpeRatio(this.state.period), 2, 2)}</div>
              <div className="question-icon" data-asset-details-tooltip={stats.riskAdjustedReturnDescription} />
            </div>
          </div>

          <div className="horizontal-divider"></div>

          <div className="description">{this.props.assetSummary.description}</div>
        </div>

        <ReactHint position="right" events className="tooltip" attribute="data-asset-details-tooltip" delay={100} persist={true} />
      </React.Fragment>
    )

    return (
      <CSSTransitionGroup
              transitionName="animated-pane-scale-in"
              transitionAppear={true}
              transitionAppearTimeout={250}
              transitionEnter={false}
              transitionLeave={false} component="div" className="asset-details-container">
        <div className="asset-details">
          {mainContent}
        </div>
      </CSSTransitionGroup>
    )
  }
}

AssetDetails.propTypes = {
  user: PropTypes.object,
  currency: PropTypes.string,
  assetSummary: PropTypes.object,
  primaryColor: PropTypes.string,
  proportion: PropTypes.number,
  data: PropTypes.object,
  onClose: PropTypes.func,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
}

export default AssetDetails
