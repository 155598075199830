import React from "react"
import PropTypes from "prop-types"
import * as purchaseStatus from '../../util/builder/purchase_status'
import * as kycStage from '../../util/kyc/kyc_stage'

class KycProgressBar extends React.Component {
  constructor(props) {
    super(props)
  }

  render () {
    const isProfileCompleted = kycStage.isProfileCompleted(this.props.user)
    const isAddressCompleted = kycStage.isAddressCompleted(this.props.user)
    const isDocumentsCompleted = kycStage.isDocumentsCompleted(this.props.user)

    var profileLinkClass = "profile-container progress-col"
    var addressLinkClass = "address-container progress-col"
    var documentsLinkClass = "documents-container progress-col"

    // styling for labels and circles
    if (isProfileCompleted) {
      profileLinkClass += " clickable completed"
      addressLinkClass += " clickable"
    } else if (this.props.currentStage === kycStage.PROFILE) {
      profileLinkClass += " active"
    }

    if (isAddressCompleted) {
      addressLinkClass += " clickable completed"
      documentsLinkClass += " clickable"
    } else if (this.props.currentStage === kycStage.ADDRESS) {
      addressLinkClass += " active"
    }

    if (isDocumentsCompleted) {
      documentsLinkClass += " clickable completed"
    } else if (this.props.currentStage === kycStage.DOCUMENTS) {
      documentsLinkClass += " active"
    }

    // styling for the horizontal separator line
    var firstSeparatorClass = "separator first"
    var secondSeparatorClass = "separator second"

    if (isProfileCompleted) {
      if (isAddressCompleted) {
        firstSeparatorClass += " completed"
      } else if (this.props.currentStage === kycStage.ADDRESS) {
        firstSeparatorClass += " in-progress"
      }
    }

    if (isAddressCompleted) {
      if (isDocumentsCompleted) {
        secondSeparatorClass += " completed"
      } else if (this.props.currentStage === kycStage.DOCUMENTS) {
        secondSeparatorClass += " in-progress"
      }
    }

    return (
      <div className="kyc-progress-bar">
        <div className={profileLinkClass} onClick={this.props.onClickProfile}>
          <div className="circle" />
          <div className="label">Profile</div>
        </div>

        <div className={firstSeparatorClass} />

        <div className={addressLinkClass} onClick={isProfileCompleted ? this.props.onClickAddress : undefined}>
          <div className="circle" />
          <div className="label">Address</div>
        </div>

        <div className={secondSeparatorClass} />

        <div className={documentsLinkClass} onClick={isAddressCompleted ? this.props.onClickDocuments : undefined}>
          <div className="circle" />
          <div className="label">Documents</div>
        </div>
      </div>
    )
  }
}

KycProgressBar.propTypes = {
  purchaseOrder: PropTypes.object,
  currentStage: PropTypes.number,
  onClickProfile: PropTypes.func,
  onClickAddress: PropTypes.func,
  onClickDocuments: PropTypes.func,
}

export default KycProgressBar
