import React from "react"
import PropTypes from "prop-types"

import axios from '../../util/network'

import ReactHintFactory from 'react-hint'
const ReactHint = ReactHintFactory(React)

import * as geography from '../../util/geography'
import LockableLabel from '../forms/LockableLabel'
import LockableTextInput from '../forms/LockableTextInput'
import LockableDropdown from '../forms/LockableDropdown'

class AddressEditorForm extends React.Component {
  constructor(props) {
    super(props)

    this.valueOrEmptyString = this.valueOrEmptyString.bind(this)
    this.onChangeCountry = this.onChangeCountry.bind(this)
    this.onChangeProvince = this.onChangeProvince.bind(this)
    this.onChangeCity = this.onChangeCity.bind(this)
    this.onChangeStreet = this.onChangeStreet.bind(this)
    this.onChangePostalCode = this.onChangePostalCode.bind(this)
    this.emptyStringValidator = this.emptyStringValidator.bind(this)
    this.getErrorClass = this.getErrorClass.bind(this)

    this.state = {
      address: this.props.address,
      blurredFields: [],
    }
  }

  valueOrEmptyString(value) {
    return !value ? '' : value
  }

  onChangeCountry(e) {
    const newAddress = {
      ...this.state.address,
      country: e.target.value,
    }
    this.setState({
      ...this.state,
      address: newAddress,
    })
    this.props.onUpdate(newAddress)
  }

  onChangeProvince(e) {
    const newAddress = {
      ...this.state.address,
      province: e.target.value,
    }
    this.setState({
      ...this.state,
      address: newAddress,
    })
    this.props.onUpdate(newAddress)
  }

  onChangeCity(e) {
    const newAddress = {
      ...this.state.address,
      city: e.target.value,
    }
    this.setState({
      ...this.state,
      address: newAddress,
    })
    this.props.onUpdate(newAddress)
  }

  onChangeStreet(e) {
    const newAddress = {
      ...this.state.address,
      street: e.target.value,
    }
    this.setState({
      ...this.state,
      address: newAddress,
    })
    this.props.onUpdate(newAddress)
  }

  onChangePostalCode(e) {
    const newAddress = {
      ...this.state.address,
      postal_code: e.target.value,
    }
    this.setState({
      ...this.state,
      address: newAddress,
    })
    this.props.onUpdate(newAddress)
  }

  emptyStringValidator(fieldName) {
    return () => {
      return !this.props.mandatoryFields.includes(fieldName) || this.state.address[fieldName] && this.state.address[fieldName].length > 0
    }
  }

  getErrorClass(fieldName, validator) {
    return (this.state.blurredFields.includes(fieldName) &&
      !validator(fieldName)
    ) ? 'invalid' : ''
  }

  render () {
    return (
      <div className="address-editor-form">
        <div className="address-row">
          <div className="address-col left">
            <LockableLabel className="label" text="Country" isLocked={this.props.lockedFields.includes('country')} tooltipText="You account is verified. Contact us if you'd like to change this information." />
            {(this.props.mandatoryFields.includes('country') && !this.props.lockedFields.includes('country')) && (
              <span className="mandatory-asterisk">*</span>
            )}

            <LockableDropdown
              className="country-input"
              value={this.state.address.country}
              items={geography.countries.reduce((acc, countryName) => {acc[countryName] = countryName; return acc;}, {'': 'Select Country'})}
              onChange={this.onChangeCountry}
              isLocked={this.props.lockedFields.includes('country')}
              validator={this.emptyStringValidator('country')} />
          </div>

          <div className="address-col right">
            <LockableLabel className="label" text="Province / State" isLocked={this.props.lockedFields.includes('province')} tooltipText="You account is verified. Contact us if you'd like to change this information." />
            {(this.props.mandatoryFields.includes('province') && !this.props.lockedFields.includes('province')) && (
              <span className="mandatory-asterisk">*</span>
            )}
            {geography.states_provinces[this.state.address.country] ? (
              <LockableDropdown
                className="country-input"
                value={this.state.address.province}
                items={geography.states_provinces[this.state.address.country].reduce((acc, provinceName) => {acc[provinceName] = provinceName; return acc;}, {'': 'Select Province'})}
                onChange={this.onChangeProvince}
                isLocked={this.props.lockedFields.includes('province')}
                validator={this.emptyStringValidator('province')} />
            ) : (
              <LockableTextInput
                value={this.state.address.province}
                placeholder="E.g. Québec"
                onChange={this.onChangeProvince}
                isLocked={this.props.lockedFields.includes('province')}
                validator={this.emptyStringValidator('province')} />
            )}
          </div>
        </div>

        <div className="address-row">
          <div className="address-col left">
            <LockableLabel className="label" text="City" isLocked={this.props.lockedFields.includes('city')} tooltipText="You account is verified. Contact us if you'd like to change this information." />
            {(this.props.mandatoryFields.includes('city') && !this.props.lockedFields.includes('city')) && (
              <span className="mandatory-asterisk">*</span>
            )}

            <LockableTextInput
              value={this.state.address.city}
              placeholder="E.g. Toronto"
              onChange={this.onChangeCity}
              isLocked={this.props.lockedFields.includes('city')}
              validator={this.emptyStringValidator('city')} />
          </div>
          <div className="address-col right">
            <LockableLabel className="label" text="Street address" isLocked={this.props.lockedFields.includes('street')} tooltipText="You account is verified. Contact us if you'd like to change this information." />
            {(this.props.mandatoryFields.includes('street') && !this.props.lockedFields.includes('street')) && (
              <span className="mandatory-asterisk">*</span>
            )}

            <LockableTextInput
              value={this.state.address.street}
              placeholder="E.g. 1122 Summerhill Drive"
              onChange={this.onChangeStreet}
              isLocked={this.props.lockedFields.includes('street')}
              validator={this.emptyStringValidator('street')} />
          </div>
        </div>

        <div className="address-row">
          <div className="address-col left">
            <LockableLabel className="label" text="Postal / Zip code" isLocked={this.props.lockedFields.includes('postal_code')} tooltipText="You account is verified. Contact us if you'd like to change this information." />
            {(this.props.mandatoryFields.includes('postal_code') && !this.props.lockedFields.includes('postal_code')) && (
              <span className="mandatory-asterisk">*</span>
            )}

            <LockableTextInput
              value={this.state.address.postal_code}
              placeholder="E.g. M5J 0A9"
              onChange={this.onChangePostalCode}
              isLocked={this.props.lockedFields.includes('postal_code')}
              validator={this.emptyStringValidator('postal_code')} />
          </div>
        </div>
      </div>
    )
  }
}

AddressEditorForm.propTypes = {
  address: PropTypes.object,
  onUpdate: PropTypes.func,
  lockedFields: PropTypes.array,
  mandatoryFields: PropTypes.array,
}

AddressEditorForm.defaultProps = {
  lockedFields: [],
  mandatoryFields: [],
}

export default AddressEditorForm
