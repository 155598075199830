import React from "react"
import PropTypes from "prop-types"

import axios from '../../util/network'

import RedeemProgressBar from "./RedeemProgressBar"
import RedeemAmountsPickerTab from "./RedeemAmountsPickerTab"
import RedeemMethodPickerTab from "./RedeemMethodPickerTab"
import RedeemDetailsTab from "./RedeemDetailsTab"
import RedeemConfirmationTab from "./RedeemConfirmationTab"

import redeemStage from "../../util/redeem/redeem_stage"
import redeemKind from "../../util/redeem/redeem_kind"
import * as userHelpers from "../../util/users"

class RedeemWorkflow extends React.Component {
  constructor(props) {
    super(props)

    var isSettlementClient = this.props.user.type === userHelpers.USER_TYPE_SETTLEMENT_CLIENT
    this.state = {
      isSettlementClient: isSettlementClient,
      isLoading: true,
      user: this.props.user,
      redeemRequest: this.props.redeemRequest,
      currentStage: redeemStage.PICK_AMOUNTS,
      availableFunds: {},
      error: null,
    }
  }

  componentDidMount() {
    axios.get("/redeem_requests/available_amounts.json")
      .then((resp) => {
        var redeemRequest = this.state.redeemRequest

        this.setState({
          ...this.state,
          redeemRequest: redeemRequest,
          availableFunds: resp.data.amounts,
          isLoading: false,
        })
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          error: "Failed to fetch available funds. Please try again later.",
        })
      })
  }

  createRedeemRequest = (redeemRequest) => {
    var details = undefined

    if (redeemRequest.kind === redeemKind.FIAT) {
      details = redeemRequest.fiatDetails
    } else if (redeemRequest.kind === redeemKind.CRYPTO) {
      details = redeemRequest.cryptoDetails
    } else if (redeemRequest.kind === redeemKind.MAIL) {
      details = redeemRequest.mailDetails
    }

    details.amounts = redeemRequest.amounts

    const requestData = {
      kind: redeemRequest.kind,
      details: details,
    }

    return axios.post('/redeem_requests', requestData)
      .catch((err) => {
        console.log(err)
      })
  }

  onUpdateCurrentStage = (newStage) => {
    this.setState({
      ...this.state,
      currentStage: newStage,
    })
  }

  onAmountsPickerAmountsUpdated = (amounts) => {
    this.setState({
      ...this.state,
      redeemRequest: {
        ...this.state.redeemRequest,
        amounts: amounts,
      },
    })
  }
  onAmountsPickerSubmit = () => {
    this.setState({
      ...this.state,
      currentStage: redeemStage.PICK_METHOD,
    })
  }

  onMethodPickerSubmit = (kind) => {
    const newRedeemRequest = {
      ...this.state.redeemRequest,
      kind: kind,
    }

    this.setState({
      ...this.state,
      redeemRequest: newRedeemRequest,
      currentStage: redeemStage.ENTER_DETAILS,
    })
  }

  onRedeemDetailsUpdate = (newRedeemRequest) => {
    this.setState({
      ...this.state,
      redeemRequest: newRedeemRequest,
    })
  }

  onRedeemDetailsSubmit = () => {
    this.createRedeemRequest(this.state.redeemRequest)
    this.props.afterSubmitted()
  }

  renderLoadingScreen = () => {
    return (
      <div className="redeem-loading-screen">
        {this.state.error ? (
          <div className="error-message">{this.state.error}</div>
        ) : (
          <React.Fragment>
            <div className="spinner" />
            <div className="loading-text">Loading...</div>
          </React.Fragment>
        )}
      </div>
    )
  }

  renderRedeemWorkflow = () => {
    return (
      <div className="redeem-workflow-container">
        <div className="title-bar">
          <RedeemProgressBar
            user={this.state.user}
            redeemRequest={this.state.redeemRequest}
            currentStage={this.state.currentStage}
            onUpdateCurrentStage={this.onUpdateCurrentStage}
            isSettlementClient={this.state.isSettlementClient}
          />
        </div>

        <div className="divider" />

        {this.state.currentStage === redeemStage.PICK_AMOUNTS && (
          <RedeemAmountsPickerTab
            user={this.state.user}
            dac={this.props.dac}
            availableFunds={this.state.availableFunds}
            redeemRequest={this.state.redeemRequest}
            fiatRates={this.props.fiatRates}
            onAmountsUpdated={this.onAmountsPickerAmountsUpdated}
            onSubmit={this.onAmountsPickerSubmit}
          />
        )}

        {this.state.currentStage === redeemStage.PICK_METHOD && (
          <RedeemMethodPickerTab
            user={this.state.user}
            redeemRequest={this.state.redeemRequest}
            onSubmit={this.onMethodPickerSubmit}
            isSettlementClient={this.state.isSettlementClient}
          />
        )}

        {this.state.currentStage === redeemStage.ENTER_DETAILS && (
          <RedeemDetailsTab
            user={this.state.user}
            redeemRequest={this.state.redeemRequest}
            dac={this.props.dac}
            onUpdate={this.onRedeemDetailsUpdate}
            onSubmit={this.onRedeemDetailsSubmit}
          />
        )}
      </div>
    )
  }

  render = () => {
    if (this.state.isLoading) {
      return this.renderLoadingScreen()
    }
    return this.renderRedeemWorkflow()
  }
}

RedeemWorkflow.propTypes = {
  user: PropTypes.object,
  redeemRequest: PropTypes.object,
  dac: PropTypes.object,
  afterSubmitted: PropTypes.func,
  fiatRates: PropTypes.object,
}

export default RedeemWorkflow
