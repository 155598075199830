import React from "react"
import PropTypes from "prop-types"

import ReactHintFactory from 'react-hint'
const ReactHint = ReactHintFactory(React)

class LockableDropdown extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      allowValidation: false,
    }
  }

  valueOrEmptyString = (value) => {
    return !value ? '' : value
  }

  onChange = (e) => {
    this.setState({...this.state, allowValidation: true})
    if (this.props.onChange) {
      this.props.onChange(e)
    }
  }

  onBlur = (e) => {
    this.setState({...this.state, allowValidation: true})
    if (this.props.onBlur) {
      this.props.onBlur(e)
    }
  }

  render () {
    var classNames = [this.props.className, "input", "dropdown-input"]
    if (this.props.isLocked) {
      classNames.push('disabled')
    }

    if (!this.props.validator()) {
      if (this.state.allowValidation || !this.props.validateOnBlurOnly) {
        classNames.push('invalid')
      }
    }

    return (
      <select
        className={classNames.join(' ')}
        value={this.valueOrEmptyString(this.props.value)}
        onChange={this.onChange}
        onBlur={this.onBlur}
        disabled={this.props.isLocked}>

        {Object.keys(this.props.items).map((value, i) => {
          return (
            <option key={i} value={value}>{this.props.items[value]}</option>
          )
        })}
      </select>
    )
  }
}

LockableDropdown.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any,
  items: PropTypes.object,

  onChange: PropTypes.func,
  onBlur: PropTypes.func,

  isLocked: PropTypes.bool,
  validator: PropTypes.func,

  validateOnBlurOnly: PropTypes.bool,
}

const defaultValidator = () => { return true }

LockableDropdown.defaultProps = {
  className: "",
  isLocked: false,
  validator: defaultValidator,
  validateOnBlurOnly: true,
}

export default LockableDropdown
