import React from "react"
import PropTypes from "prop-types"

import CurrencyLabel from "../common/CurrencyLabel"

import LockableTextInput from '../forms/LockableTextInput'
import * as currencyHelpers from '../../util/currency'

class RedeemCryptoForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      redeemRequest: this.props.redeemRequest,
    }

    if (!this.state.redeemRequest.cryptoDetails) {
      this.state.redeemRequest.cryptoDetails = {}
      currencyHelpers.getSupportedCurrencies().forEach((currency, i) => {
        this.state.redeemRequest.cryptoDetails[this.getFieldNameForCurrency(currency)] = ''
      })
    }
  }

  getFieldNameForCurrency = (currency) => {
    return currency + "_public_address"
  }

  getFieldNameForXlmMemo = () => {
    return "xlm_memo"
  }

  getFieldNameForXrpTag = () => {
    return "xrp_tag"
  }

  publicAddressValidator = (currency) => {
    return () => {
      var addr = this.state.redeemRequest.cryptoDetails[this.getFieldNameForCurrency(currency)]
      return currencyHelpers.isValidPublicAddress(currency, addr)
    }
  }

  xlmMemoValidator = () => {
    var memo = this.state.redeemRequest.cryptoDetails[this.getFieldNameForXlmMemo()]
    return !memo || memo.length == 0 || currencyHelpers.isValidXlmMemo(memo)
  }

  xrpTagValidator = () => {
    var tag = this.state.redeemRequest.cryptoDetails[this.getFieldNameForXrpTag()]
    return !tag || tag.length == 0 || currencyHelpers.isValidXrpTag(tag)
  }

  onChangePubAddress = (currency) => {
    return (e) => {
      var newRedeemRequest = {
        ...this.state.redeemRequest,
        cryptoDetails: {
          ...this.state.redeemRequest.cryptoDetails,
          [this.getFieldNameForCurrency(currency)]: e.target.value,
        },
      }

      this.setState({
        ...this.state,
        redeemRequest: newRedeemRequest,
      })

      this.props.onUpdate(newRedeemRequest)
    }
  }

  onChangeXlmMemo = (e) => {
    var newRedeemRequest = {
      ...this.state.redeemRequest,
      cryptoDetails: {
        ...this.state.redeemRequest.cryptoDetails,
        [this.getFieldNameForXlmMemo()]: e.target.value,
      },
    }

    this.setState({
      ...this.state,
      redeemRequest: newRedeemRequest,
    })

    this.props.onUpdate(newRedeemRequest)
  }

  onChangeXrpTag = (e) => {
    var newRedeemRequest = {
      ...this.state.redeemRequest,
      cryptoDetails: {
        ...this.state.redeemRequest.cryptoDetails,
        [this.getFieldNameForXrpTag()]: e.target.value,
      },
    }

    this.setState({
      ...this.state,
      redeemRequest: newRedeemRequest,
    })

    this.props.onUpdate(newRedeemRequest)
  }

  render () {
    return (
      <div className="redeem-crypto-form redeem-form">
        <div className="title">Input the public addresses of the destination wallets you wish to withdraw to:</div>

        {Object.keys(this.props.redeemRequest.amounts).map((currency, i) => {
          return (
            <div className="form-row currency-row" key={currency}>
              <CurrencyLabel currency={currency} className="currency-col" />

              <LockableTextInput
                className="crypto-address-input"
                value={this.state.redeemRequest.cryptoDetails[this.getFieldNameForCurrency(currency)]}
                placeholder={currencyHelpers.getSamplePublicAddress(currency)}
                onChange={this.onChangePubAddress(currency)}
                validator={this.publicAddressValidator(currency)} />

              {currency === "xlm" && (
                <LockableTextInput
                  className="crypto-address-input xlm-memo-input"
                  value={this.state.redeemRequest.cryptoDetails[this.getFieldNameForXlmMemo()]}
                  placeholder="Memo (optional)"
                  onChange={this.onChangeXlmMemo}
                  validator={this.xlmMemoValidator}
                />
              )}

              {currency === "xrp" && (
                <LockableTextInput
                  className="crypto-address-input xrp-tag-input"
                  value={this.state.redeemRequest.cryptoDetails[this.getFieldNameForXrpTag()]}
                  placeholder="Tag (optional)"
                  onChange={this.onChangeXrpTag}
                  validator={this.xrpTagValidator}
                />
              )}
            </div>
          )
        })}
      </div>
    )
  }
}

RedeemCryptoForm.propTypes = {
  user: PropTypes.object,
  redeemRequest: PropTypes.object,
  dac: PropTypes.object,
  onUpdate: PropTypes.func,
}

export default RedeemCryptoForm
