import '!style-loader!css-loader!rc-slider/assets/index.css'

import React from "react"
import PropTypes from "prop-types"

import ReactHintFactory from 'react-hint'
const ReactHint = ReactHintFactory(React)

import Slider from 'rc-slider'
import NumberFormat from 'react-number-format'

import * as format from '../../util/formatting'
import * as currencyHelpers from '../../util/currency'
import * as purchaseOrderHelpers from '../../util/builder/purchase_order'

class AssetPicker extends React.Component {
  constructor(props) {
    super(props)

    this.customSortedItems = this.customSortedItems.bind(this)
    this.rebalanceProportions = this.rebalanceProportions.bind(this)
    this.renderTooltip = this.renderTooltip.bind(this)
    this.onChangeProportion = this.onChangeProportion.bind(this)
    this.onClickLock = this.onClickLock.bind(this)
    this.onBlurAmount = this.onBlurAmount.bind(this)
    this.onChangeAmount = this.onChangeAmount.bind(this)
    this.handleKeypress = this.handleKeypress.bind(this)

    this.MIN_DAC_AMOUNT = 5000
    this.amountBeingEdited = false // used to save state to history on amount changes
    this.lastEditedCurrency = undefined

    this.state = {
      cad_total: String(this.props.purchaseOrder.cad_total),
    }
  }

  customSortedItems() {
    return [
      purchaseOrderHelpers.getItemForCurrency(this.props.purchaseOrder, 'btc'),
      purchaseOrderHelpers.getItemForCurrency(this.props.purchaseOrder, 'bch'),
      purchaseOrderHelpers.getItemForCurrency(this.props.purchaseOrder, 'dash'),
      purchaseOrderHelpers.getItemForCurrency(this.props.purchaseOrder, 'eth'),
      purchaseOrderHelpers.getItemForCurrency(this.props.purchaseOrder, 'etc'),
      purchaseOrderHelpers.getItemForCurrency(this.props.purchaseOrder, 'ltc'),
      purchaseOrderHelpers.getItemForCurrency(this.props.purchaseOrder, 'xrp'),
      purchaseOrderHelpers.getItemForCurrency(this.props.purchaseOrder, 'xlm'),
    ]
  }

  // This function takes the new value of a given currency and if it
  // causes the total proportion to exceed 100%, attempts to rebalance the
  // delta among remaining non-locked currencies evenly
  rebalanceProportions(currency, newProportion) {
    var startingProportion = purchaseOrderHelpers.getTotalProportion(this.props.purchaseOrder)

    // new proportion + sum of proportions from all other currencies
    var newTotalProportion = newProportion + this.props.purchaseOrder.items.reduce((sum, item) => {
      return sum + (item.currency === currency ? 0 : item.proportion)
    }, 0)

    // don't push the proportions past 100% when locked values are present
    var maxAllowedValue = 100 - this.props.purchaseOrder.items.reduce((sum, item) => {
      return sum + (item.currency !== currency && item.locked ? item.proportion : 0)
    }, 0)

    // set value of item we're changing
    var itemForCurrency = purchaseOrderHelpers.getItemForCurrency(this.props.purchaseOrder, currency)
    itemForCurrency.proportion = Math.min(maxAllowedValue, newProportion)

    // rebalance the rest
    if (newTotalProportion > 100 || startingProportion == 100) {
      let remainingAmountToRebalance = newTotalProportion - 100
      let numEligibleForRebalance = this.props.purchaseOrder.items.reduce((sum, item) => {
        return (item.currency !== currency && !item.locked) ? sum + 1 : sum
      }, 0)

      let rebalanceAmountPerCurrency = remainingAmountToRebalance/numEligibleForRebalance
      if (remainingAmountToRebalance > 0) {
        // update currencies that will be at 0 after rebalance
        this.props.purchaseOrder.items.forEach((item, i) => {
          if (item.currency !== currency && !item.locked && item.proportion - rebalanceAmountPerCurrency <= 0) {
            remainingAmountToRebalance -= item.proportion
            item.proportion = 0
            numEligibleForRebalance -= 1
          }
        })

        // update the rest
        rebalanceAmountPerCurrency = remainingAmountToRebalance/numEligibleForRebalance
        this.props.purchaseOrder.items.forEach((item, i) => {
          if (item.currency !== currency && !item.locked && item.proportion > 0) {
            item.proportion -= rebalanceAmountPerCurrency
          }
        })
      } else {
        // when we're going below 100% (after reaching it initially), we want to always adjust the rest of the nodes
        this.props.purchaseOrder.items.forEach((item, i) => {
          if (item.currency !== currency && !item.locked) {
            item.proportion -= rebalanceAmountPerCurrency
          }
        })
      }
    }

    return this.props.purchaseOrder.items
  }

  renderTooltip(target, content) {
    const currency = target.dataset.currency
    const cadAmount = this.props.purchaseOrder.cad_total * purchaseOrderHelpers.getProportionForCurrency(this.props.purchaseOrder, currency) / 100
    const cadAmountStr = format.money(cadAmount, 0) + " CAD"

    const currentPriceCAD = this.props.assetSummary[currency].cad_price
    const cryptoAmount = cadAmount / currentPriceCAD
    const cryptoAmountStr = format.variableDecimalDigits(cryptoAmount, 4, 4) + " " + currency.toUpperCase()

    return (
      <div className="tooltip-content nowrap">{cadAmountStr}<br/>{cryptoAmountStr}</div>
    )
  }

  onChangeProportion(currency, newProportion) {
    const newPurchaseOrder = {
      ...this.props.purchaseOrder,
      items: this.rebalanceProportions(currency, newProportion),
      preset: 'none',
    }

    this.props.onUpdate(newPurchaseOrder)
    this.lastEditedCurrency = currency
  }

  onClickLock(currency) {
    this.props.saveCurrentStateToHistory()

    const item = purchaseOrderHelpers.getItemForCurrency(this.props.purchaseOrder, currency)
    item['locked'] = !item['locked']

    this.props.onUpdate(this.props.purchaseOrder)
  }

  onBlurAmount(e) {
    this.amountBeingEdited = false
  }

  onChangeAmount(values, e) {
    if (!this.amountBeingEdited) {
      this.amountBeingEdited = true
      this.props.saveCurrentStateToHistory()
    }

    const newPurchaseOrder = {
      ...this.props.purchaseOrder,
      cad_total: values.floatValue || 0,
    }

    this.props.onUpdate(newPurchaseOrder)

    this.setState({...this.state, cad_total: values.value})
  }

  handleKeypress(e) {
    var evtobj = window.event? event : e

    // l
    if (evtobj.keyCode == 76) {
      if (this.lastEditedCurrency) {
        this.onClickLock(this.lastEditedCurrency)
      }
    }
  }

  componentDidMount() {
    window.addEventListener("keydown", this.handleKeypress)
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeypress)
  }

  render () {
    var amountToDisplay = this.state.cad_total
    if (amountToDisplay !== "" || this.props.purchaseOrder.cad_total > 0) {
      amountToDisplay = String(this.props.purchaseOrder.cad_total)
    }

    return (
      <div className="picker-container">
        {this.customSortedItems().map((item, i) => {
          return (
            <div className="picker-row" key={item.currency}>
              <div className="currency-container" onClick={()=>{this.props.onAssetClicked(item.currency)}}>
                <div className={"currency-icon " + currencyHelpers.getIconClassForCurrency(item.currency)} />
                <div className="currency-name">{currencyHelpers.getFullNameForCurrency(item.currency)}</div>
              </div>
              <div className="slider-container">
                <Slider
                  step={0.1} min={0} max={100}
                  value={item.proportion}
                  onBeforeChange={this.props.saveCurrentStateToHistory}
                  onChange={(val) => {this.onChangeProportion(item.currency, val)}}
                  trackStyle={{
                    height: 3,
                    borderRadius: 3.5,
                    backgroundColor: currencyHelpers.getPrimaryColorForCurrency(item.currency),
                  }}
                  railStyle={{
                    height: 3,
                    borderRadius: 3.5,
                    backgroundColor: '#D8D8D8',
                  }}
                  handleStyle={{
                    width: 15,
                    height: 15,
                    backgroundColor: currencyHelpers.getPrimaryColorForCurrency(item.currency),
                    marginLeft: -7.5,
                    marginTop: -6,
                    border: 'none',
                  }} />
              </div>
              <div className="proportion-container">
                <div className="proportion" data-picker-tooltip data-currency={item.currency}>{format.percentage(item.proportion, 2)}</div>
              </div>
              <div className={"lock-icon " + (item.locked ? 'locked' : 'unlocked') } onClick={() => {this.onClickLock(item.currency)}} title={item.locked ? 'Unlock' : 'Lock'} />
            </div>
          )
        })}

        <ReactHint events onRenderContent={this.renderTooltip} className="tooltip" attribute="data-picker-tooltip" position="left" delay={100} persist={true} />
        <div className="total-divider" />
        <div className="totals-container">
          <div className="total-dollar-container">
            <span className="dollar">$</span>
            <NumberFormat className="dollar-input"
              value={amountToDisplay}
              displayType="input"
              thousandSeparator={true}
              onValueChange={this.onChangeAmount}
              onBlur={this.onBlurAmount}
              decimalScale={0}
              fixedDecimalScale={true}
              allowNegative={false} />
          </div>

          <div className="total-proportion-container">
            <span className="value">{purchaseOrderHelpers.getTotalProportion(this.props.purchaseOrder).toFixed(2)}</span>
            <span className="percent">%</span>
          </div>
        </div>
        <div className="errors-container">
          <div>
            {this.props.purchaseOrder.cad_total < purchaseOrderHelpers.getMinPurchaseAmount(this.props.purchaseOrder) && (
              <div className="error">Minimum of {format.money(purchaseOrderHelpers.getMinPurchaseAmount(this.props.purchaseOrder), 0)} CAD.</div>
            )}
          </div>
          <div>
            {purchaseOrderHelpers.getTotalProportion(this.props.purchaseOrder) < 100 && (
              <div className="error">Must add to 100%.</div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

AssetPicker.propTypes = {
  purchaseOrder: PropTypes.object,
  assetSummary: PropTypes.object,
  saveCurrentStateToHistory: PropTypes.func,
  onUpdate: PropTypes.func,
  onAssetClicked: PropTypes.func,
}

export default AssetPicker
