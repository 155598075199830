import React from "react"
import PropTypes from "prop-types"

import axios from '../../util/network'
import * as uploadStatus from "../../util/kyc/upload_status"

import FileUploadTracker from "./FileUploadTracker"

class DocumentEditorForm extends React.Component {
  constructor(props) {
    super(props)

    this.getUploadParams = this.getUploadParams.bind(this)
    this.saveDocumentAsync = this.saveDocumentAsync.bind(this)
    this.deleteDocumentAsync = this.deleteDocumentAsync.bind(this)
    this.onClickDeleteHandler = this.onClickDeleteHandler.bind(this)
    this.onUploadProgressHandler = this.onUploadProgressHandler.bind(this)
    this.onUploadSuccessHandler = this.onUploadSuccessHandler.bind(this)
    this.onFilePicked = this.onFilePicked.bind(this)

    this.state = {
      user: this.props.user,
      upload_progress: 0,
      upload_status: uploadStatus.not_started,
      show_upload_success: false,
    }
  }

  getUploadParams(file) {
    return axios.get('/documents/signed_upload_url', {params: {filename: file.name}})
  }

  saveDocumentAsync(uploadParams) {
    return axios.post('/documents', {
      document_type: this.props.doctype,
      s3_key: uploadParams.s3_key,
      filename: uploadParams.filename,
    })
  }

  deleteDocumentAsync(doc)  {
    return axios.delete('/documents/'+doc.id)
    .catch((e) => {
      console.log(e)
    })
  }

  onClickDeleteHandler() {
    const newState = this.state
    const doc = newState.user.kyc_documents[this.props.doctype]
    this.deleteDocumentAsync(doc)

    newState.user.kyc_documents[this.props.doctype] = null
    newState.upload_progress = 0
    newState.upload_status = uploadStatus.not_started
    newState.show_upload_success = false

    this.setState(newState)
    this.props.onUpdate(newState.user)
  }

  onUploadProgressHandler(e) {
    this.setState({
      ...this.state,
      upload_progress: Math.round(e.loaded * 100 / e.total),
      upload_status: uploadStatus.in_progress,
    })
  }

  onUploadSuccessHandler(uploadParams) {
    this.saveDocumentAsync(uploadParams)
    .then((e) => {
      const newUser = this.state.user
      newUser.kyc_documents[this.props.doctype] = e.data

      const newState = {
        ...this.state,
        upload_progress: 100,
        upload_status: uploadStatus.uploaded_successfully,
        show_upload_success: true,
        user: newUser,
      }

      this.setState(newState)
      this.props.onUpdate(newUser)
    }).catch((e)=> {
      console.log(e)
    })
  }

  onFilePicked(acceptedFiles) {
    const file = acceptedFiles[0] // we only accept one file

    this.getUploadParams(file).then((response) => {
      const uploadParams = response.data
      const uploadConfig = {
        onUploadProgress: this.onUploadProgressHandler,
      }

      axios.put(uploadParams.signed_upload_url, file, uploadConfig)
        .then(() => {this.onUploadSuccessHandler(uploadParams)})
        .catch(function(){
          console.log('UPLOAD FAILURE!!');
        });
    }).catch((response) => {
      console.log('Failed to get file upload URL. Response was: ' + response.message);
    })
  }

  render () {
    return (
      <div className="document-editor-form">
        {(this.state.user.kyc_documents[this.props.doctype] && this.state.user.kyc_documents[this.props.doctype].status === 'active' && !this.state.show_upload_success) ? (
          <div className="file-exists-container delete-btn-container">
            <div className="delete-btn" onClick={this.onClickDeleteHandler} />
            <div className="paperclip-icon" />
            <span>{this.state.user.kyc_documents[this.props.doctype].filename}</span>
          </div>
        ) : (
          <div className="file-uploader-container delete-btn-container">
            <FileUploadTracker
              uploadStatus={this.state.upload_status}
              graphicClass={this.props.backgroundGraphicClass}
              uploadProgress={this.state.upload_progress}
              filename={this.state.user.kyc_documents[this.props.doctype] ? this.state.user.kyc_documents[this.props.doctype].filename : ''}
              onFilePicked={this.onFilePicked}
              acceptedExtensions='image/jpeg,image/png,application/pdf' />

            {this.state.show_upload_success && (
              <div className="delete-btn" onClick={this.onClickDeleteHandler} />
            )}
          </div>
        )}
      </div>
    )
  }
}

DocumentEditorForm.propTypes = {
  user: PropTypes.object,
  doctype: PropTypes.string,
  backgroundGraphicClass: PropTypes.string,
  onUpdate: PropTypes.func,
}

export default DocumentEditorForm
