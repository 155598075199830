import React from "react"
import PropTypes from "prop-types"

import * as geography from '../../util/geography'
import * as addressHelpers from '../../util/address'

import LockableLabel from '../forms/LockableLabel'
import LockableTextInput from '../forms/LockableTextInput'
import LockableTextArea from '../forms/LockableTextArea'
import LockableDropdown from '../forms/LockableDropdown'

class RedeemFiatForm extends React.Component {
  constructor(props) {
    super(props)

    this.onChangeField = this.onChangeField.bind(this)
    this.onChangeNumberField = this.onChangeNumberField.bind(this)
    this.emptyStringValidator = this.emptyStringValidator.bind(this)

    this.state = {
      redeemRequest: this.props.redeemRequest,
    }

    if (!this.state.redeemRequest.fiatDetails) {
      this.state.redeemRequest.fiatDetails = {
        country: "", // initialize this on "Select Country" dropdown option
        beneficiary_name: this.props.user.fullname,
        bank_address: "",
        currency: "",
        institution_number: "",
        transit_number: "",
        account_number: "",
        aba_routing_number: "",
        iban: "",
        swift: "",
      }
    }
  }

  onChangeField(field_name) {
    return (e) => {
      const newFiatDetails = {
        ...this.state.redeemRequest.fiatDetails,
      }
      newFiatDetails[field_name] = e.target.value

      const newRedeemRequest = {
        ...this.state.redeemRequest,
        fiatDetails: newFiatDetails,
      }

      this.setState({
        ...this.state,
        redeemRequest: newRedeemRequest,
      })
      this.props.onUpdate(newRedeemRequest)
    }
  }

  onChangeNumberField(field_name) {
    return (e) => {
      const newFiatDetails = {
        ...this.state.redeemRequest.fiatDetails,
      }
      newFiatDetails[field_name] = e.value

      const newRedeemRequest = {
        ...this.state.redeemRequest,
        fiatDetails: newFiatDetails,
      }

      this.setState({
        ...this.state,
        redeemRequest: newRedeemRequest,
      })
      this.props.onUpdate(newRedeemRequest)
    }
  }

  emptyStringValidator(fieldName) {
    return () => {
      return this.state.redeemRequest.fiatDetails[fieldName] && this.state.redeemRequest.fiatDetails[fieldName].length > 0
    }
  }

  render () {
    return (
      <div className="redeem-fiat-form redeem-form">
        <div className="title">Bank transfer information</div>

        <div className="form-row">
          <div className="form-col left">
            <LockableLabel className="label" text="Beneficiary name" isLocked={true} tooltipText="We can only send the transfer to a bank account in your name." />
            <LockableTextInput
              className="name-input"
              value={this.state.redeemRequest.fiatDetails.beneficiary_name}
              isLocked={true} />
          </div>

          <div className="form-col">
            <LockableLabel className="label" text="Currency" />
            <span className="mandatory-asterisk">*</span>
            <LockableDropdown
              className="currency-input"
              value={this.state.redeemRequest.fiatDetails.currency}
              items={{'': 'Select Currency', 'cad': 'CAD', 'usd': 'USD'}}
              onChange={this.onChangeField('currency')}
              validator={this.emptyStringValidator('currency')} />
          </div>
        </div>

        <div className="form-row">
          <div className="form-col left">
            <LockableLabel className="label" text="Financial institution country" />
            <span className="mandatory-asterisk">*</span>
            <LockableDropdown
              className="country-input"
              value={this.state.redeemRequest.fiatDetails.country}
              items={geography.countries.reduce((acc, countryName) => {acc[countryName] = countryName; return acc;}, {'': 'Select Country'})}
              onChange={this.onChangeField('country')}
              validator={this.emptyStringValidator('country')} />
          </div>

          <div className="form-col">
            <LockableLabel className="label" text="Financial institution address" />
            <span className="mandatory-asterisk">*</span>
            <LockableTextArea
              value={this.state.redeemRequest.fiatDetails.bank_address}
              placeholder="30 Wellington St W, Toronto, ON, M5L 1L5, Canada"
              onChange={this.onChangeField('bank_address')}
              validator={this.emptyStringValidator('bank_address')}
              numRows={2} />
          </div>
        </div>

        {this.state.redeemRequest.fiatDetails.country === "Canada" && (
          <React.Fragment>
            <div className="form-row">
              <div className="form-col left">
                <LockableLabel className="label" text="Institution number" />
                <span className="mandatory-asterisk">*</span>

                <LockableTextInput
                  value={this.state.redeemRequest.fiatDetails.institution_number}
                  onChange={this.onChangeField('institution_number')}
                  placeholder="003"
                  validator={this.emptyStringValidator('institution_number')}
                  isAllowed={(e) => { return e.target.value.length <= 3 && !isNaN(Number(e.target.value)) && Number(e.target.value) >= 0 }} />
              </div>

              <div className="form-col">
                <LockableLabel className="label" text="Transit number" />
                <span className="mandatory-asterisk">*</span>

                <LockableTextInput
                  value={this.state.redeemRequest.fiatDetails.transit_number}
                  onChange={this.onChangeField('transit_number')}
                  placeholder="00616"
                  validator={this.emptyStringValidator('transit_number')}
                  isAllowed={(e) => { return e.target.value.length <= 5 && !isNaN(Number(e.target.value)) && Number(e.target.value) >= 0 }} />
              </div>
            </div>

            <div className="form-row">
              <div className="form-col left">
                <LockableLabel className="label" text="Account number" />
                <span className="mandatory-asterisk">*</span>

                <LockableTextInput
                  value={this.state.redeemRequest.fiatDetails.account_number}
                  onChange={this.onChangeField('account_number')}
                  placeholder="4002390"
                  validator={this.emptyStringValidator('account_number')}
                  isAllowed={(e) => { return e.target.value.length <= 15 && !isNaN(Number(e.target.value)) && Number(e.target.value) >= 0 }} />
              </div>

              <div className="form-col">
                <LockableLabel className="label" text="SWIFT code" />
                <LockableTextInput
                  value={this.state.redeemRequest.fiatDetails.swift}
                  placeholder="ROYCCAT2"
                  onChange={this.onChangeField('swift')} />
              </div>
            </div>
          </React.Fragment>
        )}

        {this.state.redeemRequest.fiatDetails.country === "United States" && (
          <React.Fragment>
            <div className="form-row">
              <div className="form-col left">
                <LockableLabel className="label" text="ABA routing number" />
                <span className="mandatory-asterisk">*</span>

                <LockableTextInput
                  value={this.state.redeemRequest.fiatDetails.aba_routing_number}
                  onChange={this.onChangeField('aba_routing_number')}
                  placeholder="100120023"
                  validator={this.emptyStringValidator('aba_routing_number')}
                  isAllowed={(e) => { return e.target.value.length <= 9 && !isNaN(Number(e.target.value)) && Number(e.target.value) >= 0 }} />
              </div>

              <div className="form-col">
                <LockableLabel className="label" text="Account number" />
                <span className="mandatory-asterisk">*</span>
                <LockableTextInput
                  value={this.state.redeemRequest.fiatDetails.account_number}
                  onChange={this.onChangeField('account_number')}
                  placeholder="10012300123"
                  validator={this.emptyStringValidator('account_number')}
                  isAllowed={(e) => { return e.target.value.length <= 15 && !isNaN(Number(e.target.value)) && Number(e.target.value) >= 0 }} />
              </div>
            </div>

            <div className="form-row">
              <div className="form-col left">
                <LockableLabel className="label" text="SWIFT code" />
                <LockableTextInput
                  value={this.state.redeemRequest.fiatDetails.swift}
                  placeholder="ROYCCAT2"
                  onChange={this.onChangeField('swift')} />
              </div>
            </div>
          </React.Fragment>
        )}

        {!["", "Canada", "United States"].includes(this.state.redeemRequest.fiatDetails.country) && (
          <React.Fragment>
            <div className="form-row">
              <div className="form-col left">
                <LockableLabel className="label" text="Account number / IBAN" />
                <span className="mandatory-asterisk">*</span>
                <LockableTextInput
                  value={this.state.redeemRequest.fiatDetails.iban}
                  placeholder="10012300123"
                  onChange={this.onChangeField('iban')}
                  validator={this.emptyStringValidator('iban')} />
              </div>

              <div className="form-col">
                <LockableLabel className="label" text="SWIFT" />
                <span className="mandatory-asterisk">*</span>
                <LockableTextInput
                  value={this.state.redeemRequest.fiatDetails.swift}
                  placeholder="ROYCCAT2"
                  onChange={this.onChangeField('swift')}
                  validator={this.emptyStringValidator('swift')} />
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
}

RedeemFiatForm.propTypes = {
  user: PropTypes.object,
  redeemRequest: PropTypes.object,
  onUpdate: PropTypes.func,
}

export default RedeemFiatForm
