const isOrderSubmitted = (purchaseOrder) => {
  return purchaseOrder && !['not_started', 'editing', 'saved'].includes(purchaseOrder.status)
}

const isOrderBeingEdited = (purchaseOrder) => {
  return purchaseOrder && ['not_started', 'editing'].includes(purchaseOrder.status)
}

module.exports = {
  DELETED:          'deleted',
  NOT_STARTED:      'not_started',
  EDITING:          'editing',
  SAVED:            'saved',
  ORDER_PLACED:     'order_placed',
  PAPERWORK_SIGNED: 'paperwork_signed',
  INVOICE_PAID:     'invoice_paid',
  COMPLETED:        'completed',

  isOrderSubmitted,
  isOrderBeingEdited,
}
