import React from "react"
import PropTypes from "prop-types"

import NumberFormat from 'react-number-format'
import axios from '../../util/network'
import moment from 'moment'

import ReactHintFactory from 'react-hint'
const ReactHint = ReactHintFactory(React)

import UserEditorForm from '../profile/UserEditorForm'

class ProfileTab extends React.Component {
  constructor(props) {
    super(props)

    this.valueOrEmptyString = this.valueOrEmptyString.bind(this)
    this.saveProfileAsync = this.saveProfileAsync.bind(this)
    this.canSubmit = this.canSubmit.bind(this)
    this.onClickSubmit = this.onClickSubmit.bind(this)
    this.onUserEditorFormUpdate = this.onUserEditorFormUpdate.bind(this)

    this.profileTooltipText = "In order to comply with Anti-Money Laundering regulations, we verify your identity using your name, date of birth, address and relevant documentation corrborating this information."

    this.state = {
      user: this.props.user,
    }
  }

  valueOrEmptyString(value) {
    return !value ? '' : value
  }

  saveProfileAsync(profile) {
    axios.put('/update_profile', profile)
      .catch((err) => {
        console.log(err)
      })
  }

  canSubmit() {
    return this.valueOrEmptyString(this.state.user.firstname).length > 0 &&
           this.valueOrEmptyString(this.state.user.lastname).length > 0 &&
           this.valueOrEmptyString(this.state.user.date_of_birth).length > 0 &&
           this.valueOrEmptyString(this.state.user.phone_number).length > 0 &&
           this.valueOrEmptyString(this.state.user.occupation).length > 0
  }

  onClickSubmit() {
    if (!this.canSubmit()) {
      return
    }

    this.saveProfileAsync(this.state.user)
    this.props.afterSubmitted(this.state.user)
  }

  onUserEditorFormUpdate(newUser) {
    this.setState({
      ...this.state,
      user: newUser,
    })
  }

  render () {
    return (
      <div className="profile-editor">
        <div className="title-container">
          <div className="title">Profile</div>
          <div className="question-icon-blue" data-profile-editor-tooltip={this.profileTooltipText} />
        </div>

        <UserEditorForm
          user={this.state.user}
          onUpdate={this.onUserEditorFormUpdate}
          mandatoryFields={['firstname', 'lastname', 'dob', 'phoneNumber', 'occupation']} />

        <button className={"pv-btn submit-btn " + (this.canSubmit() ? '' : 'disabled')} onClick={this.onClickSubmit} tabIndex="0">Continue</button>

        <ReactHint autoPosition events className="tooltip" attribute="data-profile-editor-tooltip" delay={100} persist={true} />
      </div>
    )
  }
}

ProfileTab.propTypes = {
  user: PropTypes.object,
  afterSubmitted: PropTypes.func,
}

export default ProfileTab
