import React from "react"
import PropTypes from "prop-types"

import ReactHintFactory from 'react-hint'
const ReactHint = ReactHintFactory(React)

import * as purchaseStatus from '../../util/builder/purchase_status'
import * as purchaseKind from '../../util/builder/purchase_kind'
import * as kycStatus from '../../util/kyc/kyc_status'

class PurchaseProgressView extends React.Component {
  constructor(props) {
    super(props)

    this.isCancellable = this.isCancellable.bind(this)
    this.onClickVerifyId = this.onClickVerifyId.bind(this)
    this.onClickPayInvoice = this.onClickPayInvoice.bind(this)
    this.renderTooltip = this.renderTooltip.bind(this)
  }

  isCancellable() {
    if (this.props.purchaseOrder.kind === purchaseKind.TOP_UP) {
      return this.props.purchaseOrder.status === purchaseStatus.ORDER_PLACED
    } else {
      return this.props.purchaseOrder.status === purchaseStatus.ORDER_PLACED &&
           this.props.user.kyc_verification.status !== kycStatus.VERIFIED &&
           this.props.user.kyc_verification.status !== kycStatus.FAILED
    }
  }

  onClickVerifyId() {
    if (this.props.purchaseOrder.status === purchaseStatus.ORDER_PLACED &&
        (this.props.user.kyc_verification.status === kycStatus.IN_PROGRESS ||
          !this.props.purchaseOrder.questionnaire.is_completed)) {
      $('.tooltip').hide() // HACK - tooltip doesn't hide properly on Safari when modal is open
      this.props.onClickVerifyId()
    }
  }

  onClickPayInvoice() {
    if (this.props.purchaseOrder.status === purchaseStatus.PAPERWORK_SIGNED) {
      $('.tooltip').hide() // HACK - tooltip doesn't hide properly on Safari when modal is open
      this.props.onClickPayInvoice()
    }
  }

  renderTooltip(target, content) {
    const rawContent = target.dataset.content

    if (!rawContent) {
      return undefined
    }
    // it's fine to dangerouslySetInnerHTML because it's defined by us, and so there's no risk of xss attack
    return (
      <div className="tooltip-content" dangerouslySetInnerHTML={{__html: rawContent}} />
    )
  }

  render () {
    var titleText = ""
    var descriptionText = ""

    var verifyIdTooltipAttrs = {}
    var signPaperworkTooltipAttrs = {}
    var payInvoiceTooltipAttrs = {}

    var verifyIdColClass = "verify-id-container progress-col"
    var signPaperworkColClass = "sign-paperwork-container progress-col"
    var payInvoiceColClass = "pay-invoice-container progress-col"

    if (this.props.purchaseOrder.status === purchaseStatus.ORDER_PLACED &&
        (this.props.user.kyc_verification.status === kycStatus.IN_PROGRESS ||
          !this.props.purchaseOrder.questionnaire.is_completed)) {
      titleText = "You're on your way!"
      descriptionText = "The first step in the process is submitting documentation to verify your identity. Click Verify ID below to get started."

      verifyIdColClass += " active"

      verifyIdTooltipAttrs = {
        "data-purchase-progress-tooltip": true,
        "data-content": "<div class='tooltip-title'>Start ID Verification</div><div>We need to verify your identity first, before we can proceed with your purchase. Don't worry, it's just this one time.</div>"
      }
      signPaperworkTooltipAttrs = {
        "data-purchase-progress-tooltip": true,
        "data-content": "You'll receive the paperwork over email for electronic signing as soon as we verify your identity. Usually within 24h."
      }
      payInvoiceTooltipAttrs = {
        "data-purchase-progress-tooltip": true,
        "data-content": "You'll receive an invoice and payment instructions from us as soon as all the paperwork is signed."
      }
    } else if (this.props.purchaseOrder.status === purchaseStatus.ORDER_PLACED &&
        this.props.user.kyc_verification.status === kycStatus.SUBMITTED) {
      titleText = "Pending Verification"
      descriptionText = "Our compliance team is reviewing your submission. Expect to hear back within 24 hours."

      verifyIdColClass += " active not-clickable"

      signPaperworkTooltipAttrs = {
        "data-purchase-progress-tooltip": true,
        "data-content": "Once we verify your identity, your concierge will email you the contracts for signing electronically."
      }
      payInvoiceTooltipAttrs = {
        "data-purchase-progress-tooltip": true,
        "data-content": "You'll receive an invoice and payment instructions from us as soon as all the paperwork is signed."
      }
    } else if (this.props.purchaseOrder.status === purchaseStatus.ORDER_PLACED &&
        this.props.user.kyc_verification.status === kycStatus.VERIFIED) {
      titleText = "Signature Required"
      descriptionText = "We've emailed you the paperwork and need your signature before we can proceed."

      verifyIdColClass += " completed not-clickable"
      signPaperworkColClass += " active"

      signPaperworkTooltipAttrs = {
        "data-purchase-progress-tooltip": true,
        "data-content": "Search your inbox for documents@balancenow.ca for instructions on how to submit the paperwork. Don't worry, you don't need to print anything."
      }
      payInvoiceTooltipAttrs = {
        "data-purchase-progress-tooltip": true,
        "data-content": "You'll receive an invoice and payment instructions from us as soon as all the paperwork is signed."
      }
    } else if (this.props.purchaseOrder.status === purchaseStatus.ORDER_PLACED &&
        this.props.user.kyc_verification.status === kycStatus.FAILED) {
      titleText = "Verification failed"
      descriptionText = "Please see your email for more information"

      verifyIdColClass += " failed not-clickable"

      signPaperworkTooltipAttrs = {
        "data-purchase-progress-tooltip": true,
        "data-content": "Once we verify your identity, your concierge will email you the contracts for signing electronically."
      }
      payInvoiceTooltipAttrs = {
        "data-purchase-progress-tooltip": true,
        "data-content": "You'll receive an invoice and payment instructions from us as soon as all the paperwork is signed."
      }
    } else if (this.props.purchaseOrder.status === purchaseStatus.PAPERWORK_SIGNED) {
      titleText = "Awaiting Payment"
      descriptionText = "We've emailed you an invoice, all that's left to do is to process your payment. Click Pay invoice for instructions."

      verifyIdColClass += " completed not-clickable"
      signPaperworkColClass += " completed not-clickable"
      payInvoiceColClass += " active"

      payInvoiceTooltipAttrs = {
        "data-purchase-progress-tooltip": true,
        "data-content": "Click to see payment options."
      }
    } else if (this.props.purchaseOrder.status === purchaseStatus.INVOICE_PAID) {
      titleText = "Your DAC is being loaded!"
      descriptionText = "We are loading your Digital Asset Cache! Check this page later to see when your DAC is live!"

      verifyIdColClass += " completed not-clickable"
      signPaperworkColClass += " completed not-clickable"
      payInvoiceColClass += " completed not-clickable"
    }

    return (
      <React.Fragment>
        <div>
          <div className="title">
            <span>{titleText}</span>
            {this.isCancellable() && (
              <button className="pv-link-medium cancel-btn" onClick={this.props.onCancel}>Cancel</button>
            )}
          </div>
          <div className="description">{descriptionText}</div>
        </div>

        <div className="purchase-progress-container">
          <div className={verifyIdColClass} onClick={this.onClickVerifyId} {...verifyIdTooltipAttrs}>
            <div className="verify-id-icon icon" />
            <div className="label">Verify ID <i className="arrow right" /></div>
          </div>
          <div className="separator first" />
          <div className={signPaperworkColClass} {...signPaperworkTooltipAttrs}>
            <div className="sign-paperwork-icon icon" />
            <div className="label">Sign paperwork <i className="arrow right" /></div>
          </div>
          <div className="separator second" />
          <div className={payInvoiceColClass} onClick={this.onClickPayInvoice} {...payInvoiceTooltipAttrs}>
            <div className="pay-invoice-icon icon" />
            <div className="label">Pay invoice <i className="arrow right" /></div>
          </div>
          <ReactHint position="bottom" events className="tooltip" attribute="data-purchase-progress-tooltip" onRenderContent={this.renderTooltip} delay={100} persist={true} />
        </div>
      </React.Fragment>
    )
  }
}

PurchaseProgressView.propTypes = {
  user: PropTypes.object,
  purchaseOrder: PropTypes.object,
  onCancel: PropTypes.func,
  onClickVerifyId: PropTypes.func,
  onClickPayInvoice: PropTypes.func,
}

export default PurchaseProgressView
