import React from "react"
import PropTypes from "prop-types"

class EmptyAssetsView extends React.Component {
  render () {
    return (
      <div className="empty-assets-view">
        <div className="header">You do not currently have any assets in custody.</div>

        <div className="currency-list-container">
          <a className="currency-item" href="https://bitcoin.org/" target="_blank">
            <div className="currency-icon btc" />
            <div className="currency-description">Bitcoin</div>
          </a>

          <a className="currency-item" href="https://www.bitcoincash.org/" target="_blank">
            <div className="currency-icon bch" />
            <div className="currency-description">Bitcoin Cash</div>
          </a>

          <a className="currency-item" href="https://www.dash.org/" target="_blank">
            <div className="currency-icon dash" />
            <div className="currency-description">Dash</div>
          </a>

          <a className="currency-item" href="https://ethereum.org/" target="_blank">
            <div className="currency-icon eth" />
            <div className="currency-description">Ethereum</div>
          </a>

          <a className="currency-item" href="https://ethereumclassic.org/" target="_blank">
            <div className="currency-icon etc" />
            <div className="currency-description">Ethereum Classic</div>
          </a>

          <a className="currency-item" href="https://litecoin.org/" target="_blank">
            <div className="currency-icon ltc" />
            <div className="currency-description">Litecoin</div>
          </a>

          <a className="currency-item" href="https://www.stellar.org/" target="_blank">
            <div className="currency-icon xlm" />
            <div className="currency-description">Stellar</div>
          </a>

          <div className="currency-item">
            <div className="currency-icon erc20" />
            <div className="currency-description">ERC 20/223</div>
          </div>
        </div>

        <div className="description">These are just a few of the assets we support. Additional assets can be added on client demand.</div>
      </div>
    )
  }
}

EmptyAssetsView.propTypes = {
}

export default EmptyAssetsView
