const zero = () => {
  return [
    { currency: 'btc', proportion: 0 },
    { currency: 'bch', proportion: 0 },
    { currency: 'ltc', proportion: 0 },
    { currency: 'dash', proportion: 0 },
    { currency: 'eth', proportion: 0 },
    { currency: 'etc', proportion: 0 },
    { currency: 'xlm', proportion: 0 },
    { currency: 'xrp', proportion: 0 },
  ]
}

const equal = () => {
  return [
    { currency: 'btc', proportion: 12.5 },
    { currency: 'bch', proportion: 12.5 },
    { currency: 'ltc', proportion: 12.5 },
    { currency: 'dash', proportion: 12.5 },
    { currency: 'eth', proportion: 12.5 },
    { currency: 'etc', proportion: 12.5 },
    { currency: 'xlm', proportion: 12.5 },
    { currency: 'xrp', proportion: 12.5 },
  ]
}


const marketCap = (assetSummary) => {
  var currencies = ['btc', 'bch', 'ltc', 'dash', 'eth', 'etc', 'xlm', 'xrp']
  const totalMarketCap = currencies.reduce((acc, ticker) => {
    return acc + Number(assetSummary[ticker].market_cap)
  }, 0)

  return [
    { currency: 'btc', proportion: 100 * assetSummary['btc'].market_cap / totalMarketCap },
    { currency: 'bch', proportion: 100 * assetSummary['bch'].market_cap / totalMarketCap },
    { currency: 'ltc', proportion: 100 * assetSummary['ltc'].market_cap / totalMarketCap },
    { currency: 'dash', proportion: 100 * assetSummary['dash'].market_cap / totalMarketCap },
    { currency: 'eth', proportion: 100 * assetSummary['eth'].market_cap / totalMarketCap },
    { currency: 'etc', proportion: 100 * assetSummary['etc'].market_cap / totalMarketCap },
    { currency: 'xlm', proportion: 100 * assetSummary['xlm'].market_cap / totalMarketCap },
    { currency: 'xrp', proportion: 100 * assetSummary['xrp'].market_cap / totalMarketCap },
  ]
}

const markowitz = () => {
  return [
    { currency: 'btc', proportion: 73.65 },
    { currency: 'bch', proportion: 5.71 },
    { currency: 'ltc', proportion: 2.14 },
    { currency: 'dash', proportion: 0 },
    { currency: 'eth', proportion: 17.68 },
    { currency: 'etc', proportion: 0.82 },
    { currency: 'xlm', proportion: 0 },
    { currency: 'xrp', proportion: 0 },
  ]
}

const bloombergGalaxyIndex = () => {
  return [
    { currency: 'btc', proportion: 30.99 },
    { currency: 'eth', proportion: 30.99 },
    { currency: 'xrp', proportion: 20.84 },
    { currency: 'bch', proportion: 10.23 },
    { currency: 'ltc', proportion: 6.95 },
    { currency: 'dash', proportion: 0 },
    { currency: 'etc', proportion: 0 },
    { currency: 'xlm', proportion: 0 },
  ]
}

module.exports = {
  zero,
  equal,
  marketCap,
  markowitz,
  bloombergGalaxyIndex,
}
