import React from "react"
import PropTypes from "prop-types"

import axios from '../../../util/network'

class ReminderPage extends React.Component {
  constructor(props) {
    super(props)

    this.resendReminderAsync = this.resendReminderAsync.bind(this)
    this.onClickResend = this.onClickResend.bind(this)

    this.state = {
      reminderSent: false,
    }
  }

  resendReminderAsync() {
    return axios.post('/confirmation_reminder', {token: this.props.token})
      .catch((err) => {
        console.log(err)
      })
  }

  onClickResend() {
    this.resendReminderAsync()
    this.setState({...this.state, reminderSent: true})
  }

  render () {
    return (
      <div className="reminder-page auth-page">
        <div className="header">To log in, verify your email</div>
        <div className="text">We’ve sent an email to {this.props.email}. Click the link in the email and you’ll be good to go.</div>
        <div className="envelope-icon" />

        {this.state.reminderSent ? (
          <React.Fragment>
            <div className="text send-confirmed">A verification link has been re-sent to your inbox.</div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="text">Didn’t get an email from us?</div>
            <button className="pv-btn" onClick={this.onClickResend}>Re-send email</button>
          </React.Fragment>
        )}

        <div className="text">
          Still having issues?<br/>
          Contact our support <a className="pv-link-medium" href="mailto:support@balanocenow.ca">support@balancenow.ca</a>
        </div>
      </div>
    )
  }
}

ReminderPage.propTypes = {
  email: PropTypes.string,
  token: PropTypes.string,
}

export default ReminderPage
