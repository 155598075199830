export const variableDecimalDigits = (number, minDigits, maxDigits) => {
  return Number(number).toLocaleString('en-US', {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  })
}

// used for displaying dollar amounts
// e.g. $12,345.67
export const money = (amount, decimalPlaces) => {
  return `CAD ${variableDecimalDigits(amount, 2, 2)}`
}

// used for displaying percentages with given number of decimal places
// e.g. 10.12%, -13.32%
export const percentage = (pct, decimalPlaces) => {
  // let decimalFactor = 10**decimalPlaces
  // return (Math.round(pct * decimalFactor) / decimalFactor).toString() + "%"

  return Number(pct).toFixed(decimalPlaces) + "%"
}

// used for displaying percent change
// e.g. +14.34%, 0.00%, -10.32%
export const signedPercentage = (pct, decimalPlaces) => {
  let roundedPct = Number(pct.toFixed(decimalPlaces))
  let sign = ''
  if (roundedPct > 0) {
    sign = '+'
  } else if (roundedPct < 0) {
    sign = '-'
  }

  return sign + percentage(Math.abs(pct), decimalPlaces)
}

// used for showing short date
// e.g. Nov 30, 2017
export const shortDate = (date, hideCurrentYear) => {
  let options = {month: 'short', day: 'numeric', year: 'numeric'}
  return date.toLocaleDateString('en-US', options);
}

export const roundDown = (number, decimals) => {
  var exponent = 10**decimals
  return Math.floor(number * exponent) / exponent
}
