import React from "react"
import PropTypes from "prop-types"

import axios from '../../util/network'
import Modal from 'react-modal'

import DacDetails from "./DacDetails"
import DacSummary from "./DacSummary"
import TopUpTracker from "./TopUpTracker"
import DacBuilder from "../builder/DacBuilder"
import PurchaseOrderTracker from "../purchase_order/PurchaseOrderTracker"
import PaymentInstructions from "../purchase_order/PaymentInstructions"
import KycWorkflow from "../kyc/KycWorkflow"

import * as privacyUtils from "../../util/privacy"
import * as purchaseStatus from '../../util/builder/purchase_status'
import * as purchaseKind from '../../util/builder/purchase_kind'
import * as purchaseOrderHelpers from '../../util/builder/purchase_order'
import * as kycStage from '../../util/kyc/kyc_stage'
import * as kycStatus from '../../util/kyc/kyc_status'

class SettlementDashboardPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      privacyEnabled: privacyUtils.getSessionValue(),
    }
  }

  togglePrivacy = () => {
    const isPrivacyEnabled = !this.state.privacyEnabled
    this.setState({
      ...this.state,
      privacyEnabled: isPrivacyEnabled,
    })

    privacyUtils.setSessionValue(isPrivacyEnabled)
  }

  render () {
    return (
      <div className="dashboard-page settlement-dashboard-page">
        <privacyUtils.PrivacyContext.Provider value={this.state.privacyEnabled}>
          <div className="dac-details-component-container dac-component-container">
            <DacDetails
              dac={this.props.dac}
              assetSummary={this.props.assetSummary}
              user={this.props.user}
              isTopUpViewSelected={false}
              showSummaryAmounts={true}
              onTogglePrivacy={this.togglePrivacy}
            />
          </div>
        </privacyUtils.PrivacyContext.Provider>
      </div>
    )
  }
}

SettlementDashboardPage.propTypes = {
  dac: PropTypes.object,
  purchaseOrder: PropTypes.object,
  assetSummary: PropTypes.object,
  user: PropTypes.object,
}

export default SettlementDashboardPage
