import React from "react"
import PropTypes from "prop-types"

import ReactHintFactory from 'react-hint'
const ReactHint = ReactHintFactory(React)

class LockableLabel extends React.Component {
  constructor(props) {
    super(props)

    this.renderTooltip = this.renderTooltip.bind(this)
    this.tooltipAttributeName = "data-lockable-label-tooltip-" + Math.random().toString(36).substring(7)
  }

  renderTooltip(target, content) {
    const rawContent = target.dataset.content

    if (!rawContent) {
      return undefined
    }
    return (
      <div className="tooltip-content">{rawContent}</div>
    )
  }

  render () {
    var tooltipAttrs = {}

    // don't render tooltip if it's not defined
    if (this.props.tooltipText !== undefined) {
      tooltipAttrs[this.tooltipAttributeName] = true
      tooltipAttrs['data-content'] = this.props.tooltipText
    }

    return (
      <div className={`${this.props.className} lockable-label-container`}>
        <label>{this.props.text}</label>
        {(this.props.isLocked || !this.props.hideLockIfUnlocked) && (
          <div className={"lock-icon " + (this.props.isLocked ? 'locked' : 'unlocked')} {...tooltipAttrs} />
        )}

        <ReactHint
          position="right"
          events
          className="tooltip"
          attribute={this.tooltipAttributeName}
          onRenderContent={this.renderTooltip}
          delay={100}
          persist={true} />
      </div>
    )
  }
}

LockableLabel.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  tooltipText: PropTypes.string,
  isLocked: PropTypes.bool,
  hideLockIfUnlocked: PropTypes.bool,
}

LockableLabel.defaultProps = {
  className: "label",
  hideLockIfUnlocked: true,
  tooltipText: undefined,
  isLocked: false,
}

export default LockableLabel
