import '!style-loader!css-loader!rc-slider/assets/index.css'

import React from "react"
import PropTypes from "prop-types"

import NumberFormat from 'react-number-format'

import Slider, { createSliderWithTooltip } from 'rc-slider'
const SliderWithTooltip = createSliderWithTooltip(Slider);

import ReactHintFactory from 'react-hint'
const ReactHint = ReactHintFactory(React)

import CurrencyLabel from "../common/CurrencyLabel"

import redeemKind from "../../util/redeem/redeem_kind"
import * as currencyHelpers from '../../util/currency'
import * as format from "../../util/formatting"

class RedeemAmountsPickerTab extends React.Component {
  constructor(props) {
    super(props)

    this.precision = 6
  }

  disabledTooltipAttrName = (currency) => {
    return `data-disabled-${currency}-tooltip`
  }

  disabledTooltipTrigger = (currency) => {
    var tooltipText = ""
    if (currencyHelpers.isERC20(currency)) {
      tooltipText = "This wallet does not have the minimum ETH balance (0.0005) to initiate an ERC-20 transaction."
    }

    return {
      [this.disabledTooltipAttrName(currency)]: tooltipText,
    }
  }

  isMaxWithdrawal = (currency, amount, balance) => {
    var transferableBalance = currencyHelpers.maxTransferableAmount(currency, balance)

    var roundedAmount = format.roundDown(amount, this.precision)
    var roundedBalance = format.roundDown(transferableBalance, this.precision)

    if (roundedAmount == 0) {
      return false
    }

    return roundedAmount === roundedBalance
  }

  onChangeAmount = (currency, amount) => {
    var actualAmount = amount ? amount : 0
    var balance = this.props.availableFunds[currency]

    if (this.isMaxWithdrawal(currency, actualAmount, balance)) {
      actualAmount = balance
    }

    var newAmounts = this.props.redeemRequest.amounts
    if (actualAmount === 0) {
      delete newAmounts[currency]
    } else {
      newAmounts[currency] = actualAmount
    }

    this.props.onAmountsUpdated(newAmounts)
  }

  totalSourceDollarAmount = () => {
    var totalSourceAmount = 0.0
    currencyHelpers.getCurrenciesForTransfer(this.props.availableFunds).forEach((cur, i) => {
      totalSourceAmount += currencyHelpers.maxTransferableAmount(cur, this.props.availableFunds[cur]) * this.props.fiatRates[cur]
    })
    return totalSourceAmount
  }

  totalDestinationDollarAmount = () => {
    var totalDestinationAmount = 0.0
    Object.keys(this.props.redeemRequest.amounts).forEach((cur, i) => {
      if (this.props.redeemRequest.amounts[cur] > 0) {
        var selectedAmount = Math.min(this.props.availableFunds[cur], this.props.redeemRequest.amounts[cur])
        totalDestinationAmount += selectedAmount * this.props.fiatRates[cur]
      }
    })
    return totalDestinationAmount
  }

  totalRemainingSourceDollarAmount = () => {
    return Math.max(0, this.totalSourceDollarAmount() - this.totalDestinationDollarAmount())
  }

  totalSourceTooltipText = () => {
    return `Out of ${format.money(this.totalSourceDollarAmount())} in total settled and unsettled balances across your digital assets.`
  }

  canSubmit = () => {
    if (!this.props.redeemRequest || !this.props.redeemRequest.amounts) {
      return false
    }

    return this.totalDestinationDollarAmount() > 0
  }

  onSubmit = () => {
    if (this.canSubmit()) {
      this.props.onSubmit()
    }
  }

  render () {
    return (
      <div className="redeem-amounts-picker-tab">
        <div className="description">Drag the sliders or input the amount of assets you wish to withdraw.</div>
        <div className="builder-row">
          <div className="currency-col"></div>
          <div className="source-amount-col header-col">To remain:</div>
          <div className="slider-col"></div>
          <div className="destination-amount-col header-col">To withdraw:</div>
        </div>

        {currencyHelpers.getCurrenciesForTransfer(this.props.availableFunds).map((currency, i) => {
          var lockedAmount = currencyHelpers.getLockedAmount(currency)
          var sourceBalance = this.props.availableFunds[currency] || 0

          if ((sourceBalance - lockedAmount) >= 0) {
            sourceBalance = (sourceBalance - lockedAmount).toFixed(8)
          }

          var selectedAmount = Math.min(this.props.redeemRequest.amounts[currency] || 0, sourceBalance)
          var remainingAmount = Math.max(sourceBalance - selectedAmount, 0)
          var isTransferPossible = currencyHelpers.isTransferPossible(this.props.availableFunds, currency)

          return (
            <div className="builder-row" key={i}>
              {!isTransferPossible && (
                <React.Fragment>
                  <div className="disabled-question-icon question-icon-blue" {...this.disabledTooltipTrigger(currency)} />

                  <div className="disabled-tooltip-container">
                    <ReactHint
                      position="top"
                      className="tooltip"
                      attribute={this.disabledTooltipAttrName(currency)}
                      delay={100}
                      persist={true}
                      events
                    />
                  </div>
                </React.Fragment>
              )}

              <div className={"builder-row-content " + (!isTransferPossible ? "disabled" : "")}>
                <CurrencyLabel currency={currency} className="currency-col" />

                <div className="source-amount-col">{format.variableDecimalDigits(Math.max(0, remainingAmount), 2, 8)}</div>
                <div className="slider-col">
                  <SliderWithTooltip
                    step={Math.pow(10, -this.precision)} min={0} max={Number.parseFloat(sourceBalance)}
                    value={Math.min(selectedAmount, sourceBalance)}
                    onChange={(val) => {this.onChangeAmount(currency, val)}}
                    trackStyle={{
                      height: 3,
                      borderRadius: 3.5,
                      backgroundColor: currencyHelpers.getPrimaryColorForCurrency(currency),
                    }}
                    railStyle={{
                      height: 3,
                      borderRadius: 3.5,
                      backgroundColor: '#D8D8D8',
                    }}
                    handleStyle={{
                      width: 15,
                      height: 15,
                      backgroundColor: currencyHelpers.getPrimaryColorForCurrency(currency),
                      marginTop: -6,
                      border: 'none',
                    }}
                    tipFormatter={value => format.money(value * this.props.fiatRates[currency], true) }
                    disabled={!isTransferPossible}
                  />
                </div>
                <div className="destination-amount-col">
                  <NumberFormat
                    className={"amount-input " + (remainingAmount < 0 ? "error" : "")}
                    value={format.variableDecimalDigits(selectedAmount, 2, 8)}
                    displayType='input'
                    onValueChange={(v, e) => {this.onChangeAmount(currency, v.floatValue)}}
                    decimalScale={8}
                    fixedDecimalScale={false}
                    allowNegative={false}
                    isAllowed={(v) => {return v.value === '' || v.floatValue >= 0}}
                    isNumericString={true}
                    disabled={!isTransferPossible}
                  />
                </div>
              </div>
            </div>
          )
        })}

        <div className="builder-row">
          <div className="currency-col"></div>
          <div className="source-amount-col"><div className="divider" /></div>
          <div className="slider-col"></div>
          <div className="destination-amount-col"><div className="divider" /></div>
        </div>

        <div className="builder-row">
          <div className="source-amount-col total-amount-col source-total-amount-col">
            <div>{format.money(this.totalRemainingSourceDollarAmount())}</div>
            <div className="question-icon-blue" data-source-total-tooltip={this.totalSourceTooltipText()} />

            <div className="source-total-tooltip">
              <ReactHint
                position="bottom"
                className="tooltip"
                attribute="data-source-total-tooltip"
                delay={100}
                persist={true}
                events
              />
            </div>
          </div>
          <div className="destination-amount-col total-amount-col destination-total-amount-col">
            {format.money(this.totalDestinationDollarAmount(), true)}
          </div>
        </div>

        <button className={"submit-btn pv-btn " + (this.canSubmit() ? "" : "disabled")} onClick={this.onSubmit}>Continue</button>
      </div>
    )
  }
}

RedeemAmountsPickerTab.propTypes = {
  user: PropTypes.object,
  availableFunds: PropTypes.object,
  dac: PropTypes.object,
  redeemRequest: PropTypes.object,
  fiatRates: PropTypes.object,
  onAmountsUpdated: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default RedeemAmountsPickerTab
