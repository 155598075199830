import React from "react"
import PropTypes from "prop-types"

import ReactHintFactory from 'react-hint'
const ReactHint = ReactHintFactory(React)

class LockableTextInput extends React.Component {
  constructor(props) {
    super(props)

    this.valueOrEmptyString = this.valueOrEmptyString.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onChange = this.onChange.bind(this)

    this.state = {
      allowValidation: false,
    }
  }

  valueOrEmptyString(value) {
    return !value ? '' : value
  }

  onChange(e) {
    if (!this.props.isAllowed(e)) {
      return false
    }

    this.setState({...this.state, allowValidation: true})
    if (this.props.onChange) {
      this.props.onChange(e)
    }
  }

  onBlur(e) {
    this.setState({...this.state, allowValidation: true})
    if (this.props.onBlur) {
      this.props.onBlur(e)
    }
  }

  render () {
    var classNames = [this.props.className, "input", "text-input"]
    if (this.props.isLocked) {
      classNames.push('disabled')
    }

    if (!this.props.validator()) {
      if (this.state.allowValidation || !this.props.validateOnBlurOnly) {
        classNames.push('invalid')
      }
    }

    return (
      <input
        type="text"
        className={classNames.join(' ')}
        value={this.valueOrEmptyString(this.props.value)}
        placeholder={this.props.placeholder}
        onChange={this.onChange}
        onBlur={this.onBlur}
        disabled={this.props.isLocked} />
    )
  }
}

LockableTextInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,

  onChange: PropTypes.func,
  onBlur: PropTypes.func,

  isLocked: PropTypes.bool,
  validator: PropTypes.func,
  isAllowed: PropTypes.func,

  validateOnBlurOnly: PropTypes.bool,
}

const defaultTrueFunc = () => { return true }

LockableTextInput.defaultProps = {
  className: "",
  isLocked: false,
  validator: defaultTrueFunc,
  isAllowed: defaultTrueFunc,
  validateOnBlurOnly: true,
  placeholder: "",
}

export default LockableTextInput
