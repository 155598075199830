import React from "react"
import PropTypes from "prop-types"

import ReactHintFactory from 'react-hint'
const ReactHint = ReactHintFactory(React)

import UserEditorForm from './UserEditorForm'
import AddressEditorForm from './AddressEditorForm'
import PasswordResetTrigger from './PasswordResetTrigger'
import RedeemTrigger from './RedeemTrigger'
import EmailOptinForm from './EmailOptinForm'
import DocumentEditorForm from './DocumentEditorForm'

import * as kycStatus from '../../util/kyc/kyc_status'

import axios from '../../util/network'

class ProfilePage extends React.Component {
  constructor(props) {
    super(props)

    this.canSave = this.canSave.bind(this)
    this.onClickSave = this.onClickSave.bind(this)
    this.onUserEditorFormUpdate = this.onUserEditorFormUpdate.bind(this)
    this.onAddressEditorFormUpdate = this.onAddressEditorFormUpdate.bind(this)
    this.afterEmailOptinFormSubmitted = this.afterEmailOptinFormSubmitted.bind(this)
    this.onDocumentEditorFormUpdate = this.onDocumentEditorFormUpdate.bind(this)
    this.renderTooltip = this.renderTooltip.bind(this)
    this.getUserEditorLockedFields = this.getUserEditorLockedFields.bind(this)
    this.getAddressEditorLockedFields = this.getAddressEditorLockedFields.bind(this)

    this.state = {
      user: this.props.user,
      isPristine: true, // used for grayout the save button when the page just loaded
      isSaving: false,  // used for graying out the save button while the API call is in-flight
      isSaved: true,    // used for displaying "Saved" on the save button after API call is successful
    }
  }

  canSave() {
    return !this.state.isSaving && !this.state.isSaved &&
      this.state.user.firstname && this.state.user.firstname.length > 0 &&
      this.state.user.lastname && this.state.user.lastname.length > 0
  }

  saveUserAsync(user) {
    return axios.put('/update_profile', user)
      .catch((err) => {
        console.log(err)
      })
  }

  saveAddressAsync(address) {
    return axios.put('/addresses/'+address.id, address)
      .catch((err) => {
        console.log(err)
      })
  }

  onClickSave() {
    if (this.canSave()) {
      this.setState({
        ...this.state,
        isSaving: true,
        isSaved: false,
      })

      this.saveUserAsync(this.state.user).then(() => {
        this.saveAddressAsync(this.state.user.address).then(() => {
          this.setState({
            ...this.state,
            isSaving: false,
            isSaved: true,
          })
        })
      })
    }
  }

  onUserEditorFormUpdate(newUser) {
    this.setState({
      ...this.state,
      user: newUser,
      isSaved: false,
      isPristine: false,
    })
  }

  onAddressEditorFormUpdate(newAddress) {
    this.setState({
      ...this.state,
      user: {
        ...this.state.user,
        address: newAddress,
      },
      isSaved: false,
      isPristine: false,
    })
  }

  afterEmailOptinFormSubmitted(newEmailOptin) {
    const newUser = {
      ...this.state.user,
      email_optin: newEmailOptin,
    }
    this.setState({
      ...this.state,
      user: newUser,
    })
  }

  onDocumentEditorFormUpdate(newUser) {
    this.setState({...this.state, user: newUser})
  }


  renderTooltip(target, content) {
    if (target.dataset.doctype === 'photo_id') {
      return (
        <div className="tooltip-content">
          <div className="tooltip-title">We accept any of the documents listed below as long as they are authentic, valid, and current:</div>
          <ul>
            <li>Photo ID card;</li>
            <li>Passport;</li>
            <li>Driver's license;</li>
            <li>Citizenship certificate;</li>
            <li>Permanent Resident or equivalent card.</li>
          </ul>
          <div>Please contact <a href="mailto:documents@balancenow.ca">documents@balancenow.ca</a> if you are unable to provide any of the above documents.</div>
        </div>
      )
    }

    if (target.dataset.doctype === 'proof_of_address') {
      return (
        <div className="tooltip-content">
          <div className="tooltip-title">We can accept the following documents issued within the last 3 months:</div>
          <ul>
             <li>Utility bill;</li>
             <li>Cell phone bill;</li>
             <li>Bank statement;</li>
             <li>Credit card statement;</li>
             <li>Vehicle registration;</li>
             <li>Insurance document;</li>
             <li>Record of employment;</li>
             <li>Letter from the tax authorities (e.g. CRA).</li>
           </ul>
           <div>Please contact <a href="mailto:documents@balancenow.ca">documents@balancenow.ca</a> if you are unable to provide any of the above documents.</div>
        </div>
      )
    }
  }

  getUserEditorLockedFields() {
    if (this.state.user.kyc_verification.status === kycStatus.VERIFIED) {
      return ['firstname', 'lastname', 'dob']
    }
    return []
  }

  getAddressEditorLockedFields() {
    if (this.state.user.kyc_verification.status === kycStatus.VERIFIED) {
      return ['country', 'province', 'city', 'street', 'postal_code',]
    }
    return []
  }

  render () {
    var saveBtnClass = "pv-btn submit-btn"
    if (!this.canSave() || this.state.isSaving || this.state.isSaved) {
      saveBtnClass += " disabled"
    }

    var saveBtnText = "Save"
    if (this.state.isSaving) {
      saveBtnText = "Saving..."
    }

    if (this.state.isSaved && !this.state.isPristine) {
      saveBtnText = "Saved"
    }

    return (
      <div className="profile-page">
        <div className="section-title">Profile</div>
        <div className="divider" />
        <div className="section-content">
          <UserEditorForm
            user={this.state.user}
            onUpdate={this.onUserEditorFormUpdate}
            showEmail={true}
            mandatoryFields={['firstname', 'lastname']}
            lockedFields={this.getUserEditorLockedFields()} />
          <div className="divider inner-divider" />

          <div className="subsection-title">Address</div>
          <AddressEditorForm
            address={this.state.user.address}
            onUpdate={this.onAddressEditorFormUpdate}
            lockedFields={this.getAddressEditorLockedFields()} />

          <button className={saveBtnClass} onClick={this.onClickSave} tabIndex="0">{saveBtnText}</button>
        </div>
        <div className="divider" />

        <div className="col-container">
          <div className="section-content notifications-col col-1-3">
            <div className="subsection-title">Notifications</div>
            <EmailOptinForm user={this.state.user} afterSubmitted={this.afterEmailOptinFormSubmitted} />
          </div>

          <div className="vertical-divider" />

          <div className="section-content details-col">
            <div className="subsection-title">Account Management</div>
            <div className="actions-container">
              <div className="action-link">
                <PasswordResetTrigger />
              </div>

              {this.state.user.is_client && (
                <div className="action-link">
                  <RedeemTrigger
                    user={this.state.user}
                    redeemRequest={this.props.redeemRequest}
                    dac={this.props.dac}
                    fiatRates={this.props.fiatRates}
                  />
                </div>
              )}
            </div>
          </div>
        </div>


        {![kycStatus.SUBMITTED, kycStatus.VERIFIED].includes(this.state.user.kyc_verification.status) && (
          <React.Fragment>
            <div className="divider" />
            <div className="section-content">
              <div className="subsection-title">Documents</div>
              <div className="col-container">
                <div className="doc-col">
                  <div className="doc-title">Government issued photo ID</div>
                  <div className="question-icon-blue" data-documents-editor-tooltip data-doctype="photo_id" />

                  <DocumentEditorForm
                    user={this.state.user}
                    doctype='photo_id'
                    backgroundGraphicClass='drivers-license-graphic'
                    onUpdate={this.onDocumentEditorFormUpdate} />
                </div>

                <div className="doc-col">
                  <div className="doc-title">Proof of address document</div>
                  <div className="question-icon-blue" data-documents-editor-tooltip data-doctype="proof_of_address" />

                  <DocumentEditorForm
                    user={this.state.user}
                    doctype='proof_of_address'
                    backgroundGraphicClass='hydro-bill-graphic'
                    onUpdate={this.onDocumentEditorFormUpdate} />
                </div>
              </div>
            </div>

            <ReactHint position="top" events className="tooltip" attribute="data-documents-editor-tooltip" onRenderContent={this.renderTooltip} delay={100} persist={true} />
          </React.Fragment>
        )}
      </div>
    )
  }
}

ProfilePage.propTypes = {
  user: PropTypes.object,
  redeemRequest: PropTypes.object,
  dac: PropTypes.object,
  fiatRates: PropTypes.object,
}

export default ProfilePage
