import React from "react"
import PropTypes from "prop-types"

import { CSSTransitionGroup } from 'react-transition-group'
import possessive from "@wardrakus/possessive"

import WelcomeView from './WelcomeView'
import Presets from './Presets'
import AssetPicker from './AssetPicker'
import AssetDetails from '../dashboard/AssetDetails'

import * as presets from '../../util/builder/presets'
import * as purchaseStatus from '../../util/builder/purchase_status'
import * as purchaseKind from '../../util/builder/purchase_kind'
import * as format from '../../util/formatting'
import * as currencyHelpers from '../../util/currency'
import * as purchaseOrderHelpers from '../../util/builder/purchase_order'

class DacBuilder extends React.Component {
  constructor(props) {
    super(props)

    this.isPurchaseOrderValid = this.isPurchaseOrderValid.bind(this)
    this.isUndoAvailable = this.isUndoAvailable.bind(this)
    this.saveCurrentStateToHistory = this.saveCurrentStateToHistory.bind(this)
    this.onClickDelete = this.onClickDelete.bind(this)
    this.onClickUndo = this.onClickUndo.bind(this)
    this.onClickSave = this.onClickSave.bind(this)
    this.handleKeypress = this.handleKeypress.bind(this)
    this.applyPreset = this.applyPreset.bind(this)
    this.onClickSettings = this.onClickSettings.bind(this)
    this.onAssetPickerUpdate = this.onAssetPickerUpdate.bind(this)
    this.onAssetPickerAssetClicked = this.onAssetPickerAssetClicked.bind(this)
    this.onAssetDetailsClose = this.onAssetDetailsClose.bind(this)
    this.onAssetDetailsNext = this.onAssetDetailsNext.bind(this)
    this.onAssetDetailsPrevious = this.onAssetDetailsPrevious.bind(this)

    var purchaseOrder = JSON.parse(JSON.stringify(this.props.purchaseOrder))
    if (!purchaseOrder.items || purchaseOrder.items.length === 0) {
      purchaseOrder.items = presets.zero()
    }
    if (!purchaseOrder.cad_total) {
      purchaseOrder.cad_total = purchaseOrderHelpers.getMinPurchaseAmount(this.props.purchaseOrder)
    }

    this.state = {
      purchaseOrder: purchaseOrder,
      displayAssetDetails: false,
      selectedCurrency: null,
    }

    // save default history state
    this.history = []
  }

  isPurchaseOrderValid() {
    return Number(this.state.purchaseOrder.cad_total) >= purchaseOrderHelpers.getMinPurchaseAmount(this.state.purchaseOrder) &&
           purchaseOrderHelpers.getTotalProportion(this.state.purchaseOrder) === 100
  }

  isUndoAvailable() {
    return this.history.length > 0
  }

  saveCurrentStateToHistory() {
    const currentStateDeepCopy = JSON.parse(JSON.stringify(this.state)) // don't want this reference getting messed with
    this.history.push(currentStateDeepCopy)
  }

  onClickDelete() {
    if (confirm("Are you sure you want to delete your top-up? All your changes will be discarded.")) {
      this.props.onDelete()
    }
  }

  onClickUndo() {
    if (this.isUndoAvailable()) {
      const newState = this.history.pop()
      this.props.onUpdate(newState.purchaseOrder)
      this.setState(newState)
    }
  }

  onClickSave() {
    if (this.isPurchaseOrderValid()) {
      this.props.onSave(this.state.purchaseOrder)
    }
  }

  applyPreset(presetItems, selectedPresetName) {
    this.saveCurrentStateToHistory()

    var dacAmount = Number(this.state.purchaseOrder.cad_total)
    if (dacAmount === 0) {
      dacAmount = purchaseOrderHelpers.getMinPurchaseAmount(this.state.purchaseOrder)
    }

    const newItems = []
    presetItems.forEach((item, i) => {
      var newItem = purchaseOrderHelpers.getItemForCurrency(this.state.purchaseOrder, item.currency)
      if (!newItem) {
        newItem = {
          ...item,
          cad_price: this.props.assetSummary[item.currency].cad_price,
        }
      } else {
        newItem.proportion = item.proportion
      }
      newItems.push(newItem)
    })

    const newPurchaseOrder = {
      ...this.state.purchaseOrder,
      status: purchaseStatus.EDITING,
      items: newItems,
      cad_total: dacAmount,
      preset: selectedPresetName,
    }
    this.props.onUpdate(newPurchaseOrder)

    this.setState({
      ...this.state,
      purchaseOrder: newPurchaseOrder,
    })
  }

  onClickSettings() {
    this.applyPreset(presets.zero(), 'none')
  }

  onAssetPickerUpdate(purchaseOrder) {
    this.setState({
      ...this.state,
      purchaseOrder: purchaseOrder,
    })

    this.props.onUpdate(purchaseOrder)
  }

  onAssetPickerAssetClicked(selectedCurrency) {
    this.setState({
      ...this.state,
      displayAssetDetails: true,
      selectedCurrency: selectedCurrency,
    })
  }

  onAssetDetailsClose() {
    this.setState({
      ...this.state,
      displayAssetDetails: false,
      selectedCurrency: null,
    })
  }

  onAssetDetailsNext() {
    this.setState({
      ...this.state,
      displayAssetDetails: true,
      selectedCurrency: currencyHelpers.getNextCurrency(this.state.selectedCurrency),
    })
  }

  onAssetDetailsPrevious() {
    this.setState({
      ...this.state,
      displayAssetDetails: true,
      selectedCurrency: currencyHelpers.getPreviousCurrency(this.state.selectedCurrency),
    })
  }

  handleKeypress(e) {
    var evtobj = window.event? event : e

    // ctrl-z, cmd-z
    if (evtobj.keyCode == 90 && (evtobj.ctrlKey || evtobj.metaKey)) {
      this.onClickUndo()
    }

    // u
    if (evtobj.keyCode == 85) {
      this.onClickUndo()
    }

    // enter
    if (evtobj.keyCode == 13) {
      this.onClickSave()
    }
  }

  componentDidMount() {
    window.addEventListener("keydown", this.handleKeypress)
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeypress)
  }

  render () {
    let mainContent = null

    if (this.state.displayAssetDetails) {
      const proportion = purchaseOrderHelpers.getProportionForCurrency(this.state.purchaseOrder, this.state.selectedCurrency) / 100
      const assetSummary = this.props.assetSummary[this.state.selectedCurrency]
      const cadValue = proportion * this.state.purchaseOrder.cad_total
      const balance = cadValue / assetSummary.cad_price
      const data = {
        balance: balance,
        cad_value: cadValue,
      }

      mainContent = (
        <AssetDetails
          user={this.props.user}
          currency={this.state.selectedCurrency}
          assetSummary={assetSummary}
          primaryColor={currencyHelpers.getPrimaryColorForCurrency(this.state.selectedCurrency)}
          proportion={proportion}
          data={data}
          onClose={this.onAssetDetailsClose}
          onNext={this.onAssetDetailsNext}
          onPrevious={this.onAssetDetailsPrevious} />
      )
    } else {
      mainContent = (
        <div className="dac-builder">
          <div className="section-title">
            {this.state.purchaseOrder.kind === purchaseKind.TOP_UP ? (
              <div>Your Top-Up</div>
            ) : (
              <div>Your Digital Asset Cache</div>
            )}

            <div className="picker-nav-container">
              {this.state.purchaseOrder.kind === purchaseKind.TOP_UP && (
                <React.Fragment>
                  <div className="icon trash-icon" onClick={this.onClickDelete} title="Delete" />
                  <div className="vertical-divider" />
                </React.Fragment>
              )}

              {this.state.purchaseOrder.status === purchaseStatus.NOT_STARTED && (
                <div className="settings-icon" onClick={this.onClickSettings} title="Edit" />
              )}

              {this.state.purchaseOrder.status === purchaseStatus.EDITING && (
                <React.Fragment>
                  <div className={"icon undo-icon " + (this.isUndoAvailable() ? '' : 'disabled')} onClick={this.onClickUndo} title="Undo" />
                  <div className={"icon save-icon " + (this.isPurchaseOrderValid() ? '' : 'disabled')} onClick={this.onClickSave} title="Done" />
                </React.Fragment>
              )}
            </div>
          </div>
          <div className="divider" />

          <CSSTransitionGroup
              transitionName="animated-pane-fade"
              transitionAppear={true}
              transitionAppearTimeout={400}
              transitionEnter={false}
              transitionLeave={false}
              component="div" className="content">

            {this.state.purchaseOrder.status === purchaseStatus.NOT_STARTED && (
              <WelcomeView purchaseOrder={this.state.purchaseOrder} />
            )}

            {this.state.purchaseOrder.status === purchaseStatus.EDITING && (
              <AssetPicker
                purchaseOrder={this.state.purchaseOrder}
                assetSummary={this.props.assetSummary}
                saveCurrentStateToHistory={this.saveCurrentStateToHistory}
                onUpdate={this.onAssetPickerUpdate}
                onAssetClicked={this.onAssetPickerAssetClicked} />
            )}

            <Presets
              purchaseOrder={this.state.purchaseOrder}
              assetSummary={this.props.assetSummary}
              onPresetSelected={this.applyPreset} />
          </CSSTransitionGroup>
        </div>
      )
    }

    return mainContent
  }
}

DacBuilder.propTypes = {
  purchaseOrder: PropTypes.object,
  assetSummary: PropTypes.object,
  user: PropTypes.object,
  onUpdate: PropTypes.func,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
}

export default DacBuilder
